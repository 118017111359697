<script setup>
import {ref} from "vue";
import {getLogo} from "@/helpers/logo";

const startup = ref(null);
const showModal = ref(false);

function open(selectedStartup) {
    startup.value = selectedStartup;
    showModal.value = true;
}

defineExpose({
    open
});
</script>

<template>
    <b-modal v-model="showModal" modal-class="top-startup-modal" hide-footer>
        <template v-if="startup">
            <div class="list-cards-inrow__content">
                <div class="image-block-wrapper">
                    <div class="image-block">
                        <img :src="getLogo(startup.logo)" :alt="startup.name"/>
                    </div>

                    <div>
                        <h5 class="heading5"><a href="#">{{ startup.name }}</a></h5>

                        <ul class="list-activity">
                            <li class="list-activity__startup">{{ startup.stage }}</li>
                        </ul>
                    </div>
                    <div class="icon-speed"
                         :class="'icon-speed--' + startup.score">IRL Score:
                        {{ startup.score }}
                    </div>
                </div>
            </div>

            <footer v-if="startup.categories.length" class="list-cards-inrow__footer">
                <div class="tags-list tags-list--light">
                    <div v-for="category in startup.categories" class="tags-list__tag"
                         :key="category.id">
                        <a :href="'/startups?category=' + category.name" target="_blank">{{ category.name }}</a>
                    </div>
                </div>
            </footer>
        </template>
    </b-modal>
</template>

<style scoped>

</style>
