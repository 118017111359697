import axios from '@/axios';

const RESOURCE_NAME = '/dashboard';

export default {

    getAvailableCharts() {
        return axios.get(`${RESOURCE_NAME}/get-available-charts`)
    },

    getSettings() {
        return axios.get(`${RESOURCE_NAME}/get-settings`)
    },

    sendChartsRequest(type) {
        return axios.post(`${RESOURCE_NAME}/send-charts-request?type=${type}`)
    },

    getChartData(chartType, filters) {
        return axios.post(`${RESOURCE_NAME}/get-chart-data?chart_type=${chartType}`, {
            filters
        })
    },

    getChartStartupsList(chartType, filters, code, currentPage, pageLength) {
        return axios.post(`${RESOURCE_NAME}/get-chart-startups-list?chart_type=${chartType}`, {
            filters,
            code,
            page: currentPage,
            length: pageLength
        })
    },

    getWidgetData(widgetType, filters) {
        return axios.post(`${RESOURCE_NAME}/get-widget-data?widget_type=${widgetType}`, {
            filters,
        })
    },

    getStartupsActionLogs(maxDate, filters) {
        return axios.post(`${RESOURCE_NAME}/get-startups-action-logs`, {maxDate, filters});
    },

    getTopFundedCategories(period) {
        return axios.get(`${RESOURCE_NAME}/get-top-funded-categories?period=${period}`)
    },
}
