<template>
    <div v-if="'approve_status' in this.$store.state.user" class="dashboard">
        <main class="main main--dark main--dashboard main--dashboard--ecosystem pb-0">
            <template v-if="$store.state.user.approve_status == null">
                <div class="block-info">
                    <h3 class="heading3">Your company profile must be completed</h3>
                    <p class="mt-4">In order to have your account approved you need to complete your company's
                        profile</p>
                    <button class="button" @click="openCompanyProfile">Complete Profile</button>
                </div>
            </template>

            <template v-else-if="$store.state.user.approve_status == 'in_review'">
                <div class="block-info">
                    <h3 class="heading3">Your account is currently pending review.</h3>
                    <p class="mt-4">A SwitchPitch representative will review your account within 1 business day and
                        you will be notified when
                        it's been approved.</p>
                </div>
            </template>
            <template v-else-if="$store.state.user.approve_status == 'rejected'">
                <div class="block-info">
                    <h3 class="heading3">Your account was not approved.</h3>
                    <p class="mt-4">We're determined that your organization does not meet our criteria for our
                        ecosystems.</p>
                    <p>If you believe that this decision is incorrect or would like to get in touch, you can reach out
                        to us at <a href="mailto:support@switchpitch.com">support@switchpitch.com</a>.</p>
                </div>
            </template>

            <template v-else-if="$store.getters.isApproved">
                <div class="content">
                    <section v-if="topOpportunities.length" class="dashboard--opportunities">
                        <div class="card card--bordered card--dashboard mb-0">
                            <header class="dashboard__header">
                                <h3 class="heading3">Top Opportunities</h3>
                                <a href="/opportunities" target="_blank" class="link link--view">See
                                    More</a>
                            </header>

                            <div class="dashboard__content">
                                <div class="row w-100">
                                    <div v-for="tracker in topOpportunities" :key="tracker.id" class="col-12 col-md-6 mb-4 mb-md-0">
                                        <div class="d-flex h-100">
                                            <div class="image-block image-block--mds">
                                                <img
                                                    :src="LogoHelper.getLogo(tracker.enterprise.logoUrl)"
                                                    alt="test2">
                                            </div>
                                            <div class="d-flex flex-column align-items-baseline h-100">
                                                <h4 class="heading4 heading4--secondary">{{tracker.name}}</h4>
                                                <h5 class="heading5 heading5--tertiary">{{tracker.enterprise.name}}</h5>

                                                <p>
                                                    <v-clamp autoresize :max-lines="3"> {{tracker.description}}
                                                        <template #after="{ toggle, expanded, clamped }">
                                                            <a v-if="expanded" class="link-normal" @click="toggle"> less</a>
                                                            <a v-if="clamped" class="link-normal" @click="toggle"> more</a>
                                                        </template>
                                                    </v-clamp>
                                                </p>

                                                <router-link :to="`/opportunities/${tracker.id}`" class="mt-auto">
                                                    <button class="button mt-auto">View & Apply</button>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div v-if="['premium','trial'].includes($store.state.accountType)" class="insights-page p-0">
                        <top-startups/>
                        <top-funded-categories/>
                    </div>

                </div>
            </template>

            <aside class="aside aside--secondary">
                <div class="aside__inner">
                    <div class="aside__inner__section">
                        <div class="d-flex align-items-center">
                            <div class="image-block">
                                <img :src="LogoHelper.getLogo($store.state.user.company_logo)" alt="img"/>
                            </div>
                            <h4 class="heading4">{{ $store.state.user.company_name }}</h4>
                        </div>
                        <h6 v-if="false" class="heading6 heading6--secondary">{{ $store.state.user.company_name }}</h6>
                        <p><a :href="$store.state.user.company_url"
                              target="_blank">{{ $store.state.user.company_website }}</a></p>
                    </div>
                    <div v-if="$store.state.user.company_id !== constants.companyPfizer"
                         class="card card--bordered card--user">
                        <div class="card--user__inner">
                            <div class="card--user__image"></div>

                            <div>
                                <h6 class="heading6">Natasha Braun</h6>
                                <p>Account Manager</p>
                            </div>
                        </div>

                        <a href="http://calendly.com/natasha-switchpitch" target="_blank" class="button button--bordered--with--icon button--schedule">Schedule Meeting</a>
                    </div>

                    <div v-if="false" class="card card--bordered card--user">
                        <div class="card--user__inner">
                            <div class="card--user__image"></div>
                            <div>
                                <h6 class="heading6">Natasha Braun</h6>
                                <p>Account Manager</p>
                            </div>
                        </div>

                        <div class="card--user__controls">
                            <a href="#" class="link-schedule">Schedule Meeting</a>
                            <a href="#" class="link-envelope">Send Message</a>
                        </div>
                    </div>

                    
<!--                    <footer class="aside__footer">
                        <a href="#" class="btn btn-primary btn&#45;&#45;edit">Edit Homepage Widgets</a>
                    </footer>-->
                </div>
            </aside>
        </main>

        <Greeting ref="greeting"></Greeting>
    </div>
</template>

<script>

import Greeting from "@/components/ecosystems/Greeting.vue";
import trackers from "@/services/trackers.service";
import TopStartups from "@/views/ecosystem/TopStartups.vue";
import TopFundedCategories from "@/views/ecosystem/TopFundedCategories.vue";
import VClamp from "vue-clamp";

export default {
    components: {
        Greeting,
        TopStartups,
        TopFundedCategories,
        VClamp,
    },

    data() {
        return {
            topOpportunities: [],
        }
    },

    mounted() {
        if ('greeting_shown' in this.$store.state.user && !this.$store.state.user.greeting_shown) {
            this.$refs.greeting.open()
        }

        this.getTopOpportunities()
    },

    methods: {
        openCompanyProfile() {
            this.$store.commit('setOpenCompanyProfile', true);

            this.$nextTick(() => {
                this.$store.commit('setOpenCompanyProfile', false);
            })
        },

        getTopOpportunities() {
            trackers.getTopOpportunities().then(response => {
                this.topOpportunities = response.data
            })
        }
    },
}
</script>
