import { render, staticRenderFns } from "./ComparisonReportModal.vue?vue&type=template&id=36ab44ca&scoped=true&"
import script from "./ComparisonReportModal.vue?vue&type=script&setup=true&lang=js&"
export * from "./ComparisonReportModal.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./ComparisonReportModal.vue?vue&type=style&index=0&id=36ab44ca&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36ab44ca",
  null
  
)

export default component.exports