import axios from '@/axios'

const RESOURCE_NAME = '/action-logs';

export default {

    removeActivity(id) {
        return axios.post(`${RESOURCE_NAME}/remove-activity?id=${id}`)
    },

    getLastActionLogs(filters, maxDate) {
        return axios.post(`${RESOURCE_NAME}/get-last-action-logs`, {
            filters,
            maxDate
        })
    },

    getActionLogs(data) {
        return axios.post(`${RESOURCE_NAME}/get-action-logs`, data)
    },

    getActionLogsFeed(filter, maxDate) {
        return axios.post(`${RESOURCE_NAME}/get-action-logs-feed`, {filter, maxDate})
    },
}
