<script setup>
import {ref, inject, onMounted, watch, computed} from "vue";
import {useStore} from "vue2-helpers/vuex";
import SearchMultiselect from "@/components/ui/SearchMultiselect.vue";
import DatePicker from "vue2-datepicker";
import {encodeDateForServer} from "@/helpers/date";
import {titleCase} from "../../../../../../helpers/string";

const filters = inject('activitiesFilters');
const store = useStore();
const period = ref();

const allTypes = computed({
    get: () => Object.values(filters.activityTypes).filter(Boolean).length === Object.values(filters.activityTypes).length,

    set: (value) => {
        filters.activityTypes = Object.keys(filters.activityTypes).reduce((acc, key) => {
            acc[key] = value;
            return acc;
        }, {});
    }
});

watch(period, () => {
    filters.period = [encodeDateForServer(period.value[0]), encodeDateForServer(period.value[1])];
});

onMounted(() => {
    store.dispatch('fetchUsersList');
    store.dispatch('fetchGroups');
    store.dispatch('fetchAllTrackers');
    store.dispatch('fetchAllTrackers');
    store.dispatch('fetchTags');
});
</script>

<template>
    <ul
        class="filters__list"
    >
        <li>
            <a class="filters__list__title" v-b-toggle.filter-types>
                Activity Type
            </a>

            <b-collapse class="filters__list__content" id="filter-types">
                <ul class="filters__list__choose">
                    <li class="input-checkbox mr-1">
                        <input id="filter-activityType-all" type="checkbox" v-model="allTypes">
                        <label for="filter-activityType-all">All</label>
                    </li>

                    <li v-for="type in Object.keys(filters.activityTypes)" :key="type" class="input-checkbox mr-1">
                        <input :id="`filter-activityType-${type}`" type="checkbox" v-model="filters.activityTypes[type]">
                        <label :for="`filter-activityType-${type}`">{{titleCase(type)}}</label>
                    </li>
                </ul>
            </b-collapse>
        </li>

        <li>
            <a class="filters__list__title" v-b-toggle.filter-date>
                Date
            </a>

            <b-collapse class="filters__list__content" id="filter-date">
                <DatePicker
                    v-model="period"
                    range
                />
            </b-collapse>
        </li>

        <li>
            <a class="filters__list__title" v-b-toggle.filter-users>
                Users
            </a>

            <b-collapse class="filters__list__content" id="filter-users">
                <SearchMultiselect
                    v-model="filters.users"
                    key-name="id"
                    :options="store.getters.usersList"
                    placeholder="Select users"
                />
            </b-collapse>
        </li>

        <li v-if="store.state.user.is_admin || store.getters.settings.users_feed_access === 'full'">
            <a class="filters__list__title" v-b-toggle.filter-groups>
                Groups
            </a>

            <b-collapse class="filters__list__content" id="filter-groups">
                <SearchMultiselect
                    v-model="filters.groups"
                    key-name="id"
                    :options="store.getters.groupsList"
                    placeholder="Select groups"
                />
            </b-collapse>
        </li>

        <li>
            <a class="filters__list__title" v-b-toggle.filter-trackers>
                Trackers
            </a>

            <b-collapse class="filters__list__content" id="filter-trackers">
                <SearchMultiselect
                    v-model="filters.trackers"
                    key-name="id"
                    :options="store.getters.allTrackersList"
                    placeholder="Select trackers"
                />
            </b-collapse>
        </li>

        <li>
            <a class="filters__list__title" v-b-toggle.filter-tags>
                Tags
            </a>

            <b-collapse class="filters__list__content" id="filter-tags">
                <SearchMultiselect
                    v-model="filters.tags"
                    key-name="id"
                    :options="store.getters.tagsList"
                    placeholder="Select tags"
                />
            </b-collapse>
        </li>
    </ul>
</template>

<style scoped>

</style>
