<script setup>
import Updates from "@/views/enterprise/home/aside/updates/Updates.vue";
import CompanyDetails from "@/views/enterprise/home/aside/CompanyDetails.vue";
import {useStore} from "vue2-helpers/vuex";

const store = useStore();
</script>

<template>
    <aside class="aside">
        <div class="aside__inner">
            <div class="aside__inner__section">
                <CompanyDetails/>
            </div>

            <div v-if="store.getters.isPremiumEnterprise" class="aside__inner__section">
                <Updates/>
            </div>
        </div>
    </aside>
</template>

<style scoped>
    .aside {
        flex: 0 0 33% !important;
        background-color: inherit !important;
        box-shadow: inherit !important;
        padding: 0;

        .aside__inner {
            width: 432px;

            .aside__inner__section {
                background-color: #fff;
                font-weight: bold;
                border-radius: 10px;
                border: solid 1px #ddd;
            }
        }
    }
</style>
