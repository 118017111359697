<script setup>
import {computed, inject, ref, watch} from "vue";
import UserActivityItem from "@/views/enterprise/home/aside/updates/users/UserActivityItem.vue";
import Loader from "@/components/ui/Loader.vue";
import actionLogsService from "@/services/action-logs.service";
import ScrolledBottom from "@/components/ScrolledBottom.vue";
import {useStore} from "vue2-helpers/vuex";

const store = useStore();
const formFilters = inject('activitiesFilters');
const actionLogs = ref(null);
const loading = ref(false);
const maxDate = ref(null);
const hasMore = ref(true);

const filters = computed(() => Object.keys(formFilters).reduce((acc, key) => {
    if (key === 'period') {
        acc[key] = formFilters[key];
    } else if (key === 'activityTypes') {
        acc[key] = Object.keys(formFilters[key]).filter(type => formFilters[key][type]);

        if (acc[key].length === Object.keys(formFilters[key]).length) {
            acc[key] = [];
        }
    } else {
        acc[key] = formFilters[key].map(item => item.id);
    }

    return acc;
}, {}));

watch(filters, () => {
    actionLogs.value = null;
    maxDate.value = null;
    getActionLogs();
}, {deep: true, immediate: true});

watch(() => store.getters.isLoggedIn, (value) => {
    if (value) {
        store.dispatch('fetchTasks');
        store.dispatch('fetchFolders');
    }
}, {immediate: true});

async function getActionLogs() {
    loading.value = true;
    const {data} = await actionLogsService.getLastActionLogs(filters.value, maxDate.value);

    if (actionLogs.value === null) {
        actionLogs.value = [];
    }

    actionLogs.value = [...actionLogs.value, ...data.logs];
    maxDate.value = data.max_date;
    loading.value = false;
    hasMore.value = data.logs.length >= 10;
}

function scrolledBottom() {
    if (loading.value || !hasMore.value) {
        return;
    }

    getActionLogs();
}
</script>

<template>
    <div>
        <template v-if="actionLogs !== null">
            <div v-if="!actionLogs.length" class="no-results-section">
                <h3 class="heading3">No Results</h3>

                <p>Try changing your filters to see activity from users in your company</p>
            </div>

            <template v-else>
                <UserActivityItem
                    v-for="(log, index) in actionLogs"
                    :key="index"
                    :log="log"
                />

                <ScrolledBottom v-if="hasMore" @scrolledBottom = scrolledBottom />
            </template>
        </template>

        <Loader
            v-if="loading"
            :loading="true"
            :color="constants.spinnerColor"
        />
    </div>
</template>

<style scoped>
    .no-results-section {
        min-height: 0;
        padding: 0;

        h3 {
            font-size: 22px;
        }

        p {
            font-size: 14px;
        }
    }
</style>
