const common = {
    methods: {
        showNeedUpgrade: function (type) {
            this.$store.commit('showNeedUpgrade', type)
            this.$nextTick(() => {
                this.$store.commit('showNeedUpgrade', false)
            })
        },

        showUpgrade: function (type) {
            this.$store.commit('showUpgrade', type)
            this.$nextTick(() => {
                this.$store.commit('showUpgrade', false)
            })
        },

        showAddEnterpriseForm: function () {
            this.$store.commit('setAddEnterprise', true);
            this.$nextTick(() => {
                this.$store.commit('setAddEnterprise', false);
            })
        },
    },
}

export default common
