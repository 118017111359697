<script setup>
import Vue, {computed, ref} from "vue";
import trackersReportsService from "@/services/trackers-reports.service";
import {download} from "@/helpers/file";
import PrimaryButton from "@/components/ui/PrimaryButton.vue";
import {useStore} from "vue2-helpers/vuex";

const store = useStore();
const props = defineProps(["id", "name"]);
const showModal = ref(false);
const insightsReportLoading = ref(false);
const deepDiveReportLoading = ref(false);
const compareReportLoading = ref(false);
const currentReportName = ref('');

const loading = computed(() => insightsReportLoading.value || deepDiveReportLoading.value || compareReportLoading.value);

async function getInsightsReport() {
    insightsReportLoading.value = true;
    currentReportName.value = 'comprehensive-report';

    try {
        const {data} = await trackersReportsService.getInsightsReport(props.id);
        downloadReport(data);
    } finally {
        insightsReportLoading.value = false;
    }
}

async function getDeepDiveReport() {
    deepDiveReportLoading.value = true;
    currentReportName.value = 'deep-dive-report';

    try {
        const {data} = await trackersReportsService.getDeepDiveReport(props.id);
        downloadReport(data);
    } finally {
        deepDiveReportLoading.value = false;
    }
}

async function getCompareReport() {
    compareReportLoading.value = true;
    currentReportName.value = 'compare-report';

    try {
        const {data} = await trackersReportsService.getCompareReport(props.id);
        downloadReport(data);
    } finally {
        compareReportLoading.value = false;
    }
}

function downloadReport(data) {
    if (data.size < 100) {
        Vue.notify({
            group: 'app',
            type: 'error',
            duration: 5000,
            text: "Error occurred. Please try again later."
        })
    } else {
        let currentDate = new Date();
        download(data, `${props.name}-${currentReportName.value}-${currentDate.toLocaleDateString()}.pdf`);
    }
}

function open() {
    showModal.value = true;
}

defineExpose({
    open
});
</script>

<template>
    <b-modal v-model="showModal" hide-footer title="Generate Tracker Report" modal-class="modal-tracker-report modal-w-xxl">
        <p>Select a template to generate:</p>
        <ul class="list-report mb-5">
            <li>
                <figure class="list-report__figure">
                    <img src="@/images/tracker-report-templates-previews/comprehensive-report.jpeg"/>
                </figure>

                <h5 class="heading5">Comprehensive Report</h5>

                <p>Future trends, recommendations and similar startups</p>
                <p><b>Best for: Landscaping new areas</b></p>

                <PrimaryButton
                    caption="Select Template"
                    loading-caption="Generating..."
                    :loading="insightsReportLoading"
                    :disabled="loading"
                    spinner
                    class="btn btn-bordered"
                    @click="getInsightsReport"
                />
            </li>

            <template v-if="store.getters.isTestEnterprise">
                <li>
                    <figure class="list-report__figure">
                        <img src="@/images/tracker-report-templates-previews/deep-dive-report.png"/>
                    </figure>

                    <h5 class="heading5">Deep Dive</h5>
                    <p>AI-powered details on each individual startup</p>
                    <p><b>Best for: Overview of startups for events or presentations</b></p>

                    <PrimaryButton
                        caption="Select Template"
                        loading-caption="Generating..."
                        :loading="deepDiveReportLoading"
                        :disabled="loading"
                        spinner
                        class="btn btn-bordered"
                        @click="getDeepDiveReport"
                    />
                </li>

                <li>
                    <figure class="list-report__figure">
                        <img src="@/images/tracker-report-templates-previews/summary-report.png"/>
                    </figure>

                    <h5 class="heading5">Summary</h5>

                    <p>A metrics-based overview of the landscape</p>
                    <p><b>Best for: Startup selection for specific need</b></p>

                    <PrimaryButton
                        caption="Select Template"
                        loading-caption="Generating..."
                        :loading="compareReportLoading"
                        :disabled="loading"
                        spinner
                        class="btn btn-bordered"
                        @click="getCompareReport"
                    />
                </li>
            </template>
        </ul>
    </b-modal>
</template>

<style scoped>

</style>
