<script setup>
import TasksWidget from "@/components/tasks/TasksWidget.vue";
import tasksService from "@/services/tasks.service";
import {onMounted, provide, ref, nextTick} from "vue";
import usePromiseAll from "@/composables/usePromiseAll";

const tasksWidget = ref(null);
const tasksListType = ref('');
const tasksCount = ref(null);
const canLoadList = ref(false);

const loadListResolvers = {
    tasksListType: null,
    tasksCount: null,
};

onMounted(() => {
    usePromiseAll(loadListResolvers, () => {
        canLoadList.value = true;

        nextTick(() => {
            tasksWidget.value.getTasks();
        })
    });

    getAllTasksCount();
    getDefaultTasksListType();
});

async function getDefaultTasksListType() {
    const {data} = await tasksService.getDefaultListType();

    tasksListType.value = data;
    loadListResolvers.tasksListType();
}

async function getAllTasksCount() {
    const {data} = await tasksService.getAllTasksCount();

    tasksCount.value = data;
    loadListResolvers.tasksCount();
}

function getTasksIndexList(listType, data, pageParams) {
    return tasksService.getIndex(listType, 0, 0, 0, data, pageParams);
}

provide('getTasksIndexList', getTasksIndexList);
provide('tasksListType', tasksListType);
provide('tasksCount', tasksCount);
</script>

<template>
    <div>
        <TasksWidget
            v-if="canLoadList"
            ref="tasksWidget"
            @tasksChanged="getAllTasksCount"
        />
    </div>
</template>

<style scoped>

</style>
