<script setup>
import {computed, inject, onMounted, ref, watch} from "vue";
import trackersTemplatesService from "@/services/trackers-templates.service";
import {decodeIntervalFromServer} from "@/helpers/date";
import {useStore} from "vue2-helpers/vuex";
import {trackerTypes} from "@/constants/trackers";

const props = defineProps(['submitterFieldsList', 'selectTemplateStep']);
const emit = defineEmits(['update:selectTemplateStep']);
const store = useStore();

const tracker = inject('tracker');
const timeline = inject('timeline');
const trackerId = inject('trackerId');

const selectedTemplate = ref(null)

const templatesList = ref({
    default: [],
    custom: []
});

const hasTemplates = computed(() => templatesList.value.default.length || templatesList.value.custom.length);

watch(selectedTemplate, (value) => {
    applyTemplate(value);
});

watch([() => templatesList.value.custom, () => props.submitterFieldsList], ([templates, fields]) => {
    if (!trackerId.value && templates.length && fields !== null) {
        let defaultTemplate = null;

        if (tracker.type === trackerTypes.PRIVATE) {
            defaultTemplate = templatesList.value.custom.find(item => item.is_default_for_private);
        }

        if (tracker.type === trackerTypes.POC) {
            defaultTemplate = templatesList.value.custom.find(item => item.is_default_for_poc);
        }

        if (defaultTemplate) {
            selectedTemplate.value = defaultTemplate;
        }
    }
});

onMounted(() => {
    getTemplatesList();
});

async function getTemplatesList() {
    const {data} = await trackersTemplatesService.getTemplates();
    templatesList.value = data;
}

function applyTemplate(template) {
    if (template) {
        tracker.templateId = template.id;
        tracker.name = template.tracker_name;
        tracker.description = template.description;
        tracker.defaultView = template.default_view;
        tracker.reviewsEnabled = template.reviews_enabled;
        tracker.pipeline = template.pipeline;

        if (template.start_date || template.end_date) {
            timeline.value = decodeIntervalFromServer([template.start_date, template.end_date]);
        }

        tracker.categories = [...template.categories];

        tracker.reviewerFields = template.reviewer_fields.map(item => ({
            field: item,
            required: false
        }));

        tracker.diligenceFields = [...template.diligence_fields];

        tracker.submitterFields = template.submitter_fields.map(item => {
            let currentField = props.submitterFieldsList.find(fieldItem => fieldItem.id === item.id);

            return {
                field: currentField,
                value: {
                    text: "",
                    options: currentField.options.filter(option => option.is_default),
                    files: [],
                    links: []
                }
            }
        });
    } else {
        tracker.templateId = null;
        tracker.categories = [];
        tracker.reviewerFields = [];
        tracker.diligenceFields = [];
        tracker.submitterFields = [];
        timeline.value = [null, null];
    }

    emit('update:selectTemplateStep', false);
}

defineExpose({
    selectedTemplate,
    hasTemplates
});
</script>

<template>
    <div v-if="selectTemplateStep">
        <h1 class="modal__title">Choose a Template</h1>

        <template v-if="templatesList.default.length">
            <h4 class="heading4">SwitchPitch Templates</h4>

            <ul class="list-template">
                <li v-for="template in templatesList.default" :key="template.id">
                    <a @click="selectedTemplate = template">
                        <div>
                            <h5 class="heading5 heading5--secondary">{{template.name}}</h5>
                            <p>{{template.description}}</p>
                        </div>
                    </a>
                </li>
            </ul>
        </template>

        <template v-if="templatesList.custom.length">
            <h4 class="heading4">{{ store.state.user.company_name }} Templates</h4>

            <ul class="list-template">
                <li class="list-template__list" v-for="template in templatesList.custom" :key="template.id">
                    <a @click="selectedTemplate = template">
                        <div>
                            <h5 class="heading5 heading5--secondary">{{template.name}}</h5>
                            <p>{{template.description}}</p>
                        </div>
                    </a>
                </li>

                <li class="list-template__no-template">
                    <a @click="selectedTemplate = null">
                        <div>
                            <h5 class="heading5 heading5--secondary">No template</h5>
                        </div>
                    </a>
                </li>
            </ul>
        </template>
    </div>
</template>

<style scoped>
    .no-template {
        &:before {
            background-image: none;
        }
    }
</style>
