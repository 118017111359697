<script setup>
import {computed, onMounted, ref, watch} from "vue";
import {getLogo} from "@/helpers/logo";
import dashboardService from "@/services/dashboard.service";
import trackersService from "@/services/trackers.service";
import ViewAnswers from "@/components/trackers/ViewAnswers.vue";

const startups = ref([]);
const trackers = ref([]);
const selectedTrackerId = ref(null);
const viewAnswers = ref(null);

const selectedTrackerName = computed(() => {
    const tracker = trackers.value.find(tracker => tracker.id === selectedTrackerId.value);
    return tracker ? tracker.name : 'All Public Trackers';
});

watch(selectedTrackerId, () => {
    getStartups();
}, {immediate: true});

onMounted(() => {
    getTrackers();
});

async function getStartups() {
    const trackers = selectedTrackerId.value ? [selectedTrackerId.value] : [];
    const {data} = await dashboardService.getWidgetData('AppliedStartups', {trackers});
    startups.value = data;
}

async function getTrackers() {
    const {data} = await trackersService.getList('public');
    trackers.value = data;
}

function showAnswers(trackerId, startupId) {
    viewAnswers.value.open(trackerId, startupId);
}
</script>

<template>
    <section class="dashboard--applied">
        <div class="card card--bordered card--dashboard">
            <header class="dashboard__header">
                <h3 class="heading3">Startups Applied To Trackers</h3>

                <b-dropdown id="dropdown-1" :text="selectedTrackerName" class="dropdown--arrow dropdown-small">
                    <b-dropdown-item
                        :active="selectedTrackerId === null"
                        @click="selectedTrackerId = null"
                    >
                        All Public Trackers
                    </b-dropdown-item>

                    <b-dropdown-item
                        v-for="tracker in trackers"
                        :key="tracker.id"
                        :active="selectedTrackerId === tracker.id"
                        @click="selectedTrackerId = tracker.id"
                    >
                        {{ tracker.name }}
                    </b-dropdown-item>
                </b-dropdown>
            </header>

            <div>
                <ul v-if="startups.length" class="list-cards-inrow list-cards-inrow--in3 mb-0">
                    <li v-for="startup in startups" :key="startup.id">
                        <div class="list-cards-inrow__content">
                            <div class="image-block-wrapper">
                                <div class="image-block">
                                    <img :src="getLogo(startup.logo)" :alt="startup.name"/>
                                </div>
                                <div>
                                    <h5 class="heading5">
                                        <a :href="`/startups/${startup.id}`" target="_blank">{{startup.name}}</a>
                                    </h5>

                                    <ul v-if="startup.stage" class="list-activity">
                                        <li class="list-activity__startup">{{startup.stage}}</li>
                                    </ul>
                                </div>
                                <div v-if="startup.score" :class="`icon-speed icon-speed--${startup.score}`">IRL: {{startup.score}} </div>
                            </div>
                        </div>

                        <footer class="list-cards-inrow__footer list-cards-inrow__footer--flex">
                            Applied {{startup.applied_date}}

                            <a v-if="startup.has_answers" class="link" @click="showAnswers(startup.tracker_id, startup.id)">Review</a>
                        </footer>
                    </li>
                </ul>

                <div v-else class="text-center mt-3">
                    <h4 class="heading4 mb-4">No startups found</h4>
                </div>
            </div>
        </div>

        <ViewAnswers ref="viewAnswers"/>
    </section>
</template>

<style scoped>
    
</style>
