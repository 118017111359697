<script setup>
import {ref, computed, watch} from "vue";
import constants from "@/constants/constants";
import dashboardService from "@/services/dashboard.service";

const categories = ref([]);
const period = ref('year');

const periodLabel = computed(() => constants.commonFilterPeriods.find(item => item.value === period.value).label.firstLetterUpperCase());

watch(period, () => {
    getCategories();
}, {immediate: true});

async function getCategories() {
    const {data} = await dashboardService.getTopFundedCategories(period.value);
    categories.value = data;
}

function openSearchWithCategory(category) {
    window.open("/startups?category=" + encodeURIComponent(category), '_blank')
}
</script>

<template>
    <section class="dashboard--categories">
        <div class="card card--bordered card--dashboard">
            <header class="dashboard__header">
                <h3 class="heading3">Top Funded Categories</h3>

                <b-dropdown id="dropdown-1" :text="periodLabel" class="dropdown--arrow dropdown-small">
                    <b-dropdown-item
                        v-for="commonPeriod in constants.commonFilterPeriods"
                        :key="commonPeriod.value"
                        :active="commonPeriod.value === period"
                        @click="period = commonPeriod.value"
                    >
                        {{ commonPeriod.label }}
                    </b-dropdown-item>
                </b-dropdown>
            </header>

            <div class="categories-grid">
                <div v-for="category in categories" :key="category.category" class="category-card">
                    <div class="category-title">
                        <a @click="openSearchWithCategory(category.category)">
                            {{ category.category }}
                        </a>
                    </div>

                    <div class="category-funding">
                        {{category.total_raised}}
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
</style>
