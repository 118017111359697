<script setup>
import {constants} from "@/views/search/searchConstants";
import {inject} from "vue";
import {getLastId} from "@/helpers/equation";

const emit = defineEmits('deleteAllRules', 'addToTracker', 'editPrompt');

const customView = inject('customView');
const query = inject('query');
const promptQuery = inject('promptQuery');
const showRadarSwitcher = inject("showRadarSwitcher");
const canUpdateSavedSearch = inject("canUpdateSavedSearch");
const isRadarView = inject("isRadarView");
const searchType = inject("searchType");
const gridParams = inject("gridParams");

const changeView = inject("changeView");
const openSaveSearch = inject("openSaveSearch");
const updateSavedSearch = inject("updateSavedSearch");

function convertPromptToEquation() {
    let id = parseInt(getLastId(query));

    promptQuery.rules.forEach(rule => {
        ++id;
        rule.id = id;
        query.rules.push(rule);
    });

    promptQuery.rules = [];
}

</script>

<template>
    <ul class="actions-list__variable">
        <template v-if="!customView">
            <li v-if="(query.rules.length || promptQuery.rules.length)">
                <a
                    class="topbar__side__link topbar__side__unload"
                    @click="emit('deleteAllRules')"
                >
                    Clear All
                </a>
            </li>

            <li v-if="promptQuery.params.text?.length">
                <a
                    class="topbar__side__edit"
                    @click="emit('editPrompt')">
                    Edit Search Prompt
                </a>
            </li>

            <li v-if="promptQuery.rules.length">
                <a
                    class="topbar__side__boolean"
                    @click="convertPromptToEquation">
                    Switch to Boolean
                </a>
            </li>

            <li v-if="showRadarSwitcher">
                <a
                    class="topbar__side__link topbar__side__radar"
                    @click="changeView('radar')"
                >
                    View As Radar
                </a>
            </li>

            <li v-else-if="isRadarView">
                <a
                    class="topbar__side__list"
                    @click="changeView('list')"
                >
                    View As List
                </a>
            </li>

            <li v-if="query.rules.length || promptQuery.rules.length">
                <a
                    class="topbar__side__link topbar__side__save"
                    @click="openSaveSearch"
                >
                    Save Search
                </a>
            </li>

            <li v-if="canUpdateSavedSearch">
                <a
                    class="topbar__side__link topbar__side__save"
                    @click="updateSavedSearch"
                >
                    Update Saved Search
                </a>
            </li>
        </template>

        <li
            v-if="searchType === constants.SEARCH_TYPE_STARTUPS && gridParams.selectedRows.length"
            class="actions-list__add"
        >
            <a @click="emit('addToTracker')" class="topbar__side__link">Add To Trackers</a>
        </li>
    </ul>
</template>

<style scoped>

</style>
