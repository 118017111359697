<template>
    <div class="insights-page__section dashboard--startups">
        <div class="card card--bordered card--dashboard">
            <header class="insights-page__header dashboard__header">
                <h3 class="heading3">Top Startups</h3>
                <p class="mr-0">Ranked using our IRL (Innovation Readiness Level) score <a @click="showIRLExplainModal" class="link link-open">How IRL Score Works</a></p>
    <!--            <a href="#" class="link mr-auto ml-3">Learn More</a>-->
                
                <div v-if="false" class="dropdown b-dropdown insights-page__select btn-group">
                    <button id="dropdown-1__BV_toggle_2" aria-haspopup="menu" aria-expanded="false" type="button" class="btn dropdown-toggle btn-secondary">This year</button>
                
                    <ul role="menu" tabindex="-1" aria-labelledby="dropdown-1__BV_toggle_2" class="dropdown-menu">
                        <li role="presentation"><a role="menuitem" href="#" target="_self" class="dropdown-item">Last 30 days </a></li>
                        <li role="presentation"><a role="menuitem" href="#" target="_self" class="dropdown-item">Last 60 days </a></li>
                        <li role="presentation"><a role="menuitem" href="#" target="_self" class="dropdown-item">Last 90 days </a></li>
                        <li role="presentation"><a role="menuitem" href="#" target="_self" class="dropdown-item">This quarter </a></li>
                        <li role="presentation"><a role="menuitem" href="#" target="_self" class="dropdown-item active">This year </a></li>
                        <li role="presentation"><a role="menuitem" href="#" target="_self" class="dropdown-item">All time </a></li>
                    </ul>
                </div>
            </header>

            <IRLExplainModal ref="IRLExplainModal"/>

            <div class="form-search form-search--light">
                <AutoCompleter
                    ref="keywordAutocompleter"
                    placeHolder="Search Categories"
                    :search-string.sync="searchCategory"
                    :update-function=categoriesService.getFilteredList
                    :clearOnSubmit=true
                    :exclude="topStartupsCategories.map(item => item.id)"
                    v-on:submitted="categorySubmitted"
                />
            </div>

            <div v-if="topStartupsCategories.length" class="tags-list tags-list--dark">
                <div class="tags-list__title">Filtered by</div>
                <span v-for="category in topStartupsCategories" title="Autonomous Vehicles" class="tags-list__tag"
                :key="category.id">
                    <a href="#" target="_blank">{{ category.name }}</a>
                    <a class="tags-list__tag__close"
                       @click="() => {topStartupsCategories = topStartupsCategories.filter(item => item.id !== category.id)}"></a>
                </span>
            </div>
            
            <div class="card--shadow__content">
                <div v-if="loading" class="d-flex justify-content-center">
                    <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
                </div>
    
                <div v-else>
                    <template v-for="topStartupsItem in topStartups">
                        <div :key="topStartupsItem.category"
                             v-if="
                                        topStartupsCategories.some(category => topStartupsItem.category === category.id)
                                        || (!topStartupsCategories.length && topStartupsItem.category === '0')
                                     ">
                            <h5 v-if="topStartupsItem.category > 0" class="heading5 heading5--secondary">
                                {{ topStartupsCategories.find(category => topStartupsItem.category === category.id).name }}
                            </h5>

                            <ul v-if="topStartupsItem.startups.length" class="list-cards-inrow list-cards-inrow--in3">
                                <li v-for="startup in topStartupsItem.startups" :key="startup.id">
                                    <div class="list-cards-inrow__content">
                                        <div class="image-block-wrapper">
                                            <div class="image-block">
                                                <img :src="LogoHelper.getLogo(startup.logo)" :alt="startup.name"/>
                                            </div>

                                            <div>
                                                <h5 class="heading5"><a :href="'/startups/' + startup.id" target="_blank">{{ startup.name }}</a></h5>

                                                <ul class="list-activity">
                                                    <li class="list-activity__startup">{{ startup.stage }}</li>
                                                </ul>
                                            </div>
                                            <div class="icon-speed"
                                                 :class="'icon-speed--' + startup.score">IRL Score:
                                                {{ startup.score }}
                                            </div>
                                        </div>
                                    </div>
                                    <footer v-if="startup.categories.length" class="list-cards-inrow__footer">
                                        <div class="tags-list tags-list--light">
                                            <template v-for="(category, index) in startup.categories">
                                                <div v-if="index < 5" class="tags-list__tag" :key="category.id">
                                                    <a :href="'/startups?category=' + category.name"
                                                       target="_blank">{{ category.name }}</a>
                                                </div>
                                            </template>
                                            <a v-if="startup.categories.length > 5"
                                               @click="() => {selectedTopStartup = startup; $bvModal.show('topStartupModal'); }"
                                               class="link">More</a>
                                        </div>
                                    </footer>
                                </li>
                                <li class="list-cards-inrow__empty"></li>
                            </ul>

                            <div v-else class="no-results">
                                No results for {{topStartupsCategories.find(category => topStartupsItem.category === category.id).name}}
                            </div>
                        </div>
                    </template>
                </div>
            </div>

            <b-modal modal-class="top-startup-modal" id="topStartupModal" hide-footer>
                <template v-if="selectedTopStartup">
                    <div class="list-cards-inrow__content">
                        <div class="image-block-wrapper">
                            <div class="image-block">
                                <img :src="LogoHelper.getLogo(selectedTopStartup.logo)" :alt="selectedTopStartup.name"/>
                            </div>

                            <div>
                                <h5 class="heading5"><a href="#">{{ selectedTopStartup.name }}</a></h5>

                                <ul class="list-activity">
                                    <li class="list-activity__startup">{{ selectedTopStartup.stage }}</li>
                                </ul>
                            </div>
                            <div class="icon-speed"
                                 :class="'icon-speed--' + selectedTopStartup.score">IRL Score:
                                {{ selectedTopStartup.score }}
                            </div>
                        </div>
                    </div>
                    <footer v-if="selectedTopStartup.categories.length" class="list-cards-inrow__footer">
                        <div class="tags-list tags-list--light">
                            <div v-for="category in selectedTopStartup.categories" class="tags-list__tag"
                                 :key="category.id">
                                <a :href="'/startups?category=' + category.name" target="_blank">{{ category.name }}</a>
                            </div>
                        </div>
                    </footer>
                </template>
            </b-modal>
        </div>
    </div>
</template>

<script>
import startups from "@/services/startups.service";
import categoriesService from "@/services/categories.service";
import AutoCompleter from "@/components/AutoCompleter.vue";
import IRLExplainModal from "@/components/IRLExplainModal.vue";

export default {
    components: {
        IRLExplainModal,
        AutoCompleter,
    },

    data() {
        return {
            loading: false,
            topStartups: [],
            topStartupsCategories: [],
            selectedTopStartup: null,
            searchCategory: "",
        }
    },

    mounted() {
        this.getTopStartups()
    },

    computed: {
        categoriesService() {
            return categoriesService;
        }
    },

    methods: {
        categorySubmitted(id) {
            this.topStartupsCategories.push(this.$store.state.categories.find(item =>item.id === id))
            this.getTopStartups()
        },

        getTopStartups() {
            if (!this.topStartupsCategories.length) {
                if (!this.topStartups.some(item => item.category === '0')) {
                    this.loading = true
                    startups.getTopStartups(0).then(response => {
                        this.topStartups.push({
                            'category': '0',
                            'startups': response.data
                        });

                        this.loading = false
                    })
                }
            } else {
                this.topStartupsCategories.forEach(category => {
                    if (!this.topStartups.some(item => item.category === category.id)) {
                        startups.getTopStartups(category.id).then(response => {
                            this.topStartups.push({
                                'category': category.id,
                                'startups': response.data
                            });
                        })
                    }
                })
            }
        },

        showIRLExplainModal() {
            this.$refs.IRLExplainModal.openModal()
        }
    }
};
</script>

<style scoped>
</style>
