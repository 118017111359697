<script setup>

import PrimaryButton from "@/components/ui/PrimaryButton.vue";
import {ref, computed, reactive, watch, provide} from "vue";
import {useStore} from "vue2-helpers/vuex";
import customFieldsService from "@/services/custom-fields.service";
import VueMultiselect from "vue-multiselect";
import accountTypes from "@/constants/accountTypes";
import VueDraggable from "vuedraggable";
import useForm from "@/composables/useForm";
import UserSelect from "@/components/ui/UserSelect.vue";
import Dependencies from "@/components/customFields/edit/Dependencies.vue";
import Permissions from "@/components/customFields/edit/Permissions.vue";
import {FIELD_TYPE_LINKS_LIST, FIELD_TYPE_SELECT, TYPE_TRACKER_SUBMIT} from "@/constants/customFields";

const {type, sectionId = 0, fieldType} = defineProps(['type', 'sectionId', 'fieldType']);
const emit = defineEmits(['saved', 'closedWithoutChanges']);
const store = useStore();

const showModal = ref(false);
const loading = ref(false);
const id = ref(0);
const disableDefaultPipeline = ref(false);
const formSubmitted = ref(false);
const selectedFieldType = ref({
    type: null,
    name: null,
});

const dependenciesBlock = ref(null);

const field = reactive({
    name: "",
    description: "",
    type: type,
    fieldType: fieldType,
    options: [],
    showInTrackersList: false,
    minValue: null,
    maxValue: null,
    permissions: [],
    defaultPipeline: null,
    defaultQuestion: null,
    allowStartupPopulation: false,
    dependsOn: null,
    dependsOnOption: null,
});

const {populateForm, resetForm, errors, setErrors, clearErrors} = useForm(field);

let initTimestamp = null;

const title = computed(() => (id.value ? "Edit " : "Add New ") + (field.fieldType === 'pipeline' ? "Funnel" : (field.fieldType === "POC_preset" ? "Preset" : "Field")));
const fieldTitle = computed(() => (field.fieldType === 'pipeline' ? "Funnel Title" : (field.fieldType === "POC_preset" ? "Preset Title" : "Field Label")));
const placeHolder = computed(() => 'Enter ' + fieldTitle.value);
const addTitle = computed(() => (field.fieldType === 'pipeline' ? "Stage" : (field.fieldType === "POC_preset" ? "Status" : "an option")));
const hasOptions = computed(() => ['radio','checkbox','select','multiselect','pipeline','POC_preset','users_list'].includes(field.fieldType));
const actualUsersList = computed(() => store.getters.usersList.filter(user => !field.options.some(option => parseInt(option.value.id) === parseInt(user.id))));

const canSave = computed(() =>
    field.name.length && field.fieldType && !field.options.some(option => !option.value)
    && !(
        field.fieldType === 'numeric' &&
        (
            !field.minValue || !field.maxValue || parseInt(field.minValue) < 1 || parseInt(field.maxValue) < 1
            || parseInt(field.minValue) > parseInt(field.maxValue))
        )
);

watch(selectedFieldType, (value) => {
    if (value) {
        field.fieldType = value.type;
    }
});

watch(showModal, value => {
    if (!value && !formSubmitted.value) {
        emit('closedWithoutChanges');
    }
})

async function getData() {
    const {data: [data, timestamp]} = await customFieldsService.getEdit(id.value);
    populateForm(data);
    initTimestamp = timestamp;

    if (field.fieldType === 'users_list') {
        await store.dispatch('fetchUsersList');

        field.options = field.options
            .filter(option => store.getters.usersList.some(user => parseInt(user.id) === parseInt(option.value)))
            .map(option => ({
                ...option,
                value: store.getters.usersList.find(user => parseInt(user.id) === parseInt(option.value))
            }));
    }

    disableDefaultPipeline.value = !!data.default_pipeline;
    selectedFieldType.value = store.getters.allFieldTypes[type].find(type => type.type === field.fieldType);
}

async function save() {
    loading.value = true;
    clearErrors();

    if (id.value) {
        const {data: updateTimestamp} = await customFieldsService.getUpdateTimestamp(id.value);

        if (updateTimestamp > initTimestamp) {
            if (confirm("This field has been updated by another user. Do you want to load actual data?")) {
                getData();
            }

            loading.value = false;
            return;
        }
    }

    try {
        let form = {
            ...field,
            options: field.options.map(option => ({
                ...option,
                value: option.value.id ?? option.value
            })),
        }
        const {data} = await customFieldsService.save(id.value ?? 0, sectionId, form);

        if (Object.keys(data[1]).length) {
            setErrors(data[1]);
        } else {
            formSubmitted.value = true;
            showModal.value = false;
            emit('saved', data[0]);
        }
    } finally {
        loading.value = false;
    }
}

function addOption() {
    let option = {
        id: 0,
        value: ''
    };

    if (field.type === 'POC_preset') {
        option.color = '#000000';
    }

    field.options.push(option);
    dependenciesBlock.value.addOptionRef();
}

function setDefaultOption(index) {
    if (field.options[index].is_default) {
        field.options.forEach((option, i) => {
            if (i !== index) {
                option.is_default = false;
            }
        });
    }
}

async function open(fieldId) {
    store.dispatch('fetchUsersList');
    resetForm();
    selectedFieldType.value = null;

    await store.dispatch('fetchFieldTypes', field.type);

    if (fieldId) {
        id.value = fieldId;
        getData();
    } else {
        id.value = 0;

        if (fieldType) {
            field.fieldType = fieldType;
        }
    }

    showModal.value = true;
    formSubmitted.value = false;
}

defineExpose({open});

provide('field', field);
provide('id', id);
provide('sectionId', sectionId);
</script>

<template>
    <div>
        <b-modal v-model="showModal" :title="title" modal-class="modal-w-md">
            <template #modal-title>
                <div class="modal-top">
                    {{ title }}

                    <div v-if="['pipeline','POC_preset'].includes(field.fieldType) && store.state.isAdmin" class="input-checkbox">
                        <input id="dp0" type="checkbox" v-model="field.defaultPipeline" :disabled="disableDefaultPipeline">
                        <label v-if="field.fieldType === 'pipeline'" for="dp0">Default Funnel</label>
                        <label v-else for="dp0">Default Preset</label>
                    </div>

                    <div v-if="field.type === TYPE_TRACKER_SUBMIT && (field.fieldType === FIELD_TYPE_SELECT || field.fieldType === FIELD_TYPE_LINKS_LIST)" class="input-checkbox">
                        <input id="dp0" type="checkbox" v-model="field.showInTrackersList">
                        <label for="dp0">Show in trackers list</label>
                    </div>
                </div>
            </template>

            <b-row class="mb-4">
                <b-col v-if="field.type === 'pipeline'" cols="12" class=mb-3 >
                    Changing the funnel stages will apply those changes to every tracker that uses this template.
                </b-col>

                <b-col cols="12" sm="6">

                    <h5 class="heading5 heading5--secondary">
                        {{fieldTitle}}*
                    </h5>

                    <b-form-input class="form-control--mid" v-model="field.name" :placeholder="placeHolder"></b-form-input>
                </b-col>

                <b-col cols="12" sm="6">
                    <template v-if="!fieldType && store.getters.allFieldTypes[type] !== undefined">
                        <h5 class="heading5 heading5--secondary">Type*</h5>

                        <VueMultiselect
                            class="multiselect-mid mb-2"
                            v-model="selectedFieldType"
                            :options="store.getters.allFieldTypes[type]"
                            label="name"
                            track-by="name"
                            :allow-empty="false"
                            :disabled="id > 0"
                        />
                    </template>
                </b-col>
            </b-row>

            <template v-if="type === 'company_diligence'">
                <b-row class="mb-4">
                    <b-col cols="12">
                        <h5 class="heading5 heading5--secondary">
                            Description
                        </h5>
                        <b-form-input class="form-control--mid" v-model="field.description" placeholder="Description"></b-form-input>

                        <span v-if="errors.description.length" class="error">{{errors.description[0]}}</span>
                    </b-col>
                </b-row>
            </template>

            <template v-if="hasOptions">
                <h5 class="heading5 heading5--secondary">
                    <template v-if="field.fieldType === 'pipeline'">
                        Stages
                    </template>

                    <template v-else-if="field.fieldType === 'POC_preset'">
                        Statuses
                    </template>

                    <template v-else-if="field.fieldType === 'users_list'">
                        Users
                    </template>

                    <template v-else>
                        Dropdown Options
                    </template>
                </h5>

                <VueDraggable v-model="field.options" class="list-colors mb-2">
                    <div class="form__grabbing drag-n-drop-item" inline v-for="(option, index) in field.options" :key="index">

                        <UserSelect
                            v-if="field.fieldType === 'users_list'"
                            v-model="field.options[index].value"
                            add-class="col-md-5 mt-1 mb-1"
                            :options="actualUsersList"
                        />

                        <input
                            v-else
                            v-model="field.options[index].value"
                            v-focus
                            class="form-control--light w-50 form-control form-control--borderless"
                        >

                        <label v-if="field.type === 'tracker_submit' && field.fieldType === 'select'" style="margin-bottom: 0; font-size: 14px">
                            <input
                                v-model="field.options[index].is_default"
                                type="checkbox"
                                class="mr-2"
                                @change="setDefaultOption(index)"
                            >
                            Default Option
                        </label>

                        <div v-if="field.fieldType === 'POC_preset'" class="list-colors__input">
                            <span class="list-colors__input__text">Color:</span>
                            <div class="list-colors__input__wrapper list-colors__input__wrapper--editable">
                                <input type="color" :id="'color-picker-'+index" v-model="field.options[index].color">
                                <label :for="'color-picker-'+index"></label>
                            </div>
                        </div>

                        <a @click="field.options.splice(index, 1)" class="link-remove"></a>
                    </div>
                </VueDraggable>

                <a @click="addOption" class="link">+ Add {{addTitle}}</a>
            </template>

            <div v-if="field.fieldType === 'numeric'" class="mb-4">
                <h5 class="heading5 heading5--secondary">Min Value*</h5>
                <b-form-input type="number" class="form-control--mid" v-model="field.minValue" placeholder="Min value"></b-form-input>

                <h5 class="heading5 heading5--secondary">Max Value*</h5>
                <b-form-input type="number" class="form-control--mid" v-model="field.maxValue" placeholder="Max value"></b-form-input>
            </div>

            <div v-if="field.type === 'tracker_question'" class="input-checkbox">
                <input id="default_question" type="checkbox" v-model="field.defaultQuestion">
                <label for="default_question">Default Question</label>
            </div>

            <Permissions/>

            <b-row
                v-if="type === 'company_diligence'
                    && store.state.companyType === accountTypes.ENTERPRISE
                    && field.fieldType !== 'users_list'"
                class="mb-4"
            >
                <b-col cols="12">
                    <div class="gray-bg-container">
                        <h5 class="heading5 heading5--secondary flex-space-between">
                            <span>Startup Sharing</span>

                            <span class="input-checkbox">
                                <input id="enable-startup-population" type="checkbox" v-model="field.allowStartupPopulation">
                                <label for="enable-startup-population">Enable sharing</label>
                            </span>
                        </h5>

                        <p class="modal-text">Allow your team to send startups a request to populate this field</p>
                    </div>
                </b-col>
            </b-row>

            <Dependencies ref="dependenciesBlock"/>

            <template #modal-footer>
                <PrimaryButton
                    :loading="loading"
                    :disabled="!canSave"
                    @click="save"
                />
            </template>
        </b-modal>
    </div>
</template>

<style scoped>

</style>
