<script setup>
import {ref, computed, inject, onMounted, reactive, watch} from "vue";
import CustomDataFilter from "@/components/customFields/CustomDataFilter.vue";
import multiselect from "vue-multiselect";
import constants from "@/constants/constants";
import {getNoun} from "../../../../helpers/string";
import {useStore} from "vue2-helpers/vuex";
import customFieldsService from "@/services/custom-fields.service";

const store = useStore();
const initFilters = inject('filters');

const filters = reactive({
    period: null,
    userType: null,
    trackers: [],
    tags: [],
    customFields: [],
});

const customFieldsList = ref({});
const showModal = ref(false);

const trackersOptions = computed(() => store.getters.foldersTrackersList.map(item => ({
        folder: item[0],
        trackers: item[1],
    })));

const tagsOptions = computed(() => store.getters.tagsList);

watch(initFilters, () => {
    for (let key in initFilters) {
        filters[key] = initFilters[key];
    }
}, {immediate: true});

onMounted(() => {
    getCustomFields();
});

async function getCustomFields() {
    const {data} = await customFieldsService.getFields("company_diligence", 1);

    if (Object.keys(data).length) {
        customFieldsList.value = data;
    }
}

function updateCustomDataFilter(filter) {
    filters.customFields = filter;
}

function apply() {
    for (let key in initFilters) {
        initFilters[key] = filters[key];
    }

    showModal.value = false;
}

function open() {
    showModal.value = true;
}

defineExpose({open});
</script>

<template>
    <b-modal v-model="showModal" modal-class="modal-w-md" title="Filter Your Dashboard">
        <div class="form-filter">
            <div class="row">
                <div class="col-sm">
                    <multiselect
                        v-model="filters.period"
                        :options="constants.commonFilterPeriods"
                        :allow-empty="false"
                        select-label=""
                        deselect-label=""
                        label="label"
                        class="form-filter__input form-filter__input--period"
                    />
                </div>

                <div class="col-sm">
                    <multiselect
                        v-model="filters.userType"
                        :options="constants.usersTypes"
                        :allow-empty="false"
                        select-label=""
                        deselect-label=""
                        label="label"
                        class="form-filter__input form-filter__input--users"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-sm">
                    <multiselect
                        v-model="filters.trackers"
                        :options="trackersOptions"
                        :multiple="true"
                        group-values="trackers"
                        group-label="folder"
                        :group-select="true"
                        select-label=""
                        deselect-label=""
                        select-group-label=""
                        deselect-group-label=""
                        placeholder="Type to search"
                        track-by="id"
                        label="name"
                        class="form-filter__input form-filter__input--tracker">

                        <span slot="noResult">No trackers found.</span>

                        <template slot="selection" slot-scope="{ values }">
                            <span class="multiselect__single" v-if="values.length ">
                                Selected {{ values.length }} {{ getNoun('Tracker', values.length) }}
                            </span>
                        </template>

                        <template slot="placeholder">
                            All Trackers
                        </template>

                    </multiselect>
                </div>

                <div class="col-sm">
                    <multiselect
                        v-model="filters.tags"
                        :options="tagsOptions"
                        :multiple="true"
                        select-label=""
                        deselect-label=""
                        placeholder="Type to search"
                        track-by="id"
                        label="name"
                        class="form-filter__input form-filter__input--tag">

                        <span slot="noResult">No tags found.</span>

                        <template slot="selection" slot-scope="{ values }">
                            <span class="multiselect__single" v-if="values.length ">
                                Selected {{ values.length }} {{ getNoun('Tag', values.length) }}
                            </span>
                        </template>

                        <template slot="placeholder">
                            No Tags Selected
                        </template>
                    </multiselect>
                </div>
            </div>

            <label class="form-filter__label--text">Custom Fields</label>

            <custom-data-filter
                :custom-fields-list="customFieldsList"
                :init-filters="filters.customFields"
                home-page
                class="form-filter__custom"
                @updateCustomDataFilter="updateCustomDataFilter"
            />
        </div>

        <template #modal-footer>
            <b-button variant="primary" size="lg" @click="apply">Save & Close</b-button>
        </template>
    </b-modal>
</template>

<style scoped>

</style>
