<template>
    <div>
        <highcharts
            v-if="loaded"
            ref="chart"
            :options="getOptions(chartOptions)"
        />

        <div v-else class="d-flex justify-content-center">
            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
        </div>
    </div>
</template>

<script>
    import {Chart} from 'highcharts-vue'
    import More from 'highcharts/highcharts-more'
    import Highcharts from 'highcharts'
    import dashboardChartMixin from '@/mixins/dashboardChartMixin'

    More(Highcharts)

    export default {
        name: 'MeetingsTrackersChart',
        mixins: [dashboardChartMixin],
        components: {
            highcharts: Chart
        },
        data() {
            return {
                loaded: false,
                chartData: [],
                chartOptions: {
                    chart: {
                        type: 'packedbubble',
                    },
                    legend: {
                        enabled: false
                    },
                    title: {
                        text: 'Meetings Held'
                    },
                    tooltip: {
                        useHTML: true,
                        pointFormat: '<b>{point.value}</b> interaction(s) logged in tracker',
                        style: {
                            opacity: 1,
                            zIndex: 9999,
                        },
                    },

                    plotOptions: {
                        packedbubble: {
                            minSize: '50%',
                            maxSize: '100%',
                            zMin: 0,
                            zMax: 15,
                            layoutAlgorithm: {
                                splitSeries: false,
                                bubblePadding: 8,
                                gravitationalConstant: 0.02
                            },
                            dataLabels: {
                                enabled: true,
                                format: '{point.label}',
                                // textPath: {
                                //     enabled: true
                                // },
                                useHTML: true,
                                // filter: {
                                //     property: 'value',
                                //     operator: '>',
                                //     value: 1
                                // },
                                style: {
                                    color: 'black',
                                    fontWeight: 'normal',
                                    textAlign: 'center',
                                    textOverflow: 'clip',
                                    width: 80,
                                    fontSize: '12px',
                                    zIndex: 0
                                }
                            },
                        },
                    },
                    series: []
                },
            }
        },
        methods: {
            fetchChartData(initialCall = false) {
                this.getChartData('MeetingsByTrackers').then(response => {
                    this.chartData = response.data
                    this.formatAndSetChartData()

                    if (!initialCall) {
                        this.$refs.chart.chart.hideLoading()
                    } else {
                        this.loaded = true
                    }
                })
            },

            formatAndSetChartData() {
                let meetings = []
                let factor = 5 / this.chartData.length
                this.chartData.forEach(item => {
                    let max_length

                    if (item.prc > 80) {
                        max_length = 50 * factor
                    } else if (item.prc > 50) {
                        max_length = 40 * factor
                    } else if (item.prc > 30) {
                        max_length = 30 * factor
                    } else {
                        max_length = 20 * factor
                    }
                    let meeting = {
                        name: item.name,
                        data: [{
                            label: this.stringHelper.cutString(item.name, max_length, '...'),
                            name: item.name,
                            value: item.cnt,
                        }]
                    }

                    meetings.push(meeting)
                })

                this.chartOptions.series = meetings

                //Adjust width of text label depending on amount of bubbles
                let labelWidth = 100 * (3.3 / this.chartData.length)
                labelWidth = labelWidth < 280 ? labelWidth : 280
                labelWidth = labelWidth > 40 ? labelWidth : 40
                this.chartOptions.plotOptions.packedbubble.dataLabels.style.width = labelWidth
            }
        }
    }
</script>
