<script setup>

import {trackerTypes} from "@/constants/trackers";
import Multiselect from "vue-multiselect";
import {onMounted, ref, watch, inject} from "vue";
import {useStore} from "vue2-helpers/vuex";
import CustomFieldEdit from "@/components/customFields/edit/CustomFieldEdit.vue";
import trackersService from "@/services/trackers.service";
import companyService from "@/services/company.service";

const store = useStore();

const tracker = inject('tracker');
const trackerId = inject('trackerId');
const canEditInfo = inject('canEditInfo');
const emailStages = ref([]);
const enableStagesEmail = ref(false);
const showEditEmailTemplate = ref(false);
const currentPipeline = ref(null);
const editPipelineModal = ref(null);
const pipelinesList = ref([]);
const emailTemplates = ref({});

const emailTemplate = ref({
    subject: "",
    body: "",
});

const currentStage = ref({
    id: 0,
    name: "",
});

watch(enableStagesEmail, (value) => {
    if (!value) {
        emailStages.value = emailStages.value.map(() => false);
    }
});

watch(() => tracker.pipeline, (value) => {
    if (value) {
        if (value.id === -1) {
            tracker.pipeline = null;
            editPipelineModal.value.open(0);
        } else {
            currentPipeline.value = value;
            emailStages.value = value.steps.map(item => tracker.emailStages.some(stage => parseInt(stage) === item.id));
        }
    }
}, {immediate: true});

onMounted(() => {
    getPipelinesList();

    if (trackerId.value) {
        enableStagesEmail.value = !!tracker.emailStages.length;
    }
});

async function openEmailTemplate(stage) {
    currentStage.value = stage

    if (currentStage.value.id in emailTemplates.value) {
        emailTemplate.value = emailTemplates.value[currentStage.value.id];
        showEditEmailTemplate.value = true;
    } else {
        const {data} = await companyService.getStageEmailTemplateEdit(trackerId.value, currentStage.value.id);
        emailTemplate.value = data;
        showEditEmailTemplate.value = true;
    }
}

function resetPipeline() {
    tracker.pipeline = currentPipeline.value;
}

async function setPipeline(id) {
    const {data} = await trackersService.getPipelinesList();
    pipelinesList.value = [
        ...data,
        {
            id: -1,
            name: "Create New Funnel",
            steps: [],
        }
    ];

    tracker.pipeline = pipelinesList.value.find(item => item.id === id);
}

async function getPipelinesList() {
    const {data} = await trackersService.getPipelinesList();
    pipelinesList.value = data;

    if (pipelinesList.value.length) {
        if (!trackerId.value) {
            tracker.pipeline = pipelinesList.value[0];
        }

        currentPipeline.value = tracker.pipeline;
    }

    if (store.getters.permissions.includes('funnel-create')) {
        pipelinesList.value.push({
            id: -1,
            name: "Create New Funnel",
            steps: [],
        })
    }
}

function saveEmailTemplate() {
    emailTemplates.value[currentStage.value.id] = emailTemplate.value;
    showEditEmailTemplate.value = false;
}

defineExpose({
    emailStages,
    emailTemplates,
});
</script>

<template>
    <div v-if="![trackerTypes.ECOSYSTEMS, trackerTypes.POC].includes(tracker.type)">
        <div class="pipeline-cover">
            <Multiselect
                v-if="tracker.enablePipeline"
                class="multiselect-mid"
                v-model="tracker.pipeline"
                :options="pipelinesList"
                select-label=""
                deselect-label=""
                label="name"
                track-by="name"
                :disabled="!canEditInfo"
            />

            <div class="content-switcher content-switcher--secondary">
                <h5 v-if="!tracker.enablePipeline" class="heading5 heading5--secondary mt-1 mr-4">Pipeline Disabled</h5>
                <div class="content-switcher__slider" :class="{'enabled': tracker.enablePipeline}">
                    <input type="checkbox" id="content-switcher__slider" :disabled="!canEditInfo" v-model="tracker.enablePipeline">
                    <label for="content-switcher__slider"></label>
                </div>
            </div>

            <template v-if="tracker.enablePipeline">
                <div class="link-wrapper m-0 p-0">
                    <a
                        v-if="tracker.pipeline && canEditInfo && store.getters.permissions.indexOf('tracker-template-manage') >= 0"
                        class="link-wrapper__edit text-nowrap"
                        @click="editPipelineModal.open(tracker.pipeline.id)"
                    >
                        Edit Stages
                    </a>
                </div>
            </template>
        </div>

        <template v-if="tracker.enablePipeline">
            <ul v-if="tracker.pipeline" class="rows-list rows-list--pipeline">
                <li v-for="(stage, index) in tracker.pipeline.steps" :key="index">
                    <div v-if="enableStagesEmail" class="d-flex justify-content-between w-100">
                        <div class="input-checkbox">
                            <input :id="'enable-email-'+index" type="checkbox" v-model="emailStages[index]">
                            <label :for="'enable-email-'+index">{{stage.name}}</label>
                        </div>

                        <a v-if="emailStages[index]" @click="openEmailTemplate(stage)" class="link link--email">Edit email</a>
                    </div>

                    <template v-else>
                        {{stage.name}}
                    </template>
                </li>
            </ul>

            <div v-if="tracker.type === trackerTypes.PUBLIC && tracker.pipeline" class="pipeline-stages-header">
                <div class="input-checkbox input-checkbox--secondary">
                    <input id="enable-stages-email" type="checkbox" v-model="enableStagesEmail">
                    <label for="enable-stages-email">Notify startups of stage changes</label>
                </div>
            </div>
        </template>

        <CustomFieldEdit
            ref="editPipelineModal"
            type="tracker_diligence"
            fieldType="pipeline"
            @saved="setPipeline"
            @closedWithoutChanges="resetPipeline"
        />

        <b-modal v-model="showEditEmailTemplate" title="Edit email template" modal-class="modal-w-md">
            <p>This email will be sent automatically when a startup is added to the tracker stage {{currentStage.name}}.<br>
                <template v-pre>
                    Enter "{{Stage name}}" or "{{Project name}}" to automatically add the relevant titles, even if you change them later.
                </template>
            </p>

            <div class="form form-modal mb-4">
                Subject
                <b-form-input class="form-control--mid" v-model="emailTemplate.subject"></b-form-input>
            </div>

            <div class="form form-modal mb-4">
                Message
                <textarea v-model="emailTemplate.body">

                </textarea>
            </div>

            <template #modal-footer>
                <b-button variant="primary" size="lg" @click="saveEmailTemplate">Save</b-button>
            </template>
        </b-modal>
    </div>
</template>

<style scoped>

</style>
