<script setup>
import {ref, onMounted, computed} from "vue";
import trackersService from "@/services/trackers.service";
import {ucFirst} from "../../../helpers/string";
import {useStore} from "vue2-helpers/vuex";

const store = useStore();
const emit = defineEmits(['approved']);
const {id} = defineProps(['id']);
const approvals = ref([]);

const canApprove = computed(() => approvals.value.some(approval => approval.status === 'pending' && approval.user_id === store.state.user.id));

onMounted(() => {
    getApprovals();
});

async function getApprovals() {
    const {data} = await trackersService.getApprovals(id);
    approvals.value = data;
}

async function approve() {
    const {data} = await trackersService.approve(id);

    if (data) {
        getApprovals();
    } else {
        emit('approved');
    }
}
</script>

<template>
    <div class="page-approval">
        <h3 class="page-approval__heading">Project Pending Approval</h3>

        <ul class="list-approval">
            <li v-for="approval in approvals" :key="approval.user_id">
                <h6 class="heading6">{{approval.user_name}}</h6>

                <span class="label-approval" :class="'label-approval--' + approval.status">{{ ucFirst(approval.status) }}</span>
            </li>
        </ul>

        <footer class="page-approval__footer">
            <button
                v-if="canApprove"
                class="button"
                @click="approve"
            >
                Approve
            </button>
        </footer>
    </div>
</template>

<style scoped>

</style>
