<script setup>
import {ref} from "vue";
import savedFiltersService from "@/services/saved-filters.service";
import EditPresetModal from "@/views/enterprise/home/topBar/EditPresetModal.vue";

const emit = defineEmits(['saved-filters-changed', 'editFilter']);
const presets = ref({});
const showModal = ref(false);
const editPresetModal = ref(false);

async function getPresets() {
    console.log(1);
    const {data} = await savedFiltersService.getList();

    presets.value = {}

    data.forEach(group => {
        presets.value[group.group] = group.items.map(item => {
            return {
                id: item.id,
                name: item.name,
                newName: item.name,
                editMode: false,
                canManage: item.can_manage,
            }
        })
    });
}
function openEdit(id) {
    showModal.value = false;
    editPresetModal.value.open(id);
}

async function deletePreset(id) {
    if (confirm("Delete Preset?")) {
        await savedFiltersService.deleteFilter(id);

        emit('savedFiltersChanged');
        await getPresets();
    }
}

function open() {
    getPresets();
    showModal.value = true;
}

defineExpose({
    open
});
</script>

<template>
    <div>
        <b-modal v-model="showModal" title="Edit Presets" size="md" hide-footer>
            <div v-for="(group, groupName) in presets" :key="groupName">
                <label>{{groupName}}</label>

                <ul class="manage-list">
                    <li v-for="(filter, index) in group" :key="index">
                        <p class="manage-list__title">{{presets[groupName][index].name}}</p>

                        <div v-if="filter.canManage" class="manage-list__controls">
                            <a @click="openEdit(filter.id)" class="link-edit">Edit</a>
                            <a @click="deletePreset(filter.id)" class="link-remove">Delete</a>
                        </div>
                    </li>
                </ul>
            </div>
        </b-modal>

        <EditPresetModal
            ref="editPresetModal"
            @updated="getPresets"
            @closed="showModal = true"
        />
    </div>
</template>

<style scoped>

</style>
