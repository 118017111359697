export const customCategories = [
    1,
    10,
    12,
    13,
    14,
    24,
    25,
    26,
    27,
    30,
    33,
    51,
    54,
    56,
    62,
    72,
    75,
    77,
    78,
    85,
    86,
    87,
    106,
    113,
    151,
    152,
    156,
    162,
    163,
    165,
    170,
    173,
    183,
    208,
    247,
    255,
    272,
    289,
    290,
    294,
    308,
    310,
    311,
    312,
    319,
    326,
    350,
    351,
    352,
    359,
    365,
    374,
    378,
    389,
    390,
    391,
    397,
    399,
    401,
    407,
    410,
    413,
    426,
    427,
    430,
    431,
    441,
    442,
    450,
    452,
    456,
    457,
    465,
    471,
    474,
    486,
    492,
    505,
    509,
    518,
    524,
    526,
    537,
    541,
    543,
    548,
    549,
    552,
    555,
    582,
    583,
    602,
    603,
    604,
    622,
    623,
    626,
    637,
    638,
    641,
    643,
    671,
    673,
    675,
    676,
    1012,
    1014,
    1015,
    1019,
    1024,
    1040,
];
