import axios from '@/axios';

const RESOURCE_NAME = '/awards';

export default {

    getDetails(id) {
        return axios.get(`${RESOURCE_NAME}/get-details?id=${id}`);
    },
}
