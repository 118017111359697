<template>
    <div id="scroll-div">
        <div v-for="(typeLogs, typeIndex) in logs" :key="typeIndex">
            <template v-if="typeLogs.logs.length">
                <div class="content__inner-section">
                    <div class="content__topbar" v-if="typeof types[typeLogs.type] !== 'undefined'">{{types[typeLogs.type]}}</div>
                    <div class="content__topbar" v-else>{{typeLogs.type}}</div>

                    <ActivityFeedSection
                        :logs="typeLogs.logs"
                        :type="typeLogs.type"
                        :prefix="modal + '-' + typeIndex"
                    />

                </div>
            </template>
        </div>

        <div class="no-results-section" v-if="!hasLogs && loaded">
            <h3 class="heading3">No Results</h3>

            <p>Try changing your filters to see activity from users in your company</p>
        </div>

        <div class="loading__spinner">
            <span v-if="loading"><b-spinner class="mr-1" small></b-spinner>Loading...</span>
            <a v-if="hasMore && !loading" @click="getList">Load More</a>
        </div>

        <ScrolledBottom @scrolledBottom = scrolledBottom />
    </div>
</template>

<script>
import actionLogs from "@/services/action-logs.service";
import ActivityFeedSection from "@/components/activity/ActivityFeedSection";
import {activityFeedTypes} from "@/constants/constants";
import ScrolledBottom from "@/components/ScrolledBottom.vue";

export default {
    props: {
        filter: {
            type: Object,
            default: null
        },

        modal: Boolean,
        showAll: Boolean,
    },

    components: {
        ScrolledBottom,
        ActivityFeedSection,
    },

    data() {
        return {
            logs: [],
            types: activityFeedTypes,
            loaded: false,

            maxDate: null,
            loading: false,
            hasMore: true,
        }
    },

    computed: {
        hasLogs() {
            let hasLogs = false

            for (let i in this.logs) {
                if (this.logs[i].logs.length) {
                    hasLogs = true
                    break
                }
            }

            return hasLogs
        }
    },

    mounted() {
        this.getList()
    },

    methods: {
        updateList() {
            this.logs = []
            this.maxDate = null
            this.getList()
        },

        getList() {
            this.loading = true
            let empty = true
            let filter = this.filter

            if (filter) {
                filter.activityTypes.all = this.showAll
            }
            this.loaded = false

            actionLogs.getActionLogsFeed(filter, this.maxDate).then(({data}) => {
                let logs = data.logs
                for (let type in logs) {

                    if (logs[type].length && empty) {
                        empty = false
                    }

                    let section = this.logs.find(item => item.type === type)

                    if (section === undefined) {
                        section = {
                            type:  type,
                            logs: [],
                        }

                        this.logs.push(section)
                    }

                    section.logs = section.logs.concat(logs[type])
                }

                this.loading = false
                this.maxDate = data.max_date

                let logsLength = 0
                for (let i in data.logs) {
                    logsLength += data.logs[i].length
                }

                this.hasMore = logsLength >= 20

                this.loaded = true
            })
        },

        scrolledBottom() {
            if (this.hasMore && this.logs.length) {
                this.getList()
            }
        },
    }
}
</script>

<style scoped>

</style>
