<script setup>
import {inject, reactive, ref, watch} from "vue";

const showModal = ref(false);
import useForm from "@/composables/useForm";
import savedFiltersService from "@/services/saved-filters.service";

const emit = defineEmits(['created', 'updated', 'closed']);

const filters = inject('filtersForServer');
const id = ref(0);

const preset = reactive({
    name: "",
    type: "personal",
});

const {populateForm, resetForm} = useForm(preset);

watch(showModal, (value) => {
    if (!value) {
        emit('closed');
    }
});

async function getData() {
    const {data} = await savedFiltersService.getEdit(id.value);

    populateForm(data);
}

async function save() {
    let form = {...preset};

    if (!id.value) {
        form.filter = filters.value;
    }

    const {data} = await savedFiltersService.save(id.value, form);
    showModal.value = false;

    if (id.value) {
        emit('updated');
    } else {
        emit('created', data);
    }
}

function open(presetId = 0) {
    showModal.value = true;

    if (presetId) {
        id.value = presetId;
        getData();
    } else {
        id.value = 0;
        resetForm();
    }
}

defineExpose({
    open
});
</script>

<template>
    <b-modal v-model="showModal" title="Save Preset" modal-class="modal-w-lg">
        <form class="form form-modal">
            <div class="form__item mb-4">
                <label class="form__label">Preset Name</label>
                <input v-model="preset.name" class="form-control form-control--mid">
            </div>

            <div class="form__item">
                <label class="form__label">Privacy</label>

                <div class="input-radio mb-3">
                    <input id="pr1" type="radio" v-model="preset.type" value="personal">
                    <label for="pr1">Only me</label>
                </div>

                <div class="input-radio">
                    <input id="pr2" type="radio" v-model="preset.type" value="company">
                    <label for="pr2">Shared with company</label>
                </div>
            </div>
        </form>

        <template #modal-footer>
            <b-button :disabled="!preset.name.length" variant="primary" size="lg" @click="save">Save</b-button>
        </template>
    </b-modal>
</template>

<style scoped>

</style>
