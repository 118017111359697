<template>
    <div>
        <highcharts
            v-if="loaded"
            :options="getOptions(chartOptions)"
            ref="chart"
        />

        <div v-else class="d-flex justify-content-center">
            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
        </div>
    </div>
</template>

<script>
import {Chart} from 'highcharts-vue'
import dashboardChartMixin from '@/mixins/dashboardChartMixin'


export default {
    name: 'RaisedByStageColumnChart',
    mixins: [dashboardChartMixin],
    components: {
        highcharts: Chart
    },
    props: {
        investorId: Number,
        chartData: Array,
        maxValue: Number,
    },
    data() {
        return {
            loaded: false,
            chartName: "RaisedByStageColumn",
            chartOptions: {
                chart: {
                    type: "column",
                    width: 250,
                    height: 35,
                    spacingTop: 0,
                    spacingRight: 0,
                    spacingBottom: 1,
                    spacingLeft: 0,

                    style: {
                        fontFamily: '"Overpass", sans-serif;',
                    },
                },
                title: {
                    text: ""
                },
                credits: {
                    enabled: false
                },
                xAxis: {
                    categories: [],
                    title: {
                        text: null
                    },
                    labels: {
                        enabled: false
                    },
                },
                yAxis: {
                    title: {
                        text: null,
                    },
                    labels: {
                        enabled: false
                    },
                },
                tooltip: {
                    formatter: function () {
                        return this.point.label
                    },
                    hideDelay: 0,
                    outside: true,
                },
                series: [
                    {
                        name: "Stage",
                        data: [],
                        colorByPoint: true,
                    }
                ],
                plotOptions: {
                    series: {
                        shadow: false,
                    },
                    column: {
                        borderColor: 'silver'
                    }
                },
                exporting: {
                    enabled: false
                },

                legend:{
                    enabled: false
                },
            }
        }
    },

    watch: {
        chartData: {
            immediate: true,
            handler() {
                this.formatAndSetChartData();
            }
        }
    },

    methods: {
        formatAndSetChartData() {
            this.chartOptions.yAxis.max = this.maxValue;
            this.chartOptions.series[0].data = this.chartData;

            this.chartOptions.series[0].events = {
                click: (e) => {
                    if (e.point.y >= 1) {
                        this.$emit('chartClicked', [this.investorId, e.point.category])
                    }
                }
            }

            this.chartOptions.xAxis.categories = this.chartData.map(item => item.stage)
        },
    }
}
</script>
