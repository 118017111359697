import axios from '@/axios';

const RESOURCE_NAME = '/trackers-folders';

export default {
    // Folders
    getNumber() {
        return axios.get(`${RESOURCE_NAME}/get-number`)
    },

    getIndex(filterType, search) {
        return axios.get(`${RESOURCE_NAME}/get-index?filter_type=${filterType}&search=${search}`)
    },

    getList(id = 0, type = '') {
        return axios.get(`${RESOURCE_NAME}/get-list?must_have_id=${id}&type=${type}`)
    },

    getAvailableIds() {
        return axios.get(`${RESOURCE_NAME}/get-available-ids`)
    },

    getInfo(id) {
        return axios.get(`${RESOURCE_NAME}/get-info?id=${id}`)
    },

    getEdit(id) {
        return axios.get(`${RESOURCE_NAME}/get-edit?id=${id}`)
    },

    save(id, data) {
        return axios.post(`${RESOURCE_NAME}/save?id=${id}`, data)
    },

    delete(id) {
        return axios.post(`${RESOURCE_NAME}/delete-folder?id=${id}`)
    },

    archive(id) {
        return axios.post(`${RESOURCE_NAME}/archive?id=${id}`)
    },

    unarchive(id) {
        return axios.post(`${RESOURCE_NAME}/unarchive?id=${id}`)
    },

    addTrackers(id, trackerIds) {
        return axios.post(`${RESOURCE_NAME}/add-trackers?id=${id}`, {tracker_ids: trackerIds})
    },

    getFoldersAndTrackersList() {
        return axios.get(`${RESOURCE_NAME}/get-folders-and-trackers-list`)
    },

    getCollectionInfo(hash) {
        return axios.get(`${RESOURCE_NAME}/get-collection-info?hash=${hash}`)
    },
}
