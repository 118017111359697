<script setup>
import {getLogo} from "@/helpers/logo";
import {useStore} from "vue2-helpers/vuex";
import {ref, watch} from "vue";
import analystService from "@/services/analyst.service";
import AddAnalystHours from "@/components/subscriptions/AddAnalystHours.vue";
import MessageToManagerModal from "@/components/MessageToManagerModal.vue";

const store = useStore();
const messageModal = ref(null);
const analystHours = ref(null);
const addAnalystHours = ref(null);

watch(() => store.getters.isPremiumEnterprise, (value) => {
    if (value) {
        getAnalystHours();
    }
}, {immediate: true});

async function getAnalystHours() {
    const {data} = await analystService.getAnalystHoursLeft();
    analystHours.value = data;
    analystHours.value.left = data.added - data.spent;
    analystHours.value.percents = analystHours.value.left / data.added * 100;
}

</script>

<template>
    <div class="account-details-section">
        <div class="image-block-wrapper align-items-start">
            <div class="image-block image-block--mds mb-0">
                <img :src="getLogo(store.state.user.company_logo)" alt="img"/>
            </div>

            <div>
                <h5 class="heading5 heading5--secondary">{{ store.state.user.company_name }}</h5>
                <a class="show-account-details" v-b-toggle.account-details>Additional account details</a>
            </div>
        </div>

        <b-collapse id="account-details">
            <div class="company-add-section">
                <template v-if="!(store.getters.isCollinsEnterprise && !store.getters.isAdmin)">
                    <div class="section-header">
                        Account Manager
                    </div>

                    <div class="card card--bordered card--user">
                        <div class="card--user__inner mb-0">
                            <div class="card--user__image"></div>

                            <div class="manager_name">
                                Pali Sabbineni
                            </div>

                            <!-- <div class="card--user__action actions-column"> -->
                            <a class="card--user__action link-envelope-inline" @click="messageModal.show()">Send Message</a>
                            <!-- </div> -->
                        </div>

                        <MessageToManagerModal
                            ref="messageModal"
                            title="Message Account Manager"
                            body="Use your analyst hours to find the right startups for projects. Send us a message detailing what you’re looking for and how we can help."
                        />
                    </div>
                </template>

                <template v-if="analystHours && analystHours.has_analyst">
                    <div class="section-header">
                        Analyst Hours
                    </div>

                    <div class="flex-line card--bordered">
                        <div class="flex-fill">
                            <div v-if="analystHours.left" class="card-hours">
                                <div class="progress-wrapper" v-if="analystHours.percents">
                                    <div class="mb-1">{{analystHours.left}}/{{analystHours.added}} hours left</div>

                                    <div class="progress">
                                        <div
                                            class="progress-bar"
                                            role="progressbar"
                                            :style="{'width': analystHours.percents + '%'}"
                                            :aria-valuenow="analystHours.percents"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div v-else>
                                <p class="mb-0">You have no analyst hours.</p>
                            </div>
                        </div>

                        <!-- <div class="actions-column"> -->
                            <a @click="addAnalystHours.open()" class="card--user__action link-add-hours">Add Hours</a>
                        <!-- </div> -->
                    </div>

                    <AddAnalystHours
                        ref="addAnalystHours"
                        @analyst-hours-added="getAnalystHours"
                    />
                </template>
            </div>
        </b-collapse>
    </div>
</template>

<style scoped>

</style>
