<script setup>
import Vue, {ref} from "vue";
import userService from "@/services/user.service";
import PrimaryButton from "@/components/ui/PrimaryButton.vue";
const {type = 'account', title, body} = defineProps(['type', 'title', 'body']);

const showModal = ref(false);
const message = ref('');
const loading = ref(false);

async function sendMessage() {
    loading.value = true;
    const {data} = await userService.sendMessageToAccountManager({message: message.value, type, url: window.location.href});

    if (data) {
        Vue.notify({
            group: 'app',
            type: 'success',
            duration: 5000,
            text: 'Message sent, your account manager will contact you within 1-2 business days.'
        });
    } else {
        Vue.notify({
            group: 'app',
            type: 'error',
            duration: 5000,
            text: 'Error. Message has not been sent.'
        });
    }

    message.value = '';
    loading.value = false;
    showModal.value = false;
}

function show() {
    message.value = '';
    showModal.value = true;
}

defineExpose({
    show
});
</script>

<template>
    <b-modal v-model="showModal" :title="title">
        <p class="modal-text">
            {{body}}
        </p>

        <b-form-group label="Message">
            <b-form-textarea class="form-control--mid mb-3" v-model="message"></b-form-textarea>
        </b-form-group>

        <template #modal-footer>
            <PrimaryButton
                caption="Send Message"
                loading-caption="Sending..."
                :loading="loading"
                :disabled="!message.length"
                @click="sendMessage"
            />
        </template>
    </b-modal>
</template>

<style scoped>

</style>
