export default {
    namespaced: true,
    state: {
        defaultLayout: [
            {
                "h": 1,
                "i": 1,
                "w": 2,
                "x": 0,
                "y": 0,
                "img": "https://sp-images-prod.s3.amazonaws.com/assets/dashboard/StartupsLocationChart.png",
                "src": "StartupsLocationChart",
                "maxH": 4,
                "maxW": 12,
                "minH": 4,
                "minW": 4,
                "name": "Startups By Location",
            },
            {
                "h": 1,
                "i": 2,
                "w": 1,
                "x": 0,
                "y": 1,
                "img": "https://sp-images-prod.s3.amazonaws.com/assets/dashboard/StartupsCategoryChart.png",
                "src": "StartupsCategoryChart",
                "maxH": 4,
                "maxW": 12,
                "minH": 4,
                "minW": 4,
                "name": "Startups By Category",
            },
            {
                "h": 1,
                "i": 3,
                "w": 1,
                "x": 1,
                "y": 1,
                "img": "https://sp-images-prod.s3.amazonaws.com/assets/dashboard/StartupsStageChart.png",
                "src": "StartupsStageChart",
                "maxH": 4,
                "maxW": 12,
                "minH": 4,
                "minW": 4,
                "name": "Startup by Stage",
            }
        ]
    },
    mutations: {
        addChart(state, chartSettings) {
            if (!Array.isArray(this.state.user.settings.dashboard)) {
                this.state.user.settings.dashboard = state.defaultLayout
            }

            this.state.user.settings.dashboard.push(chartSettings)
        },
        changeLayout(state, layout) {
            this.state.user.settings.dashboard = layout
        },
    },
    getters: {
        layout: (state, getters, rootState) => {
            return Array.isArray(rootState.user.settings.dashboard) ? rootState.user.settings.dashboard : state.defaultLayout
        },
        isNoLayoutSettings: (state, getters, rootState) => {
            return !Array.isArray(rootState.user.settings.dashboard)
        }
    },
}
