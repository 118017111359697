<template>
    <div>
        <div v-if="loading" class="loading__spinner mt-3">
            <span><b-spinner class="mb-1 mr-2" small></b-spinner>Loading startups...</span>
        </div>
        <table class="details-table" v-if="!loading">
            <thead>
                <tr>
                    <th>Startup</th>
                    <th>
                        <div class="d-flex align-items-center">
                            Notes
                            
                            <!--<span class="hint-secondary"></span>-->
                            <b-button class="button-tooltip" v-b-tooltip.hover title="Add notes for the reviewing enterprise users about a startup"></b-button>
                        </div>
                    </th>
                    <th>Submitted</th>
                    <th>Stage</th>
                    <th>Remove</th>
                </tr>
            </thead>
            <tbody v-for="startup in data" v-bind:key="startup.id">
                <tr :class="startup.id === removingId ? 'startup-row removing' : 'startup-row'">
                    <td>
                        <div class="image-block-wrapper">
                            <div class="image-block">
                                <img :src="LogoHelper.getLogo(startup.logoUrl)" :alt="startup.name">
                            </div>

                            <h5 class="heading5 m-0">{{ startup.name }}</h5>
                        </div>
                    </td>
                    <td>
                        <a class="link" v-if="startup.note" @click="$bvModal.show(startup.id.toString())">View note</a>
                        <a class="link" v-if="!startup.note" @click="$bvModal.show(startup.id.toString())">+Add Note</a>
                        <note-modal
                            :tracker-id="opportunityId.toString()"
                            :startup="startup"
                            :on-success="updateStartupNote(startup.id)"
                        ></note-modal>
                    </td>
                    <td>{{ startup.submitted }}</td>
                    <td>{{ startup.stage }}</td>
                    <td>
                        <a
                            href="javascript:void(0)"
                            class="link-remove link-remove--blue"
                            @click="removeStartup(startup.id)"
                        ></a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style>
.startup-row {
    opacity: 1;
    transition: opacity .3s linear;
}
.startup-row.removing {
    opacity: .1;
    transition: opacity 1s ease-in-out;
}
</style>

<script>
import NoteModal from "@/views/opportunities/NoteModal";
import 'bootstrap-vue/dist/bootstrap-vue.css';

export default {
    name: 'StartupsTable',
    data() {
        return {
            data: []
        }
    },
    props: {
        opportunityId: Number,
        loading: Boolean,
        startups: Array,
        removingId: Number,
        removeStartup: Function
    },
    watch: {
        startups() {
            this.data = this.startups
        }
    },
    methods: {
        updateStartupNote(startupId) {
            return (note) => {
                this.data = this.data.reduce(
                    (acc, item) => [...acc, item.id === startupId ? { ...item, note } : item],
                    []
                )
            }
        }
    },
    mounted() {
        this.data = this.startups
    },
    components: {
        NoteModal
    }
}
</script>
