<script setup>
import VueMultiselect from "vue-multiselect";
import {onMounted, ref, watch, inject} from "vue";
import customFieldsService from "@/services/custom-fields.service";

const field = inject('field');
const id = inject('id');
const sectionId = inject('sectionId');
const parentFieldsList = ref(null);
const parentField = ref(null);
const parentFieldOption = ref(null);
const parentOptionsRef = ref([]);

watch(parentField, (value) => {
    if (value) {
        field.dependsOn = value.id;

        parentOptionsRef.value = field.options.reduce((acc, option) => {
            acc.push(value && option.depends_on ? value.options.find(parentOption => parentOption.id === option.depends_on) : null);
            return acc;
        }, []);
    } else {
        field.dependsOn = null;

        field.options.forEach(option => {
            option.depends_on = null;
        });
    }
})

watch(parentFieldOption, (value) => {
    if (value) {
        field.dependsOnOption = value.id;
    } else {
        field.dependsOnOption = null;
    }
})

watch(parentOptionsRef, () => {
    field.options.forEach((option, index) => {
        option.depends_on = parentOptionsRef.value[index]?.id ?? null;
    });
}, {deep: true})

watch([() => field.dependsOn, parentFieldsList],() => {
    if (field.dependsOn && parentFieldsList.value) {
        parentField.value = parentFieldsList.value.find(parentField => parentField.id === field.dependsOn);
    } else {
        parentField.value = null;
    }

    parentFieldOption.value = parentField.value?.options.find(option => option.id === field.dependsOnOption) ?? null;
})

onMounted(() => {
    getParentFieldsList();
});

async function getParentFieldsList() {
    const {data} = await customFieldsService.getParentsList(field.type, sectionId, id);
    parentFieldsList.value = data;
}

function addOptionRef() {
    if (parentField.value) {
        parentOptionsRef.value.push(null);
    }
}

defineExpose({
    addOptionRef
})
</script>

<template>
    <div class="gray-bg-container" v-if="['company_diligence','tracker_submit'].includes(field.type)">
        <h5 class="heading5 heading5--secondary">
            Dependency
        </h5>

        <p class="modal-text">Make options dependant on selected parent fields</p>

        <div class="flex-line mt-1">
            <span class="text-nowrap"><b>Parent Field:</b></span>

            <VueMultiselect
                v-if="parentFieldsList !== null"
                class="multiselect-mid light-select ml-2"
                v-model="parentField"
                :options="parentFieldsList"
                placeholder="Select A Field"
                select-label=""
                deselect-label=""
                label="name"
                track-by="name"
            />
        </div>

        <div v-if="parentField && parentField.options.length" class="flex-line mt-1">
            <span class="text-nowrap"><b>Parent Option:</b></span>

            <VueMultiselect
                class="multiselect-mid light-select ml-2"
                v-model="parentFieldOption"
                :options="parentField.options"
                placeholder="Select Option"
                select-label=""
                deselect-label=""
                label="value"
                track-by="value"
            />
        </div>

        <template v-if="field.fieldType && parentField && field.options.length">
            <template v-if="['select','users_list'].includes(field.fieldType)">
                <h5 class="heading5 heading5--secondary text-grey2-light mt-3 mb-3">
                    OPTIONS
                </h5>

                <div class="form-grid-2" v-if="Object.keys(parentOptionsRef).length">
                    <div
                        v-for="(option, index) in field.options"
                        :key="index"
                    >
                        <div v-if="option.value">
                            <div>
                                <b>{{option.value?.name || option.value}}</b>
                            </div>

                            <div class="flex-line">
                                <VueMultiselect
                                    v-model="parentOptionsRef[index]"
                                    class="multiselect-mid light-select"
                                    :options="parentField.options"
                                    placeholder="Select Parent Option"
                                    select-label=""
                                    deselect-label=""
                                    label="value"
                                    track-by="value"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>

<style scoped>

</style>
