<script setup>
import {onMounted, ref} from "vue";
import startupsService from "@/services/startups.service";
import AutoCompleter from "@/components/AutoCompleter.vue";
import categoriesService from "@/services/categories.service";
import {useStore} from "vue2-helpers/vuex";
import {getLogo} from "@/helpers/logo";
import StartupModal from "@/views/enterprise/home/content/trending/StartupModal.vue";

const store = useStore();
const startups = ref([]);
const categories = ref([]);
const searchCategory = ref('');
const startupModal = ref(null);
const showModal = ref(false);

onMounted(() => {
    getStartups();
});

async function getStartups() {
    let selectedCategories = categories.value.map(item => item.id);

    if (!selectedCategories.length) {
        selectedCategories = [0];
    }

    selectedCategories.forEach(categoryId => {
        if (!startups.value.some(item => item.category === categoryId)) {
            startupsService.getTopStartups(categoryId).then(({data}) => {
                startups.value.push({
                    'category': categoryId,
                    'startups': data
                });
            });
        }
    })
}

function categorySubmitted(id) {
    categories.value.push(store.state.categories.find(item =>item.id === id));
    getStartups();
}

function showStartup(startup) {
    startupModal.value.open(startup);
}
</script>

<template>
    <section class="dashboard--trending">
        <div class="card card--bordered card--dashboard">
            <header class="dashboard__header">
                <h3 class="heading3">Trending On SwitchPitch</h3>

                <div class="form-search-primary">
                    <AutoCompleter
                        ref="keywordAutocompleter"
                        placeHolder="Search by category"
                        :search-string.sync="searchCategory"
                        :update-function=categoriesService.getFilteredList
                        :clearOnSubmit=true
                        :exclude="categories.map(item => item.id)"
                        v-on:submitted="categorySubmitted"
                    />
                </div>
            </header>

            <div>
                <p class="mr-0">Ranked using our IRL (Innovation Readiness Level) score <a @click="showModal = true" class="link link-open">How IRL Score Works</a></p>

                <div v-if="categories.length" class="tags-list tags-list--dark">
                    <div class="tags-list__title">Filtered by</div>

                    <span
                        v-for="category in categories"
                        :key="category.id"
                        class="tags-list__tag"
                    >

                        <span href="#">{{ category.name }}</span>
                        <a class="tags-list__tag__close"
                           @click="categories = categories.filter(item => item !== category)"></a>
                    </span>
                </div>

                <div v-for="section in startups" :key="section.category">
                    <div v-if="categories.some(category => section.category === category.id) || (!categories.length && section.category === 0)">
                        <h5 v-if="section.category > 0" class="heading5 heading5--secondary">
                            {{ categories.find(category => section.category === category.id).name }}
                        </h5>

                        <ul v-if="section.startups.length" class="list-cards-inrow list-cards-inrow--in3 mb-0">
                            <li v-for="startup in section.startups" :key="startup.id">
                                <div class="list-cards-inrow__content">
                                    <div class="image-block-wrapper">
                                        <div class="image-block">
                                            <img :src="getLogo(startup.logo)" :alt="startup.name"/>
                                        </div>

                                        <div>
                                            <h5 class="heading5"><a :href="'/startups/' + startup.id" target="_blank">{{ startup.name }}</a></h5>

                                            <ul class="list-activity">
                                                <li class="list-activity__startup">{{ startup.stage }}</li>
                                            </ul>
                                        </div>
                                        <div class="icon-speed"
                                             :class="'icon-speed--' + startup.score">IRL:
                                            {{ startup.score }}
                                        </div>
                                    </div>
                                </div>

                                <footer v-if="startup.categories.length" class="list-cards-inrow__footer">
                                    <div class="tags-list tags-list--light">
                                        <div
                                            v-for="category in startup.categories.slice(0, 5)"
                                            :key="category.id"
                                            class="tags-list__tag" >
                                            <a :href="'/startups?category=' + category.name" target="_blank">{{ category.name }}</a>
                                        </div>

                                        <a v-if="startup.categories.length > 5"
                                           @click="showStartup(startup)"
                                           class="link">More</a>
                                    </div>
                                </footer>
                            </li>

                            <li class="list-cards-inrow__empty"></li>
                        </ul>

                        <div v-else-if="section.category > 0" class="no-results">
                            <div>
                                No results for {{categories.find(category => section.category === category.id).name}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <b-modal v-model="showModal" modal-class="modal-w-lg" hide-footer>
                <p>SwitchPitch's IRL (Innovation Readiness Level) score is crafted to provide a comprehensive evaluation of a startup's readiness for corporate partnerships.</p>
                <p>Operating on a scale ranging from 1 to 10, this score is computed by corporate startup traction, depth of engagement with corporate entities, frequency of visits to a startup's profile, as well as startup traction data from outside databases.</p>
                <p><strong>Scores of 6 or higher</strong> indicate that startups have substantial interaction with corporate clients based on SwitchPitch's intelligence. <strong>Scores ranging from 1 to 5</strong> suggest a limited or unknown level of interaction between startups and corporate clients.</p>
            </b-modal>

            <StartupModal ref="startupModal"/>
        </div>
    </section>
</template>

<style scoped>
    .dashboard__content {
        &>div {
            flex: 1;
        }
    }
</style>
