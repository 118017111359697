<script setup>
import {Avatar} from "vue-avatar";
import {getLogo} from "@/helpers/logo";
import {ref, onMounted, computed, getCurrentInstance} from "vue";
import {useStore} from "vue2-helpers/vuex";

const {log} = defineProps(['log']);
const instance = getCurrentInstance();

const store = useStore();
const uid = instance.proxy._uid;
const itemsContainer = ref();
const visibleCount = ref(0);
const moreCount = ref(0);
const showAll = ref(false);

const items = computed(() => {
    const types = ['startups', 'ecosystems', 'users', 'trackers', 'fields', 'tags', 'tasks', 'contacts', 'files'];

    for (const type of types) {
        if (type in log) {
            return type === 'fields' ?
                {
                    items: log[type]
                } :
                {
                    type,
                    items: log[type]
                };
        }
    }

    return null;
});

onMounted(() => {
    if (itemsContainer.value) {
        let width = 0;

        for (let div of itemsContainer.value.children) {
            width += div.clientWidth + 10;

            if (width <= itemsContainer.value.clientWidth) {
                visibleCount.value++;
            } else {
                break;
            }
        }

        moreCount.value = itemsContainer.value.children.length - visibleCount.value;
    }
});

function getItemIcon(item) {
    if (items.value.type === 'trackers') {
        return require('@/images/icon-tracker-gray-light.svg');
    } else if (items.value.type === 'tags') {
        return require('@/images/icon-tag-light.svg');
    } else if (items.value.type === 'files') {
        return require('@/images/icon-file.svg');
    } else {
        return getLogo(item.logo);
    }
}

function itemExists(type, id) {
    if (type === 'trackers' && !store.getters.availableTrackersIds.some(item => parseInt(item) === parseInt(id))) {
        return false;
    } else if (type === 'tasks' && !store.getters.availableTasksIds.some(item => parseInt(item) === parseInt(id))) {
        return false;
    } else if (type === 'folder' && !store.getters.availableFoldersIds.some(item => parseInt(item) === parseInt(id))) {
        return false;
    }

    return true;
}
</script>

<template>
    <div class="activity-item">
        <div class="activity-item-header">
            <Avatar
                :username="log.user_name_for_avatar"
                :src="log.user_avatar"
                :size="42"
                class="activity-item__image"
            />

            <div class="activity-item__data">
                <h6 class="heading6" :class="{'user-deleted': log.user_deleted}">{{log.user_name}}</h6>

                <div v-if="'startup' in log" class="object object--startup">
                    <a :href="'/startups/' + log.startup.id" target="_blank">{{log.startup.name}}</a>
                </div>

                <div v-if="'ecosystem' in log" class="object object--ecosystem">
                    <a :href="'/ecosystems/' + log.ecosystem.id" target="_blank">{{log.ecosystem.name}}</a>
                </div>

                <div v-if="'tracker' in log" class="object object--tracker">
                    <template v-if="!itemExists('trackers', log.tracker.id)">
                        {{log.tracker.name}}
                    </template>

                    <a v-else :href="'/trackers/' + log.tracker.id" target="_blank">{{log.tracker.name}}</a>
                </div>

                <div v-if="'folder' in log" class="object object--folder">
                    <template v-if="!itemExists('folder', log.folder.id)">
                        {{log.folder.name}}
                    </template>

                    <a v-else :href="'/trackers/folder/' + log.folder.id" target="_blank">{{log.folder.name}}</a>
                </div>
            </div>

            <time class="activity-item__time">{{log.time}}</time>
        </div>

        <div class="activity-item__operation">
            <span
                v-if="'note' in log"
                class="interaction"
                :class="'interaction--' + log.note.type"
            >
                {{log.operation}}
            </span>

            <template v-else-if="'field_value' in log">
                {{log.operation}}: {{log.field_value.name}}
            </template>

            <template v-else>
                {{log.operation}}
            </template>
        </div>

        <div
            v-if="'note' in log || 'field_value' in log"
            class="activity-comment line-clamp line-clamp1"
        >
            <template v-if="'note' in log">
                {{log.note.text}}
            </template>

            <template v-if="'field_value' in log && log.field_value.value.length">
                {{log.field_value.value}}
            </template>
        </div>

        <div
            ref="itemsContainer"
            v-if="items"
            class="activity-item-list"
        >
            <div
                v-for="(item, index) in items.items"
                :key="index"
                :style="{display: moreCount > 0 && !showAll && index >= visibleCount ? 'none' : 'inline-flex'}"
            >
                <template v-if="['users','contacts'].includes(items.type)">
                    <div class="img-avatar">
                        <Avatar
                            :username="item.name"
                            :src="item.avatar"
                            :size="24"
                        />
                    </div>
                    {{item.name}}
                </template>

                <template v-else-if="!('type' in items)">
                    {{item.name}}
                </template>

                <template v-else>
                    <template v-if="!itemExists(items.type, item.id)" class="img">
                        {{item.name}}
                    </template>
<!-- 
                    <template v-else-if="items.type === 'trackers'" class="img-icon-wrapper">
                        <img :src="getItemIcon(item)" alt=""> 
                        {{item.name}}
                    </template> -->
                    <template v-else-if="items.type === 'tags'">
                        <a
                            :id="`tag-${uid}-${item.id}`"
                            :href="'/startups?tag=' + item.name"
                            target="_blank"
                            class="img-icon-wrapper"
                        >
                            <img :src="getItemIcon(item)" alt=""> 
                            {{item.name}}
                        </a>

                        <b-tooltip
                            v-if="item.description.length"
                            :target="`tag-${uid}-${item.id}`"
                            placement="right"
                            variant="light"
                            :title="item.description"
                        />
                    </template>

                    <a v-else-if="items.type === 'tasks'" :href="'/?open_task=' + item.id" target="_blank">{{item.name}}</a>
                    <span v-else-if="items.type === 'files'">{{item.name}}</span>
                    <a v-else :href="'/' + items.type + '/' + item.id" target="_blank" class="img-icon-wrapper">
                        <img :src="getItemIcon(item)" alt="">
                        {{item.name}}
                    </a>
                </template>

                <template v-if="'startup_stage' in log">

                </template>
            </div>
        </div>

        <div class="show-more" v-if="moreCount > 0 && !showAll">
            <div>
                <a @click="showAll = true">Show {{moreCount}} more</a>
            </div>
        </div>
    </div>
</template>

<style scoped>
    @import "../style.scss";

    .user {
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .user-deleted {
        text-decoration: line-through;
    }
    .img-icon {
        width: 16px;
        height: 16px;
        box-shadow: none;
        border-radius: none;
        background: transparent;

        img {
            width: 16px;
        }
    }
    .img-icon-wrapper {
        display: flex;
        align-items: center;

        img {
            margin-right: 7px;
            width: 16px;
            height: 16px;
        }
    }
    .img-avatar {
        margin-right: 10px;
        box-shadow: none;
        border-radius: none;
        background: transparent;
    }
</style>
