<script setup>
import {getLogo} from "@/helpers/logo";
import {ref, onMounted, watch} from "vue";
import publicTrackersService from "@/services/public-trackers.service";
import {decodeDate} from "../../helpers/date";
import {useStore} from "vue2-helpers/vuex";
import trackersService from "@/services/trackers.service";
import CustomFieldValue from "@/components/customFields/CustomFieldValue.vue";
import {useRoute} from "vue2-helpers/vue-router";
import profileService from "@/services/startup/profile.service";
import ProfileInfoForm from "@/components/startup/Profile.vue";
import {fieldFilled} from "@/helpers/string";
import {validateNumericValue} from "@/helpers/functions";
import trackersCompaniesService from "@/services/trackers-companies.service";
import constants from "@/constants/constants";

const {trackerId, fromPreview = false} = defineProps(['trackerId','fromPreview']);
const emit = defineEmits(['login']);
const store = useStore();
const route = useRoute();

const info = ref(null);
const applicationStatus = ref(null);
const wantsApply = ref(false);
const canApply = ref(false);
const showApplicationForm = ref(false);
const showProfileModal = ref(false);
const showTermsModal = ref(false);
const loadingQuestions = ref(false);
const agreed = ref(false);
const sendingApplication = ref(false);
const questions = ref([]);

watch(() => store.getters.isLoggedIn, async (value) => {
    if (value && !fromPreview) {
        await checkApplicationStatus();
    }
}, {immediate: true});

watch([wantsApply, () => store.getters.isLoggedIn], () => {
    if (store.getters.isLoggedIn && wantsApply.value) {
        checkCanApply()
    }
});

watch([applicationStatus, canApply], () => {
    if (canApply.value && applicationStatus.value !== 'applied') {
        showApplicationForm.value = true;
        getQuestions();
    }
});

onMounted( async () => {
    await getInfo();

    if (route.query['apply']) {
        applyClicked();
    }
});

function applyClicked() {
    if (fromPreview) {
        canApply.value = true;
    } else {
        wantsApply.value = true;

        if (store.getters.isLoggedIn) {
            checkCanApply();
        } else {
            emit('login');
        }
    }
}

async function getQuestions() {
    loadingQuestions.value = true;

    const {data} = await trackersService.getQuestions(trackerId);
    questions.value = data.map(question => ({
        ...question,
        error: '',
    }));

    loadingQuestions.value = false;
}

async function checkCanApply() {
    if (info.value.need_complete_profile) {
        const {data} = await profileService.profileCompleted();

        if (!data) {
            if (store.state.user.is_admin) {
                showProfileModal.value = true;
            } else {
                alert("Profile info must be completed");
            }
        } else {
            canApply.value = true;
        }
    } else {
        canApply.value = true;
    }
}

async function checkApplicationStatus() {
    const {data} = await trackersService.checkApplicationStatus(trackerId);
    applicationStatus.value = data;
}

function profileSaved() {
    showProfileModal.value = false;
    checkCanApply();
}

async function getInfo() {
    const {data} = fromPreview
        ? await trackersService.getApplicationInfo(trackerId)
        : await publicTrackersService.getApplicationInfo(trackerId);
    info.value = data;
}

function answerChanged(index, value) {
    questions.value[index].value = value;
}

async function apply() {
    validateAnswers();

    if (questions.value.some(question => question.error.length)) {
        return;
    }

    sendingApplication.value = true;

    let newFilesFields = questions.value.filter(field => field.value.files.some(file => file.id === 0));
    let newFilesFieldsCount = newFilesFields.length;

    if (newFilesFieldsCount) {
        sendingApplication.value = true;
        let processedCount = 0;
        let successCount = 0;

        newFilesFields.forEach(field => {
            let formData = new FormData();

            field.value.files.forEach(file => {
                formData.append('file[]', file)
            })

            trackersCompaniesService.saveAnswerFiles(trackerId, field.field.id, formData).then(response => {
                processedCount++;

                if (response.data) {
                    successCount++;
                } else {
                    questions.value.find(item => item.field.id === field.field.id).error = 'File was not uploaded';
                }

                if (processedCount === newFilesFieldsCount) {
                    if (processedCount === successCount) {
                        sendApplication();
                    } else {
                        sendingApplication.value = false;
                        trackersCompaniesService.deleteAnswers(trackerId);
                    }
                }
            });
        })
    } else {
        sendApplication();
    }
}

async function sendApplication() {
    await trackersService.apply(trackerId, {answers: questions.value});
    applicationStatus.value = 'applied';
    showApplicationForm.value = false;
    sendingApplication.value = false;
}

function validateAnswers() {
    for (let i in questions.value) {
        questions.value[i].error = ''

        if (questions.value[i].required && !fieldFilled(questions.value[i].value)) {
            questions.value[i].error = 'This field is mandatory';
        }

        let error = validateNumericValue(questions.value[i].field, questions.value[i].value.text);

        if (error) {
            questions.value[i].error = error;
        }
    }
}
</script>

<template>
    <div v-if="info !== null">
        <div class="topbar">
            <div class="d-flex align-items-center">
                <div class="image-block image-block--lg mr-4">
                    <img :src="getLogo(info.company_logo)" alt="img">
                </div>

                <div>
                    <h2 class="heading2">{{ info.company_name }}</h2>
                    <p v-if="info.company_id !== constants.companyCollins" class="text-big">Posted {{ decodeDate(info.posted, ['month-str']) }}</p>
                </div>
            </div>
        </div>

        <div class="main-inner min-vh-100" v-if="!showApplicationForm">
            <div class="card card--info w-md-75 mx-auto" v-if="info.company_description.length">
                <div class="d-flex">
                    <font-awesome-icon class="fa-2x" :icon="['fa', 'info-circle']" />
                    <div class="pl-3">
                        <h4 class="heading4">About {{ info.company_name }}</h4>
                        <div v-html="info.company_description"></div>
                    </div>
                </div>
            </div>

            <div class="public-content w-md-75 mx-auto">
                <h3 class="heading3 mb-4">{{ info.name }}</h3>

                <div v-html="info.description"></div>

                <p v-if="info.categories.length">
                    <b>Categories:</b>
                    {{ info.categories.join(', ') }}
                </p>
                <p v-if="info.start_date && info.end_date">
                    <b>Timeline:</b> {{ decodeDate(info.start_date, ['month-str']) }} - {{ decodeDate(info.end_date, ['month-str']) }}
                </p>

                <template v-if="info.submitter_fields.length">
                    <h5 class="heading5 heading5--secondary mr-4 mb-0">Tracker Details</h5>

                    <div v-for="(field, index) in info.submitter_fields" :key="index" class="mb-4">
                        <h5 class="heading5 heading5--secondary">{{field.field.name}}</h5>

                        <CustomFieldValue
                            :field="field.field"
                            :init-value="field.value"
                            :editable="false"
                        />
                    </div>
                </template>

                <template v-if="!fromPreview && applicationStatus === 'applied'">
                    <div class="confirmation-send">Your application has been submitted to the {{ info.name }}</div>
                </template>

                <div v-if="fromPreview || applicationStatus !== 'applied'" class="text-center pt-3">
                    <a @click="applyClicked" class="btn btn-primary btn-lg">Apply</a>
                </div>
            </div>
        </div>

        <template v-else>
            <div class="main-inner min-vh-100">
                <div class="w-50 mx-auto">
                    <h3 class="heading3 mb-5">{{ info.name }}</h3>
                    <table class="details-table form m-0 w-100">
                        <div v-if="loadingQuestions" class="loading__spinner mt-3">
                            <span><b-spinner class="mb-1 mr-2" small></b-spinner>Loading Questions...</span>
                        </div>
                        <tbody>
                            <tr v-for="(question, index) in questions" :key="index">
                                <td :class="[question.field.type === 'section_text' ? 'section-text' : '']">
                                    {{question.field.name}} <span v-if="question.required">*</span>
                                </td>

                                <td v-if="['static_text','section_text'].indexOf(question.field.type) < 0">
                                    <CustomFieldValue
                                        :field="question.field"
                                        :init-value="question.value"
                                        :instantUpdate="false"
                                        @valueChanged="(value) => answerChanged(index, value)"
                                    />
                                    <span class="form__error" v-if="!fromPreview && question.error.length">{{question.error}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <template v-if="info.has_terms && !loadingQuestions">
                        <div class="input-checkbox">
                            <input id="terms-agree" type="checkbox" v-model="agreed">
                            <label for="terms-agree">I have read and agree to the Challenge <a @click="showTermsModal = true" class="ml-1">Terms & Conditions</a></label>
                        </div>
                    </template>

                    <div v-if="!fromPreview && !loadingQuestions" class="text-center pt-3">
                        <button @click="apply" class="btn btn-primary btn-lg" :disabled="(info.has_terms && !agreed) || sendingApplication">Submit Application</button>
                    </div>

                </div>

            </div>

        </template>

        <b-modal v-model="showProfileModal" :hide-footer="true" modal-class="modal-w-lg" title="Complete Your Company Profile">
            <ProfileInfoForm
                :from-tracker="true"
                @saved="profileSaved"
            />
        </b-modal>

        <b-modal v-model="showTermsModal" :hide-footer="true" modal-class="modal-w-xxl" title="">
            <template #modal-title>
                <div class="modal-top">
                    <h2 class="heading2 mb-0">Terms and Conditions</h2>
                </div>
            </template>

            <div v-html="info.terms"></div>
        </b-modal>
    </div>
</template>

<style scoped>
</style>
