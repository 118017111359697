<script setup>
import {useStore} from "vue2-helpers/vuex";
import {ref, computed, inject} from "vue";
import {groupObjectsBy} from "@/helpers/array";
import MeetingsTrackersChart from '@/components/charts/MeetingsTrackersChart.vue';
import StartupsLocationChart from '@/components/charts/StartupsLocationChart.vue';
import StartupsCategoryChart from '@/components/charts/StartupsCategoryChart.vue';
import StartupsStageChart from "@/components/charts/StartupsStageChart.vue";
import StartupsStageColumnChart from "@/components/charts/StartupsStageColumnChart.vue";
import ScreenedByTrackersChart from "@/components/charts/ScreenedByTrackersChart.vue";
import TrackerStagesFunnelChart from "@/components/charts/TrackerStagesFunnelChart.vue";
import StartupsByDateAndTrackerChart from "@/components/charts/StartupsByDateAndTrackerChart.vue";
import StartupsByYearChart from "@/components/charts/StartupsByYearChart.vue";
import UniqueStartupsTrackedCounterChart from "@/components/charts/UniqueStartupsTrackedCounterChart.vue";
import CompaniesByTrackersAndCategoriesChart from "@/components/charts/CompaniesByTrackersAndCategoriesChart.vue";
import StartupsByRegionChart from "@/components/charts/StartupsByRegionChart.vue";
import CustomFieldFundingScatterChart from "@/components/charts/private/mars/CustomFieldFundingScatterChart.vue";
import StartupsByProblemStatementChart from "@/components/charts/private/mars/StartupsByProblemStatementChart.vue";
import StartupsBySourceChart from "@/components/charts/private/mars/StartupsBySourceChart.vue";
import TopInvestorsChart from "@/components/charts/private/mars/TopInvestorsChart.vue";
import StartupsModal from "@/components/charts/StartupsModal.vue";
import RequestsByBusinessArea from "@/components/charts/private/continental/RequestsByBusinessArea.vue";
import RequestsByStageAndStatus from "@/components/charts/private/continental/RequestsByStageAndStatus.vue";

const store = useStore();
const filters = inject('filters');
const startupsModal = ref(null);
const currentChart = ref(null);

const chartRefs = ref({});

const chartComponents = {
    StartupsLocationChart,
    StartupsCategoryChart,
    StartupsStageChart,
    StartupsStageColumnChart,
    MeetingsTrackersChart,
    ScreenedByTrackersChart,
    TrackerStagesFunnelChart,
    StartupsByDateAndTrackerChart,
    StartupsByYearChart,
    UniqueStartupsTrackedCounterChart,
    CompaniesByTrackersAndCategoriesChart,
    StartupsByRegionChart,
    CustomFieldFundingScatterChart,
    StartupsByProblemStatementChart,
    StartupsBySourceChart,
    TopInvestorsChart,
    RequestsByBusinessArea,
    RequestsByStageAndStatus
};

const layout = computed(() => {
    // group by value of 'y' prop to be able to get charts by row
    let structuredLayout = groupObjectsBy(store.getters['dashboard/layout'], 'y')

    // sort charts in row
    structuredLayout.forEach((row) => {
        row.sort((a, b) => a.x - b.x)
    });

    // sort rows of charts
    structuredLayout.sort((a, b) =>  a[0].y - b[0].y);

    return structuredLayout;
});

function setChartRef(el, chartName) {
    chartRefs.value[chartName] = el;
}

function showStartups(chart, params) {
    currentChart.value = chart;
    startupsModal.value.open(...params);
}

function refreshChartData() {
    chartRefs.value[currentChart.value].fetchChartData();
}
</script>

<template>
    <div>
        <div v-if="!store.getters.allTrackersList.length">
            <div class="dashboard__content charts__no__data">
                <h4 class="heading4 mb-4">Start Innovating</h4>
                <p>Create trackers and add startups to see trends, categories and other data.</p>
            </div>
        </div>

        <div v-else class="charts">
            <div
                v-for="(chartsRow, index) in layout"
                :key="index"
                class="chart-row"
            >
                <div
                    v-for="chart in chartsRow"
                    :key="chart.i"
                    class="chart"
                    :class="'w-' + chart.w"
                >
                    <div class="graph-container">
                        <Component
                            :ref="(el) => setChartRef(el, chart.src)"
                            :is="chartComponents[chart.src]"
                            :chartSettings="chart"
                            :filters="filters"
                            @showStartups="(params) => showStartups(chart.src, params)"
                        />
                    </div>
                </div>
            </div>

            <StartupsModal
                ref="startupsModal"
                :filters="filters"
                @startupRemovedFromTracker="refreshChartData"
            />
        </div>
    </div>
</template>

<style scoped>
</style>
