<script setup>
import {getShortFullName} from "@/helpers/string";
import Avatar from "@/components/ui/Avatar.vue";
import {ref} from "vue";
import InteractionEditModal from "@/components/interactions/InteractionEditModal.vue";
import actionLogsService from "@/services/action-logs.service";
import ActivityContent from "@/components/activity/ActivityContent.vue";

defineProps(['activities','isPreview','loading']);
const emit = defineEmits(['editModalOpened','editModalClosed','activitiesChanged']);

const interactionEditModal = ref(null);

function editActivity(activity) {
    emit('editModalOpened');

    if (activity.type.includes('note')) {
        interactionEditModal.value.openEditNote(activity.object_id)
    } else {
        interactionEditModal.value.openEditInteraction(activity.object_id);
    }
}

async function removeActivity(activity) {
    if (confirm("Remove activity?")) {
        await actionLogsService.removeActivity(activity.log_id);
        emit('activitiesChanged');
    }
}

</script>

<template>
    <div>
        <div v-if="loading" class="loading__spinner">
            <span><b-spinner class="mr-1" small></b-spinner>Loading...</span>
        </div>

        <template v-if="activities !== null">
            <ul v-if="activities.length" class="activity-list action-logs-list">
                <li class="d-flex" v-for="(activity, index) in activities" :key="index">
                    <div class="activity-list__wrapper">
                        <div class="activity-list__author">
                            <Avatar v-if="activity.user_name === 'SwitchPitch'"
                                    background-color="#043648"
                                    color="#00AFEF"
                                    initials="SP"
                                    :size="42"
                            />

                            <Avatar v-else
                                    :username="activity.user_name"
                                    :src="activity.user_avatar"
                                    :size="42"
                            />

                            <div>
                                <h5 class="heading5 heading5--secondary" :class="{'deleted': activity.user_deleted}">
                                    <template v-if="isPreview">
                                        {{ getShortFullName(activity.user_name) }}
                                    </template>

                                    <template v-else>
                                        {{ activity.user_name }}
                                    </template>
                                    <template v-if="activity.user_deleted">(D)</template>
                                </h5>

                                <time class="activity-list__time">{{ activity.date }}</time>
                            </div>
                        </div>

                        <ActivityContent
                            :activity="activity"
                            :isPreview="isPreview"
                            :index="index"
                        />
                    </div>

                    <div class="dropdown-original-wrapper">

                    <template v-if="activity.can_edit">
                        <b-dropdown
                            right
                            class="dropdown-single icons-list dropdown-original dropdown-original--small"
                        >
                            <b-dropdown-item
                                class="icons-list__edit"
                                @click="editActivity(activity)"
                            >
                                Edit
                            </b-dropdown-item>

                                <b-dropdown-item
                                    class="icons-list__delete"
                                    @click="removeActivity(activity)"
                                >
                                    Remove
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </div>
                </li>
            </ul>

            <div v-else class="activity-list-empty">
                <div class="main-startup__tabs__item__inner">
                    <h5 class="heading5 heading5--secondary">No Interactions… Yet.</h5>

                    <ul class="activity-icons-list">
                        <li class="activity-icons-list__call"></li>
                        <li class="activity-icons-list__note"></li>
                        <li class="activity-icons-list__email"></li>
                        <li class="activity-icons-list__demo"></li>
                        <li class="activity-icons-list__meeting"></li>
                    </ul>

                    <p>Calls, notes, emails, demos and meetings logged will display here, along with profile views by users in your company.</p>
                </div>
            </div>
        </template>

        <InteractionEditModal
            ref="interactionEditModal"
            @saved="emit('activitiesChanged')"
            @closed="emit('editModalClosed')"
        />
    </div>
</template>

<style scoped>

</style>
