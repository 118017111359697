import dashboardService from "@/services/dashboard.service";

const defaultOptions = {
    exporting: {
        buttons: {
            contextButton: {
                symbol: 'url(icon-actions-menu.svg)',
                symbolSize: 20,
                symbolX: 22,
                symbolY: 22,
            }
        }
    }
}

const dashboardChartMixin = {
    props: {
        chartSettings: {
            type: Object,
            required: true,
        },

        filters: Object,
    },

    mounted() {
        this.fetchChartData(true)
    },

    watch: {
        chartSettings: {
            handler() {
                this.reflowChart()
            },

            deep: true
        },

        filters() {
            this.fetchChartData()
        },
    },

    methods: {
        reflowChart() {
            if (this.$refs.chart) {
                this.$refs.chart.chart.reflow()
            }
        },

        fetchChartData() {
            console.log("That's method from mixin. Please implement one regarding your component needs.")
        },

        getChartData(chartType) {
            return dashboardService.getChartData(chartType, this.filters)
        },

        getOptions(options) {
            return { ...defaultOptions, ...options };
        }
    },
}

export default dashboardChartMixin
