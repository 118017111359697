<script setup>
import {computed, inject, reactive, ref, watch} from "vue";
import {toLocale} from "@/helpers/string";
import AnimatedNumber from 'animated-number-vue';
import dashboardService from "@/services/dashboard.service";
import AddStartup from "@/components/startups/AddStartup.vue";

const filters = inject('filters');
const loading = ref(false);
const addStartupModal = ref(null);

const counters = {
    addedStartupsCount: "Startups added by team",
    startupsWithActivityCount: "Startups with activity",
    startupsAssociatedWithTrackersCount: "Startups on trackers",
    addedTrackersCount: "Trackers created by team",
    trackersWithActivityCount: "Trackers with activity",
    trackersWithNoStartupsCount: "Trackers with no startups",
}

const counts = reactive({
    addedStartupsCount: 0,
    startupsWithActivityCount: 0,
    startupsAssociatedWithTrackersCount: 0,
    addedTrackersCount: 0,
    trackersWithActivityCount: 0,
    trackersWithNoStartupsCount: 0,
    allTrackersCount: 0,
});

const isEmpty = computed(() => Object.values(counts).every(count => count === 0));

watch(filters, () => {
    update();
}, {immediate: true});

async function update() {
    loading.value = true;

    await Promise.all([
        fetchAddedStartupsCount(),
        fetchStartupsWithActivityCount(),
        fetchStartupsAssociatedWithTrackersCount(),
        fetchAddedTrackersCount(),
        fetchTrackersWithActivityCount(),
        fetchTrackersWithNoStartupsCount(),
    ]);

    loading.value = false;
}

async function fetchAddedStartupsCount() {
    const {data} = await dashboardService.getWidgetData('AddedStartupsCount', filters.value);
    counts.addedStartupsCount = data;
}

async function fetchStartupsWithActivityCount() {
    const {data} = await dashboardService.getWidgetData('StartupsWithActivityCount', filters.value);
    counts.startupsWithActivityCount = data;
}

async function fetchStartupsAssociatedWithTrackersCount() {
    const {data} = await dashboardService.getWidgetData('StartupsAssociatedWithTrackersCount', filters.value);
    counts.startupsAssociatedWithTrackersCount = data;
}

async function fetchAddedTrackersCount() {
    const {data} = await dashboardService.getWidgetData('TrackersCount', filters.value);
    counts.addedTrackersCount = data.added;
    counts.allTrackersCount = data.all;
}

async function fetchTrackersWithActivityCount() {
    const {data} = await dashboardService.getWidgetData('TrackersWithActivityCount', filters.value);
    counts.trackersWithActivityCount = data;
}

async function fetchTrackersWithNoStartupsCount() {
    const {data} = await dashboardService.getWidgetData('TrackersWithNoStartupsCount', filters.value);
    counts.trackersWithNoStartupsCount = data;
}
</script>

<template>
    <section class="dashboard--startups">
        <div class="card card--bordered">
            <header class="dashboard__header">
                <h3 class="heading3">Your Tracked Startups</h3>
            </header>

            <div v-if="loading">Loading...</div>

            <template v-else>
                <div v-if="isEmpty"  class="text-center" style="width: 100%">
                    <h4 class="heading4 mb-4">Keep Tabs On Your Startups</h4>
                    <p>Add Startups to perform diligence and add them to your trackers</p>
                    <b-button @click="$refs.addStartupModal.open()" class="btn-primary btn-lg">Add A Startup</b-button>
                </div>

                <ul v-else class="dashboard__stats">
                    <li v-for="(counter, key) in counters" :key="key" class="startups-counter">
                        <div class="dashboard__stats__counter startups-counter-value"> <!-- delete value class -->
                            <AnimatedNumber
                                :value="counts[key]"
                                :formatValue="toLocale"
                                :duration="500"
                                :round="true"
                            />

                            <span v-if="key === 'addedTrackersCount'" class="dashboard__stats__total">(Total {{counts.allTrackersCount}})</span>
                        </div>

                        <div class="counter__value">{{ counter }}</div>
                    </li>
                </ul>
            </template>

            <AddStartup
                ref="addStartupModal"
                @startupsAdded="update"
            />
        </div>
    </section>
</template>

<style scoped>
</style>
