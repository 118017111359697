import { render, staticRenderFns } from "./UsersActivities.vue?vue&type=template&id=0830e772&scoped=true&"
import script from "./UsersActivities.vue?vue&type=script&setup=true&lang=js&"
export * from "./UsersActivities.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./UsersActivities.vue?vue&type=style&index=0&id=0830e772&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0830e772",
  null
  
)

export default component.exports