<script setup>

import {computed, inject, reactive, ref, watch} from "vue";
import {interactionTypes, interactionTypesRef} from "@/constants/activitiesConstants";
import DatePicker from "vue2-datepicker";
import {Mentionable} from "vue-mention";
import {useStore} from "vue2-helpers/vuex";
import {decodeDateFromServer, encodeDateForServer} from "@/helpers/date";
import {Avatar} from "vue-avatar";
import PrimaryButton from "@/components/ui/PrimaryButton.vue";
import interactionsService from "@/services/interactions.service";
import interactionTagsService from "@/services/interaction-tags.service";
import multiselect from "vue-multiselect";

const store = useStore();
const id = ref(0);
const showModal = ref(false);
const canSelectType = ref(true);
const date = ref(null);
const loading = ref(false);
const tagsList = ref([]);
const addedTag = ref(null);

const saveInteraction = inject('saveInteraction');
const saveNote = inject('saveNote');
const getNoteEdit = inject('getNoteEdit');
const canAddInteractionTags = inject('canAddInteractionTags', false);
const emit = defineEmits(['saved','closed']);

const interaction = reactive({
    type: "",
    comment: "",
    date: "",
    tags: [],
});

const title = computed(() => id.value ? 'Edit Interaction' : 'Add Interaction');
const canSave = computed(() => interaction.type !== 'note' || interaction.comment.length);
const addedTags = computed(() => tagsList.value.filter(tag => interaction.tags.some(tagId => parseInt(tagId) === parseInt(tag.id))));
const actualTagsList = computed(() => tagsList.value.filter(tag => !interaction.tags.some(tagId => parseInt(tagId) === parseInt(tag.id))));

watch(date , (value) => {
    interaction.date = encodeDateForServer(value);
});

watch(showModal , (value) => {
    if (!value) {
        emit('closed');
    }
});

watch(() => interaction.type, async (value) => {
    if (store.getters.isEnterprise) {
        const {data} = await interactionTagsService.getListForInteraction(interactionTypesRef[value]);
        tagsList.value = data;
    }
});

watch(addedTag, (value) => {
    if (value) {
        interaction.tags.push(value.id);
        addedTag.value = null;
    }
});

async function open() {
    showModal.value = true;
    store.dispatch('fetchUsersMentionList');

    if (id.value) {
        canSelectType.value = false;

        if (interaction.type === 'note') {
            const {data} = await getNoteEdit(id.value);
            interaction.comment = data.text;
        } else {
            const {data} = await interactionsService.getEdit(id.value);

            Object.keys(interaction).forEach(key => {
                interaction[key] = data[key];
            });

            if (interaction.type === 'phone_call') {
                interaction.type = 'call';
            }

            date.value = decodeDateFromServer(data.date);
        }
    } else {
        interaction.comment = '';
        interaction.date = '';
        interaction.tags = [];
    }
}

async function save() {
    loading.value = true;

    try {
        if (interaction.type === 'note') {
            await saveNote(id.value, {
                text: interaction.comment
            });
        } else {
            await saveInteraction(id.value, {
                ...interaction,
                type: interactionTypesRef[interaction.type],
            });
        }

        emit('saved');
        showModal.value = false;
    } finally {
        loading.value = false;
    }
}

function openEditInteraction(interactionId) {
    id.value = interactionId;
    interaction.type = '';
    open();
}

function openEditNote(noteId) {
    id.value = noteId;
    interaction.type = 'note';
    open();
}

function openAdd(type = null) {
    if (type) {
        interaction.type = type;
    } else {
        interaction.type = 'note';
    }

    open();
}

function removeTag(id) {
    interaction.tags = interaction.tags.filter(tagId => tagId !== id);
}

defineExpose({openAdd, openEditInteraction, openEditNote});
</script>

<template>
    <b-modal v-model="showModal" :title="title" modal-class="modal-interaction">
        <div v-if="!id">
            <p class="mb-2">Add an interaction:</p>

            <div class="list-interaction">
                <a
                    v-for="(name, value) in interactionTypes" :key="value"
                    :class="[`list-interaction__${value}`, {active: interaction.type === value}]"
                    @click="interaction.type = value"
                >
                    {{name}}
                </a>
            </div>
        </div>

        <DatePicker
            v-if="interaction.type !== 'note'"
            v-model="date"
            class="modal-interaction__datepicker"
            format = "MM/DD/YYYY"
        />

        <Mentionable
            :keys="['@']"
            :items="store.getters.usersMentionList"
            offset="6"
            insert-space
            placement="top-start"
        >
            <template #item-@="{ item }">
                <div class="user">
                    <Avatar
                        :username="item.name"
                        :src="item.avatar"
                        :size="25"
                    >
                    </Avatar>

                    <div class="mention-item__inner">
                        {{ item.value }}
                        <span class="dim">
                            ({{ item.name }})
                        </span>
                    </div>
                </div>
            </template>

            <textarea v-model="interaction.comment" class="form-control--mid form-control"></textarea>
        </Mentionable>

        <div v-if="canAddInteractionTags && tagsList.length && interaction.type !== 'note'" class="">
            <p class="mr-2">Interaction Tags:</p>

            <multiselect
                v-model="addedTag"
                :options="actualTagsList"
                placeholder="Add Tag"
                label="name"
                track-by="id"
                class="multiselect-mid mb-3"
            />

            <div v-if="addedTags.length" class="tags-light-list">
                <!--Tagged:-->
                <span v-for="tag in addedTags" :key="tag.id" class="tags-light-list__tag" :title="tag.name">
                    {{ tag.name }}
                    <a><span @click="removeTag(tag.id)" class="tags-light-list__tag__close"></span></a>
                </span>
            </div>
        </div>

        <template #modal-footer>
            <PrimaryButton
                caption="Save Interaction"
                :loading="loading"
                :disabled="!canSave"
                @click="save"
                class="btn-mid"
            />
        </template>
    </b-modal>
</template>

<style scoped>

</style>
