<template>
    <b-tab>
        <template #title>
            Challenges
            <small v-if="loading">Loading...</small>
        </template>
        
        <div class="tabs-primary__inner">
            <span v-if="loading">Loading...</span>
            
            <!-- NO DATA STATE -->
            <div v-if="!loading && !challenges.length" class="not-found">
                <h2 class="heading2 heading2--no-challenges">This enterprise hasn't posted any opportunities.</h2>
            </div>
            <!-- NO DATA STATE END -->

            <div v-for="challenge in challenges" :key="challenge.id" class="card card--bordered card--row">
                <div class="image-block image-block--lg">
                    <img :src="LogoHelper.getLogo(challenge.company_logo)" alt="img">
                </div>

                <div class="card--row__content">
                    <h4 class="heading4 heading4--secondary">{{challenge.name}}</h4>

                    <p v-html="challenge.description" class="line-clamp line-clamp3"></p>
                </div>

                <template v-if="challenge.applied">
                    <button class="btn btn-secondary btn-lg">Applied</button>
                </template>
                <router-link v-else :to="'/public-trackers/' + challenge.id" class="btn btn-primary btn-lg">Apply To Challenge</router-link>

            </div>
        </div>
    </b-tab>
</template>

<script>

export default {
    props: {
        challenges: Array,
        loading: Boolean
    },
}
</script>

<style scoped>
    .tabs-primary__inner {
        /*margin-top: -1rem !important;*/
    }
</style>