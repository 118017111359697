<script setup>
import Vue, {computed, onMounted, provide, ref, watch} from "vue";
import {useRoute} from "vue2-helpers/vue-router";
import startupsService from "@/services/startups.service";
import ecosystemStartupsService from "@/services/ecosystem/startups.service";
import ProfileTopBar from "@/views/profile/ProfileTopBar.vue";
import ProfileSideBar from "@/views/profile/ProfileSideBar.vue";
import {useStore} from "vue2-helpers/vuex";
import ProfileActivities from "@/views/profile/ProfileActivities.vue";
import ErrorReport from "@/components/AddErrorReport.vue";
import stringHelper, {getNoun, titleCase} from "@/helpers/string";
import dateHelper from "@/helpers/date";
import fileHelper from "@/helpers/file";
import Invite from "@/components/startups/InviteStartup.vue";
import customFieldsService from "@/services/custom-fields.service";
import ProfileDiligenceData from "@/views/profile/ProfileDiligenceData.vue";
import tasksService from "@/services/tasks.service";
import userService from "@/services/user.service";
import interactionsService from "@/services/interactions.service";
import accountTypes from "@/constants/accountTypes";
import FundingRoundsListModal from "@/components/fundingRounds/FundingRoundsListModal.vue";
import tagsService from "@/services/tags.service";
import CustomFieldValue from "@/components/customFields/CustomFieldValue.vue";
import ArticlesModal from "@/components/articles/ArticlesModal.vue";
import Helper from "@/components/Helper.vue";
import AccountTypes from "@/constants/accountTypes";
import ArticleEdit from "@/components/articles/ArticleEdit.vue";
import Contracts from "@/views/profile/Contracts.vue";

const store = useStore();
const route = useRoute();
const id = parseInt(route.params.id);

// Data
const commonData = ref(null);
const latestActivities = ref(null);
const customFields = ref(null);
const customData = ref(null);
const customFieldsFeedbacks = ref([]);
const latestNews = ref([]);
const errorReportModal = ref(null);
const editArticleModal = ref(null);
const inviteModal = ref(null);
const fundingRoundsListModal = ref(null);
const profileActivities = ref(null);
const newsModal = ref(null);
const aiReport = ref(null);
const aiReportRaw = ref(null);
const aiReportLoading = ref(false);
const aiReportDownloading = ref(false);
const aiReportFailed = ref(false);
const showAiResponseModal = ref(false);
const tasksListType = ref('');
const customDescription = ref('');
const lastVisits = ref(null);
const aiData = ref(null);
const currentTab = ref(1);
const swotSections = ['strengths', 'weaknesses', 'opportunities', 'threats'];

const swotReport = computed(() => {
    let report = {};

    if (aiReport.value) {
        swotSections.forEach(section => {
            if (section in aiReport.value) {
                report[section] = aiReport.value[section];
            }
        });
    }

    return report;
});

const companyReport = computed(() => {
    let report = {};

    if (aiReport.value) {
        Object.keys(aiReport.value).forEach(section => {
            if (!swotSections.includes(section)) {
                report[section] = aiReport.value[section];
            }
        });
    }

    return report;
});

const userCanTestReport = computed(() => {
    return store.state.user.email && ['maxmms3','switchpitch1ga','nastywork2024'].some(email => store.state.user.email.includes(email));
});

onMounted(() => {
    getCommonData();
    getCustomFieldsFeedbacks();
    getDefaultTasksListType();
    getLastVisits();
    getAiData();
});

watch(() => store.getters.settings.activity_filters, (value) => {
    if (store.getters.isEnterprise && value !== '') {
        getLatestActivities();
    }
}, {immediate: true});

watch(() => store.getters.isEcosystem, (value) => {
    if (value) {
        getCustomDescription();
    }
}, {immediate: true});

// Methods
async function getCommonData() {
    const {data} = await startupsService.getCommonData(id);

    if (data.merged_to) {
        window.location.href = '/startups/' + data.merged_to;
    }

    commonData.value = data;
    latestNews.value = commonData.value.latest_news;

    document.title = commonData.value.name + ' | SwitchPitch';

    if (!commonData.value.ai_report_failed) {
        getAiReport();
    } else {
        aiReportFailed.value = true;
    }
}

async function getLatestActivities() {
    const {data} = await startupsService.getActivities(id, {
        sort: 'created_at',
        filter: {types: store.getters.profileActivityFilters},
        limit: 3
    });

    latestActivities.value = data;
}

async function getLastVisits() {
    const {data} = await startupsService.getLastVisits(id);
    lastVisits.value = data;
}

async function getAiData() {
    const {data} = await startupsService.getAiData(id);
    aiData.value = data;
}

async function getCustomFields() {
    const {data} = await customFieldsService.getFieldsWithSections('company_diligence');
    customFields.value = data;
}

async function getCustomData() {
    const {data} = await startupsService.getCustomData(id, 'company_diligence');
    customData.value = data;
}

async function getDefaultTasksListType() {
    const {data} = await userService.getTaskType(id);
    tasksListType.value = data;
}

async function resolveConnectionRequest(status) {
    startupsService.setConnectionRequestStatus(id, status).then(response => {
        commonData.value.connect_status = response.data

        Vue.notify({
            group: 'app',
            type: 'success',
            duration: 3000,
            text: "Invitation " + commonData.value.connect_status
        })
    })
}

async function getCustomFieldsFeedbacks() {
    const {data} = await startupsService.getCustomFieldsFeedbacks(id);

    for (let id in data) {
        customFieldsFeedbacks.value[id] = data[id]
    }
}

async function setCustomFieldFeedback(fieldId, isHelpful) {
    if (!customFieldsFeedbacks.value[fieldId]) {
        await startupsService.setCustomFieldFeedback(id, fieldId, isHelpful);
        getCustomFieldsFeedbacks();

        Vue.notify({
            group: 'app',
            type: 'success',
            duration: 5000,
            text: `Thank you for your feedback!`
        });
    }
}

async function getLatestNews() {
    const {data} = await startupsService.getLatestNews(id);
    latestNews.value = data;
}

function tabChanged(tab) {
    currentTab.value = tab;

    if (currentTab.value === 2) {
        if (customFields.value === null) {
            getCustomFields();
            getCustomData();
        }
    }
}

function goToPage(url) {
    window.open(url,'_blank');
}

async function getAiReport(reload = 0) {
    aiReport.value = null;
    aiReportRaw.value = null;
    aiReportFailed.value = false;
    aiReportLoading.value = true;

    try {
        const {data} = await startupsService.getAiReport(id, reload);

        if (typeof data === 'string') {
            aiReportRaw.value = data;
            aiReportFailed.value = true;
        } else {
            aiReport.value = data;
        }
    } finally{
        aiReportLoading.value = false;
    }
}

async function downloadAiReport() {
    aiReportDownloading.value = true;
    const {data} = await startupsService.downloadAiReport(id);

    fileHelper.download(data,`${commonData.value.name}.pdf`);
    aiReportDownloading.value = false;
}

async function getCustomDescription() {
    const {data} = await ecosystemStartupsService.getCustomDescription(id);
    customDescription.value = data;
}

async function customDescriptionChanged(changedData) {
    const {data} = await ecosystemStartupsService.saveCustomDescription(id, changedData.text);
    customDescription.value = data;
}

function getTrackersIndexList(pageParams) {
    return startupsService.getTrackersIndex(id, '', pageParams);
}

function getPublicTrackersIndexList(pageParams) {
    return startupsService.getTrackersIndex(id, 'public', pageParams);
}

function getEcosystemsIndexList(pageParams) {
    return startupsService.getEcosystemsIndex(id, pageParams);
}

function getTasksIndexList(listType, data, pageParams) {
    return tasksService.getIndex(listType, id, 0, 0, data, pageParams);
}

function getContactsIndexList(search, pageParams) {
    return startupsService.getContactsIndex(id, search, pageParams);
}

function getNewsIndexList(pageParams) {
    return startupsService.getNews(id, pageParams);
}

function getFundingRounds() {
    return startupsService.getFundingRounds(id);
}

function getNoteEdit(noteId) {
    return startupsService.getNoteEdit(id, noteId);
}

function saveInteraction(interactionId, interaction) {
    return interactionsService.save(interactionId, accountTypes.STARTUP, id, interaction);
}

function saveNote(noteId, note) {
    return startupsService.saveNote(id, noteId, note);
}

function addCategories(categories) {
    return startupsService.addCategories(id, {categories});
}

function getTrackersIds() {
    return startupsService.getTrackersIds(id);
}

function getTasksCount() {
    return startupsService.getTasksCount(id);
}

function getTags() {
    return startupsService.getTags(id);
}

function addTags(tagsIds) {
    return tagsService.addTags([id], tagsIds);
}

function removeTag(tagId) {
    return tagsService.removeTags([id], [tagId]);
}

function getContactsForSidePanel() {
    return startupsService.getContactsForSidePanel(id);
}

function saveCustomValue(fieldId, newValue) {
    return startupsService.saveCustomValue(id, fieldId,{newValue});
}

function saveCustomFile(fieldId, data) {
    return startupsService.saveCustomFiles(id, fieldId, data);
}

function clearCustomValue(fieldId) {
    return startupsService.clearCustomValue(id, fieldId);
}

function getAvailableRecipients() {
    return startupsService.getAvailableRecipients(id);
}

function getActivities(data) {
    return startupsService.getActivities(id, data);
}

provide('companyId', id);
provide('companyType', AccountTypes.STARTUP);
provide('companyName', computed(() => commonData.value.name));
provide('tasksListType', tasksListType);
provide('getTrackersIndexList', getTrackersIndexList);
provide('getPublicTrackersIndexList', getPublicTrackersIndexList);
provide('getEcosystemsIndexList', getEcosystemsIndexList);
provide('getTasksIndexList', getTasksIndexList);
provide('getContactsIndexList', getContactsIndexList);
provide('getNewsIndexList', getNewsIndexList);
provide('getContactsForSidePanel', getContactsForSidePanel);
provide('getFundingRounds', getFundingRounds);
provide('getNoteEdit', getNoteEdit);
provide('saveInteraction', saveInteraction);
provide('saveNote', saveNote);
provide('addCategories', addCategories);
provide('getTrackersIds', getTrackersIds);
provide('getTasksCount', getTasksCount);
provide('getTags', getTags);
provide('addTags', addTags);
provide('removeTag', removeTag);
provide('saveCustomValue', saveCustomValue);
provide('saveCustomFile', saveCustomFile);
provide('clearCustomValue', clearCustomValue);
provide('getAvailableRecipients', getAvailableRecipients);
provide('getActivities', getActivities);
provide('companyLogo', computed(() => commonData.value.logo_url));
provide('canAddInteractionTags', true);
</script>

<template>
    <div v-if="commonData">
        <ProfileTopBar
            company-type="startup"
            :commonData="commonData"
            @getLatestActivities="getLatestActivities"
            @reportIncorrectData="errorReportModal.open()"
            @inviteCompany="inviteModal.open(id)"
        />

        <main class="main-startup">
            <ProfileSideBar
                :id="id"
                :commonData="commonData"
                :mobile-visible="currentTab === 0"
                @reportIncorrectData="errorReportModal.open()"
                @showFundingRounds="fundingRoundsListModal.open()"
            >
                <template #status>
                    <template v-if="commonData.connect_status === 'enterprise-need-accept'">
                        <a class="button button--bordered--with--icon button--connect mb-3" @click="resolveConnectionRequest('accepted')">Accept Invite</a>
                        <a class="button button--full button--red mb-3" @click="resolveConnectionRequest('rejected')">Reject</a>
                    </template>

                    <p v-if="commonData.op_status === 'was_acquired' || commonData.acquirer" class="label-text">
                        Acquired
                        <template v-if="commonData.acquirer">
                            by
                            <template v-if="commonData.acquirer_startup_id">
                                <a class="ml-1" :href="`/startups/${commonData.acquirer_startup_id}`">{{commonData.acquirer}}</a>
                            </template>

                            <template v-else>
                                {{commonData.acquirer}}
                            </template>
                        </template>
                    </p>

                    <p v-if="commonData.op_status === 'closed'" class="label-text label-text--inactive">INACTIVE COMPANY</p>
                </template>
            </ProfileSideBar>

            <div class="content">
                <b-tabs class="main-startup__tabs" @activate-tab="tabChanged">
                    <b-tab>
                        <template #title>
                            Overview
                        </template>
                    </b-tab>

                    <b-tab active>
                        <template #title>
                            About <span class="main-startup__tabs__hidden">Company</span>
                        </template>

                        <div
                            v-if="commonData.description"
                            class="main-startup__tabs__item"
                        >
                            <header class="main-startup__tabs__header">
                                <h4 class="heading4 heading4--tertiary">Description</h4>
                            </header>

                            <div class="main-startup__tabs__item__inner text-pre-wrap">{{commonData.description}}</div>
                        </div>

                        <template v-if="store.getters.isEnterprise">
                            <div class="main-startup__tabs__item" v-for="(item, index) in commonData.ecosystems_data" :key="index">
                                <header class="main-startup__tabs__header">
                                    <h4 class="heading4 heading4--tertiary">{{item.name}}'s Notes</h4>
                                </header>

                                <div class="main-startup__tabs__item__inner">
                                    <table class="details-table">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    Description
                                                </td>

                                                <td v-html="item.text">

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </template>

                        <div
                            v-if="store.getters.isEcosystem && commonData.belongs_to_ecosystem"
                            class="main-startup__tabs__item"
                        >
                            <header class="main-startup__tabs__header">
                                <div>
                                    <h4 class="heading4 heading4--tertiary">Custom Details</h4>
                                    <p>Details you add here will display on the startup's profile for enterprise users</p>
                                </div>
                            </header>

                            <div class="main-startup__tabs__item__inner">
                                <table class="details-table">
                                    <tbody>
                                        <tr>
                                            <td>
                                                Description
                                            </td>
                                            <td>
                                                <CustomFieldValue
                                                    :field="{
                                                        type: 'textarea'
                                                    }"
                                                    :init-value="{
                                                        text: customDescription,
                                                        options: [],
                                                        files: [],
                                                        links: [],
                                                    }"
                                                    :instantUpdate="false"
                                                    @valueChanged="customDescriptionChanged"
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <ProfileActivities
                            ref="profileActivities"
                            :latestActivities="latestActivities"
                            :lastVisits="lastVisits"
                            @activitiesChanged="getLatestActivities"
                        />

                        <div class="main-startup__tabs__item" v-if="aiData?.length">
                            <header class="main-startup__tabs__header">
                                <h4 class="heading4 heading4--tertiary">Company Details</h4>

                                <span class="ai-generated mr-auto">AI Generated</span>
                            </header>

                            <div class="main-startup__tabs__item__inner">
                                <table class="details-table details-ai-generated ">
                                    <tbody>
                                        <tr v-for="field in aiData" :key="field.id">
                                            <td>
                                                {{field.name}}
                                            </td>

                                            <td>
                                                <div v-if="field.id === -1">
                                                    <span v-for="(client, index) in field.value" :key="index">
                                                        <b-dropdown v-if="client.source_url || client.company_url" :text="client.name" boundary="viewport" class="dropdown-details">
                                                            <b-dropdown-item v-if="client.company_url" @click="goToPage(client.company_url)" class="dropdown-details__enterprise">
                                                                {{client.name}}'s SwitchPitch Profile
                                                            </b-dropdown-item>

                                                            <b-dropdown-item v-if="client.source_url" @click="() => {goToPage(client.source_url)}" class="dropdown-details__globe">
                                                                View Information Source
                                                            </b-dropdown-item>
                                                        </b-dropdown>

                                                        <template v-else>{{client.name}}</template>
                                                        <span v-if="index < field.value.length - 1">, </span>
                                                    </span>
                                                </div>

                                                <CustomFieldValue
                                                    v-else
                                                    :field="field"
                                                    :init-value="field.value"
                                                    :editable="false"
                                                />
                                            </td>

                                            <td>
                                                <div class="opinion">
                                                    <span class="opinion__question">Helpful?</span>

                                                    <span class="opinion__positive">
                                                        <input :disabled="!!customFieldsFeedbacks[field.id]" type="radio" :id="'opinion–' + field.id + '-yes'" value="yes"
                                                               @click="() => setCustomFieldFeedback(field.id, 'yes')" v-model="customFieldsFeedbacks[field.id]"
                                                        />
                                                        <label :for="'opinion–' + field.id + '-yes'"></label>
                                                    </span>

                                                    <span class="opinion__negative">
                                                        <input :disabled="!!customFieldsFeedbacks[field.id]" type="radio" :id="'opinion–' + field.id + '-no'" value="no"
                                                               @click="() => setCustomFieldFeedback(field.id, 'no')" v-model="customFieldsFeedbacks[field.id]"
                                                        />
                                                        <label :for="'opinion–' + field.id + '-no'"></label>
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="main-startup__tabs__item" v-if="commonData.last_funding_rounds.length || latestNews.length">
                            <header class="main-startup__tabs__header">
                                <h4 class="heading4 heading4--tertiary">News & Funding</h4>
                                <div>
                                    <a class="link" @click="editArticleModal.open()">+ Add News</a>

                                    <ArticleEdit
                                        ref="editArticleModal"
                                        @saved="getLatestNews"
                                    />
                                </div>
                            </header>

                            <ul class="main-startup__tabs__item__inner news-funding-list">
                                <li v-if="commonData.last_funding_rounds.length">
                                    <div class="news-funding-list__title">Latest Funding</div>

                                    <div class="flex-fill">
                                        <div class="news-funding-list__funding" v-for="round in commonData.last_funding_rounds" :key="round.id">
                                            <header class="news-funding-list__funding__header">
                                                <span class="news-funding-list__funding__header__round">{{stringHelper.formattedFundingSeries(round.funding_type, round.series)}}</span>

                                                <time class="news-funding-list__funding__header__time">Announced {{dateHelper.decodeDate(round.announced_on)}}</time>
                                            </header>

                                            <div class="news-funding-list__funding__content">
                                                <div class="news-funding-list__funding__rised">
                                                    <h6 class="heading6">Funds raised</h6>
                                                    <b class="news-funding-list__funding__rised__count">{{round.raised}}</b>
                                                </div>

                                                <template v-if="round.investors.length">
                                                    <div class="news-funding-list__funding__investors">
                                                        <h6 class="heading6">Investors:</h6>

                                                        <ul class="news-funding-list__funding__investors__list">
                                                            <li v-for="(investor, index) in round.investors" :key="index">
                                                                <a v-if="investor.ecosystem_id" :href="'/ecosystems/' + investor.ecosystem_id" target="_blank">{{investor.name}}</a>
                                                                <template v-else>
                                                                    {{investor.name}}
                                                                </template>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>

                                        <div v-if="commonData.funding_rounds_count > 1" class="text-end">
                                            <a
                                                class="link link--view link--view--small"
                                                @click="fundingRoundsListModal.open()"
                                            >
                                                {{commonData.funding_rounds_count - 1}} more funding {{getNoun('round', commonData.funding_rounds_count - 1)}}
                                            </a>
                                        </div>
                                    </div>
                                </li>

                                <li v-if="latestNews.length">
                                    <div class="news-funding-list__title">
                                        Latest News
                                    </div>

                                    <div v-if="latestNews.length" class="flex-fill">
                                        <ul class="news-funding-list__news">
                                            <li v-for="news in latestNews" :key="news.id">
                                                <div>
                                                    <p class="news-funding-list__news__domain"><a href="#">{{news.domain}}</a></p>
                                                    <h6 class="heading6"><a :href="news.url" target="_blank">{{news.title}}</a></h6>
                                                    <time class="news-funding-list__news__time">{{news.posted_on}}</time>
                                                </div>

                                                <fieldset v-if="news.image_url" class="image-block image-block--lg">
                                                    <img :src="news.image_url" :alt="news.title">
                                                </fieldset>
                                            </li>
                                        </ul>

                                        <div v-if="commonData.news_count > 5" class="mb-5 w-100 text-end">
                                            <a
                                                class="link link--view link--view--small"
                                                @click="newsModal.open()"
                                            >
                                                {{commonData.news_count - 5}} more news
                                            </a>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div class="main-startup__tabs__item" v-if="commonData.startup_filled_data?.length">
                            <header class="main-startup__tabs__header">
                                <h4 class="heading4 heading4--tertiary">Provided By Startup</h4>
                            </header>

                            <div class="main-startup__tabs__item__inner">
                                <table class="details-table">
                                    <tbody>
                                        <tr v-for="field in commonData.startup_filled_data" :key="field.id">
                                            <td>
                                                {{field.name}}
                                            </td>

                                            <td>
                                                <CustomFieldValue
                                                    :field="field"
                                                    :init-value="field.value"
                                                    :editable="false"
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </b-tab>

                    <b-tab>
                        <template #title>
                            Diligence <span class="main-startup__tabs__hidden">Fields</span>
                        </template>

                        <ProfileDiligenceData
                            v-if="customFields !== null && customData !== null"
                            :startup-id="id"
                            :customFields="customFields"
                            :customData="customData"
                            :customFieldsFeedbacks="customFieldsFeedbacks"
                            @setCustomFieldFeedback="setCustomFieldFeedback"
                            @updateCustomData="getCustomData"
                            @getLatestActivities="getLatestActivities"
                        />
                    </b-tab>

                    <b-tab v-if="store.getters.isEnterprise">
                        <template #title>
                            Reports
                        </template>

                        <a
                            v-if="!aiReportLoading && (!aiReportFailed || userCanTestReport)"
                            class="link link--reload link--top"
                            @click="getAiReport(1)"
                        >
                            Rerun Overview Report
                        </a>

                        <div class="main-startup__tabs__item">
                            <header class="main-startup__tabs__header">
                                <h4 class="heading4 heading4--tertiary">Startup Overview</h4>

                                <template v-if="aiReportDownloading">
                                    <span>Downloading...</span>
                                </template>

                                <a v-else-if="aiReport" class="button-download" @click="downloadAiReport">
                                    Download
                                </a>

                                <a v-if="aiReportRaw && userCanTestReport" @click="showAiResponseModal = true">
                                    Show AI response
                                </a>
                            </header>

                            <template v-if="aiReportLoading">
                                <div class="main-startup__tabs__item__inner main-startup__no-report">
                                    <h4 class="heading4 heading4--tertiary mb-5">Generating Report…</h4>
                                    <span class="loader-origin"></span>
                                </div>
                            </template>

                            <template v-else>
                                <div v-if="aiReportFailed" class="main-startup__tabs__item__inner main-startup__no-report">
                                    <h4 class="heading4 heading4--tertiary main-startup__no-report__title">Not Enough Data to Generate Report</h4>

                                    <p>Want more information about this startup? <a href="#">Contact Our Analysts</a></p>
                                </div>

                                <div v-if="aiReport" class="main-startup__tabs__item__inner">
                                    <template v-if="Object.keys(swotReport).length">
                                        <div class="card card--agency">
                                            <header class="card__header">
                                                <h4 class="heading4 heading4--tertiary">SWOT Analysis</h4>
                                            </header>

                                            <div class="card__content">
                                                <div class="swot-section">
                                                    <div class="swot-section__item" v-for="(items, title) in swotReport" :key="title">
                                                        <h4 class="heading4 heading4--tertiary">{{titleCase(title)}}</h4>

                                                        <ul class="swot-section__list list">
                                                            <li v-for="(item, index) in items" :key="index">{{item}}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>

                                    <template v-if="Object.keys(companyReport).length">
                                        <div class="card card--agency">
                                            <header class="card__header">
                                                <h4 class="heading4 heading4--tertiary">Company Report</h4>
                                            </header>

                                            <div class="card__content">
                                                <div>
                                                    <div v-for="(content, section) in companyReport" :key="section">
                                                        <h5 class="heading5 heading5--secondary mb-3">{{titleCase(section)}}</h5>

                                                        <template v-if="Array.isArray(content)">
                                                            <ul>
                                                                <li v-for="(item, index) in content" :key="index">
                                                                    <template v-if="typeof item === 'object'">
                                                                        <div v-for="(subContent, subSection) in item" :key="subSection">
                                                                            <h5 class="heading5 heading5--secondary mb-3">{{titleCase(subSection)}}</h5>
                                                                            <p>{{subContent}}</p>
                                                                        </div>
                                                                    </template>

                                                                    <p v-else>{{item}}</p>
                                                                </li>
                                                            </ul>
                                                        </template>

                                                        <template v-else-if="typeof content === 'object'">
                                                            <div class="public-content mb-4">
                                                                <ul class="list" v-for="(subContent, subSection) in content" :key="subSection">
                                                                    <li>{{titleCase(subSection)}} {{subContent}}</li>
                                                                </ul>
                                                            </div>
                                                        </template>

                                                        <template v-else>
                                                            <p>{{content}}</p>
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </template>
                        </div>
                    </b-tab>

                    <b-tab v-if="store.getters.isEnterprise && commonData.contracts_count">
                        <template #title>
                            Contracts
                        </template>

                        <Contracts/>
                    </b-tab>
                </b-tabs>
            </div>
        </main>

        <ErrorReport
            ref="errorReportModal"
            :startup-id="id"
        />

        <Invite ref="inviteModal"/>
        <FundingRoundsListModal ref="fundingRoundsListModal"/>
        <ArticlesModal ref="newsModal"/>

        <b-modal v-model="showAiResponseModal" hide-footer title="AI response">
            {{aiReportRaw}}
        </b-modal>

        <Helper page="startup_profile"/>
    </div>
</template>
