import axios from '@/axios';

const RESOURCE_NAME = '/user';

export default {

    getCurrentUserData() {
        return axios.get(`${RESOURCE_NAME}/get-current-user-data`)
    },

    getColumns(type) {
        return axios.get(`${RESOURCE_NAME}/get-columns?type=${type}`)
    },

    getColumnsForTracker(trackerId) {
        return axios.get(`${RESOURCE_NAME}/get-columns?tracker_id=${trackerId}`)
    },

    getColumnsForManage(type, useType, trackerId = 0) {
        return axios.get(`${RESOURCE_NAME}/get-columns-for-manage?type=${type}&use_type=${useType}&tracker_id=${trackerId}`)
    },

    updateColumns(type, useType, trackerId, columns) {
        return axios.post(`${RESOURCE_NAME}/update-columns?type=${type}&use_type=${useType}&tracker_id=${trackerId}`, {'columns': columns})
    },

    getNotificationSettings() {
        return axios.get(`${RESOURCE_NAME}/get-notification-settings`)
    },

    updateNotificationSettings(data) {
        return axios.post(`${RESOURCE_NAME}/update-notification-settings`, data)
    },

    getProfileEdit() {
        return axios.get(`${RESOURCE_NAME}/get-profile-edit`)
    },

    saveProfile(data) {
        return axios.post(`${RESOURCE_NAME}/save-profile`, data)
    },

    getTaskType(startupId, trackerId = 0) {
        return axios.get(`${RESOURCE_NAME}/get-task-type?startup_id=${startupId}&tracker_id=${trackerId}`)
    },

    saveTrackerFilter(filterType) {
        return axios.post(`${RESOURCE_NAME}/save-tracker-filter`, {filterType: filterType})
    },

    changePassword: data => axios.post(`${RESOURCE_NAME}/change-password`, data),

    sendMessageToAccountManager: data => axios.post(`${RESOURCE_NAME}/send-message-to-account-manager`, data),

    requestAddUsers() {
        return axios.post(`${RESOURCE_NAME}/request-add-users`);
    },

    saveProfileActivityFilter: filters => axios.post(`${RESOURCE_NAME}/save-profile-activity-filter`, {filters: filters}),

    acceptTerms() {
        return axios.post(`${RESOURCE_NAME}/accept-terms`);
    },

    hideFieldFillPrompt() {
        return axios.post(`${RESOURCE_NAME}/hide-field-fill-prompt`)
    },
}
