<script setup>
import {computed, ref} from "vue";

const props = defineProps(['items']);

const showAll = ref(false);
const hasMore = computed(() => props.items.length > 5);
const shownItems = computed(() => {
    if (showAll.value) {
        return props.items;
    }

    return props.items.slice(0, 5);
});

</script>

<template>
    <div>
        <span v-for="(item, index) in shownItems" :key="index">
            {{item}}<template v-if="index < shownItems.length - 1">,</template>
        </span>

        &nbsp;<a
            v-if="hasMore"
            class="link"
            @click="showAll = !showAll"
        >
            <template v-if="showAll">Show less</template>
            <template v-else>Show all</template>
        </a>
    </div>
</template>

<style scoped>

</style>
