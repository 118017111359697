<script setup>
import {decodeDate} from "@/helpers/date";
import VClamp from "vue-clamp";
import {ref} from "vue";

const {activity} = defineProps(['activity', 'isPreview', 'index']);

const bigText = activity.custom_value?.raw_value?.length > 200;
const isClamped = ref(bigText);
</script>

<template>
    <div class="activity-list__content">
        <div class="activity-list__content__inner">
            <h5
                class="activity-list__title mb-2"
                :class="`activity-list__title--${activity.type === 'phone_call' ? 'call' : activity.type}`"
            >
                <span class="mr-2">{{ activity.title }}</span>

                <template v-if="activity.interaction_date">
                    <span style="font-weight: normal;" class="">
                        (Interaction Date: {{decodeDate(activity.interaction_date)}})
                    </span>
                </template>

                <template v-if="activity.custom_value?.field">
                    :<span style="font-weight: normal;" class="ml-2">{{activity.custom_value.field}}</span>
                </template>
            </h5>

            <div class="tags-light-list">
                <template v-if="!isPreview">
                    <span v-for="tag in activity.tags" :key="tag" class="tags-light-list__tag">{{tag}}</span>
                </template>
            </div>
        </div>

        <!-- Only use v-clamp for case if note doesn't contain mention, otherwise we should use v-html statement -->
        <div
            v-if="!activity.text.includes('<span') && activity.object_type !== 'news' && activity.type !== 'update_location'"
            class="activity-comment"
        >
            <v-clamp autoresize :max-lines="2" class="activity-list__content__text">
                {{activity.text}}

                <template #after="{ toggle, expanded, clamped }">
                    <a v-if="expanded" class="link-normal" @click="toggle">less</a>
                    <a v-if="clamped" class="link-normal" @click="toggle">more</a>
                </template>
            </v-clamp>
        </div>

        <template v-else>
            <div class="activity-list__text" v-html="activity.text"></div>
        </template>

        <template v-if="activity.tag">
            <div class="tags-list tags-list--light">
                <div class="tags-list__tag">
                    <template v-if="typeof activity.tag === 'object'">
                        <a :id="'tag-' + index + '-' + activity.tag.id" :href="'/startups?tag=' + activity.tag.name" target="_blank">{{activity.tag.name}}</a>
                        <b-tooltip :target="'tag-' + index + '-' + activity.tag.id" placement="right" variant="light" :title="activity.tag.description"/>
                    </template>

                    <template v-else>
                        <a :href="'/startups?tag=' + activity.tag" target="_blank">{{activity.tag}}</a>
                    </template>
                </div>
            </div>
        </template>

        <template v-if="activity.tracker">
            <div>
                <a class="action-logs-list__item"
                   :href="'/trackers/' + activity.tracker.id"
                   target="_blank">{{activity.tracker.name}}
                </a>
            </div>
        </template>

        <template v-if="activity.contact">
            <div class="action-logs-list__user-inner">
                {{activity.contact}}
            </div>
        </template>

        <template v-if="activity.task">
            <div class="action-logs-list__task">
                <a :href="'/?open_task=' + activity.task.id" target="_blank">{{activity.task.name}}</a>
            </div>
        </template>

        <template v-if="activity.custom_value">
            <div class="action-logs-list__custom">
                <template v-if="activity.custom_value.value.length">
                    <v-clamp
                        v-if="isClamped"
                        autoresize :max-lines="3"
                    >
                        {{activity.custom_value.value}}

                        <template #after="{ clamped }">
                            <a v-if="clamped" class="link-normal" @click="isClamped = false"> more</a>
                        </template>
                    </v-clamp>

                    <template v-else>
                        <div v-html="activity.custom_value.raw_value"/>

                        <a
                            v-if="bigText"
                            class="link-normal"
                            @click="isClamped = true">
                            less
                        </a>
                    </template>
                </template>
            </div>
        </template>

        <template v-if="activity.file">
            <div class="action-logs-list__file-wrapper">
                <div class="action-logs-list__file">
                    {{ activity.file }}
                </div>
            </div>
        </template>
    </div>
</template>

<style scoped>
    .activity-item h5 {
        font-size: 14px;
        font-weight: bold;
    }

    .activity-item {
        .activity-comment {
            font-style: italic;
            font-weight: normal;
        }
    }
</style>
