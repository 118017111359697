<script setup>
import {computed, ref} from "vue";

const props = defineProps(['title', 'list', 'aiGenerated', 'loading']);
const emit = defineEmits(['add']);
const showAll = ref(false);

const hasMore = computed(() => props.list.length > 5);
const id = computed(() => `${props.title.replace(/ /g, '-')}-block`);

const shownItems = computed(() => {
    if (showAll.value) {
        return props.list;
    }

    return props.list.slice(0, 5);
});
</script>

<template>
    <div class="add-block">
        <div>
            <a v-b-toggle="id" class="block-header">
                <span :class="{'ai-generated-title': aiGenerated}">{{ title }}</span>
            </a>
        </div>

        <div v-if="loading" class="text-center">
            <b-spinner small></b-spinner>
        </div>

        <b-collapse v-else class="filters__list__content" :visible="true" :id="id">
            <ul>
                <li
                    v-for="(feature, index) in shownItems"
                    :key="index"
                    class="feature-item"
                >
                    <div>
                        <div>
                            {{feature}}
                        </div>

                        <div>
                            <a @click="emit('add', feature)" class="add-button"/>
                        </div>
                    </div>
                </li>
            </ul>

            <div class="text-right">
                <a v-if="hasMore" class="link show-more-link" @click="showAll = !showAll">
                    {{ showAll ? 'Less' : 'More' }}
                </a>
            </div>
        </b-collapse>
    </div>
</template>

<style scoped>
    .add-block {
        margin-top: 20px;

        .block-header {
            font-weight: bold;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: inherit;
            text-decoration: none;

            &:after {
                content: "";
                display: block;
                width: 10px;
                height: 10px;
                margin-right: 3px;
                background: url(../../../images/icon-r-play-dark.svg) no-repeat;
                background-size: contain;
                transform: rotate(90deg);
            }

            &.not-collapsed:after {
                transform: rotate(-90deg);
            }

            .ai-generated-title {
                display: flex;
                align-items: center;
                align-self: center;

                &:after {
                    content: "";
                    display: block;
                    width: 15px;
                    height: 20px;
                    margin-left: 5px;
                    background: url(../../../images/icon-ai-blue.svg) no-repeat;
                    background-size: contain;
                }
            }
        }

        .feature-item {
            padding: 10px 0;

            &:not(:last-child) {
                border-bottom: 1px solid #ddd;
            }

            &>div {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }

        .add-button {
            display: block;
            flex: 0 0 16px;
            width: 16px;
            height: 16px;
            background: url(../../../images/icon-add-light.svg) no-repeat center;
            background-size: contain;
        }

        .show-more-link {
            font-size: 14px;
        }
    }
</style>
