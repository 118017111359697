<template>
    <div>
        <highcharts
            v-if="loaded"
            :options="getOptions(chartOptions)"
            ref="chart"
        />

        <div v-else class="d-flex justify-content-center">
            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
        </div>
    </div>
</template>

<script>
    import {Chart} from 'highcharts-vue'
    import Highcharts from 'highcharts'
    import exportingInit from "highcharts/modules/exporting"
    import addFunnelModule from "highcharts/modules/funnel"
    import dashboardChartMixin from '@/mixins/dashboardChartMixin'

    addFunnelModule(Highcharts)
    exportingInit(Highcharts)

    const colors = ['#043648', '#a50000', '#ffbf1c', '#0083b2', '#44bf2e', '#949494']

    export default {
        name: 'TrackerStagesFunnelChart',
        mixins: [dashboardChartMixin],
        components: {
            highcharts: Chart,
        },
        data() {
            return {
                loaded: false,
                chartName: 'StartupsByFunnelStage',
                chartData: [],
                chartOptions: {
                    chart: {
                        type: 'funnel',
                    },
                    title: {
                        text: 'Tracker Stages Funnel'
                    },
                    tooltip: {
                        formatter: function () {
                            return '<b>' + this.key +
                                '</b>: <b>' + Highcharts.numberFormat(this.point.label, 0) + '</b>'
                        }
                    },
                    plotOptions: {
                        calculable: true,
                        series: {
                            dataLabels: {
                                enabled: true,
                                format: "{point.name}: {point.label:,.0f}",
                                softConnector: true,
                                inside: false,
                                useHTML: true,
                                style: {
                                    textOverflow: 'clip',
                                    textAlign: 'center'

                                }
                            },
                            neckHeight: 0,
                            neckWidth: 0,
                            //neckWidth: '25%',
                            width: '80%'
                        }
                    },
                    legend: {
                        enabled: false
                    },
                    series: [{
                        name: 'Trackers',
                        data: [],
                        events: {
                            click: (e) => {
                                this.openStartupsModal(e.point.options.name)
                            }
                        }
                    }]
                }
            }
        },
        methods: {
            fetchChartData(initialCall = false) {
                this.getChartData('StartupsByFunnelStage').then(response => {
                    this.chartData = response.data
                    this.formatAndSetChartData()

                    if (!initialCall) {
                        this.$refs.chart.chart.hideLoading()
                    } else {
                        this.loaded = true
                    }
                })
            },
            formatAndSetChartData() {
                let stages = []

                //Calculate proportions to show minimum height of stage
                let len = this.chartData.length
                let sum = 0
                let minHeight = 0.06

                for (let i = 0; i < len; i++) {
                    sum += this.chartData[i]['cnt']
                }

                this.chartData.forEach((item, key) => {
                    let fraction = item.cnt / sum
                    stages.push({
                        name: item.funnel_stage,
                        y: fraction > minHeight ? item.cnt : ((sum * minHeight) + item.cnt),
                        color: colors[key],
                        label: item.cnt,
                    })
                })

                this.chartOptions.series[0].data = stages

                // this.$refs.chart.chart.series[0].setData(stages, true)
            },

            openStartupsModal(stage) {
                this.$emit('showStartups', [this.chartName, `Startups in "${stage}" stage`, stage])
            },
        }
    }
</script>
