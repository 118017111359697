<script setup>
import multiselect from "vue-multiselect";
import {useStore} from "vue2-helpers/vuex";
import {onMounted, ref, watch} from "vue";

const emit = defineEmits(['input']);
const store = useStore();
const enterprise = ref(null);
const enterprises = ref([]);

watch(() => store.getters.eventEnterprises, (value) => {
    enterprises.value = value;
}, {immediate: true});

watch(enterprise, (value) => {
    emit('input', value);
});

onMounted(() => {
    store.dispatch('fetchEventEnterprises');
});

</script>

<template>
    <div>
        <multiselect
            class="multiselect-mid"
            v-model="enterprise"
            :options="enterprises"
            select-label=""
            deselect-label=""
            label="name"
            track-by="name"
        />
    </div>
</template>

<style scoped>

</style>
