<template>
    <div>
        <template v-if="$store.getters.isApproved">
            <div class="topbar topbar--ecosystem">
                <div class="topbar--ecosystem__inner">
                    <p>Search Opportunities:</p>
                    <input class="form-search-primary" v-debounce:500="handleChangeSearch"
                           placeholder="Search by title or Enterprise" type="text"/>

                    <div class="topbar__multiselect">
                        <Multiselect
                            v-model="filters.categories"
                            :multiple="true"
                            placeholder="Select categories"
                            :options="$store.state.categories"
                            select-label=""
                            deselect-label=""
                            label="name"
                            track-by="name"
                            class="multiselect-mid pl-2"
                        >
                        </Multiselect>
                    </div>

                    <b-dropdown id="dropdown-form" text="Filter List" ref="checkboxesDropdown"
                                class="topbar__side__actions topbar__side__filter">
                        <b-dropdown-header id="dropdown-header-label">
                            <h5 class="heading5 heading5--secondary">Display asks which I have:</h5>
                        </b-dropdown-header>

                        <b-dropdown-form>
                            <li role="presentation">
                                <b-form-checkbox v-model="filters.notSubmitted"
                                                 class="input-checkbox input-checkbox--secondary">Not submitted startups
                                </b-form-checkbox>
                            </li>
                            <li role="presentation">
                                <b-form-checkbox v-model="filters.submitted"
                                                 class="input-checkbox input-checkbox--secondary">Submitted startups
                                </b-form-checkbox>
                            </li>
                            <li role="presentation" v-if="false">
                                <b-form-checkbox v-model="filters.notViewed" disabled
                                                 class="input-checkbox input-checkbox--secondary">Not viewed
                                </b-form-checkbox>
                            </li>
                            <b-button variant="primary" size="sm" @click="applyCheckboxes"
                                      class="topbar__side__filter__button">Apply
                            </b-button>
                        </b-dropdown-form>
                    </b-dropdown>

                </div>
            </div>
        </template>

        <main class="main main--dark main--dashboard main--dashboard--ecosystem pb-0">
            <div class="content">
                <template>
                    <section class="dashboard--briefs">
                        <div class="card card--bordered card--dashboard">
                            <header class="dashboard__header">
                                <h3 class="heading3">Opportunities</h3>
                            </header>
                            <div class="dashboard__content">
                                <div v-if="loadingOpportunities" class="loading__spinner mt-3">
                                    <span><b-spinner class="mb-1 mr-2" small></b-spinner>Loading...</span>
                                </div>
                                <ul class="list-cards" v-if="!loadingOpportunities">
                                    <li v-for="tracker in trackers" :key="tracker.id">
                                        <div class="image-block image-block--md">
                                            <img :src="LogoHelper.getLogo(tracker.enterprise.logoUrl)"
                                                 v-bind:alt="tracker.name"/>
                                        </div>

                                        <div class="list-cards__content">
                                            <router-link class="heading4 heading4--secondary"
                                                         :to="`/opportunities/${tracker.id}`">{{ tracker.name }}
                                            </router-link>
                                            <router-link class="heading5 heading5--tertiary"
                                                         :to="`/enterprises/${tracker.enterprise.id}`">
                                                {{ tracker.enterprise.name }}
                                            </router-link>

                                            <p>
                                                <v-clamp autoresize :max-lines="3"> {{ tracker.description }}
                                                    <template #after="{ toggle, expanded, clamped }">
                                                        <a v-if="expanded" class="link-normal" @click="toggle"> less</a>
                                                        <a v-if="clamped" class="link-normal" @click="toggle"> more</a>
                                                    </template>
                                                </v-clamp>
                                            </p>

                                            <div class="btn-wrapper">
                                                <a href="javascript:void(0)" class="btn btn-transparent"
                                                   v-for="category in tracker.categories"
                                                   v-on:click="applyCategory(category)"
                                                   :key="category.id">
                                                    {{ category.name }}
                                                </a>
                                            </div>
                                        </div>

                                        <div class="list-cards__side">
                                            <p>
                                                <router-link class="button button--link"
                                                             :to="`/opportunities/${tracker.id}`">View Details
                                                </router-link>
                                            </p>

                                            <p v-if="tracker.startups_count.length > 0"
                                               class="list-cards__side__startups">Submitted {{ tracker.startups_count }}
                                                startups</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                </template>
            </div>
            <aside class="aside aside--secondary">
                <div class="aside__inner">
                    <div class="aside__inner__section">
                        <div class="d-flex align-items-center">
                            <div class="image-block">
                                <img :src="LogoHelper.getLogo($store.state.user.company_logo)" alt="img"/>
                            </div>
                            <h4 class="heading4">{{ $store.state.user.company_name }}</h4>
                        </div>
                        <h6 v-if="false" class="heading6 heading6--secondary">{{ $store.state.user.company_name }}</h6>
                        <p><a :href="$store.state.user.company_url"
                              target="_blank">{{ $store.state.user.company_website }}</a></p>
                    </div>
                    <div v-if="$store.state.user.company_id !== constants.companyPfizer"
                         class="card card--bordered card--user">
                        <div class="card--user__inner">
                            <div class="card--user__image"></div>

                            <div>
                                <h6 class="heading6">Natasha Braun</h6>
                                <p>Account Manager</p>
                            </div>
                        </div>

                        <a href="http://calendly.com/natasha-switchpitch" target="_blank"
                           class="button button--bordered--with--icon button--schedule">Schedule Meeting</a>
                    </div>

                    <div v-if="false" class="card card--bordered card--user">
                        <div class="card--user__inner">
                            <div class="card--user__image"></div>
                            <div>
                                <h6 class="heading6">Natasha Braun</h6>
                                <p>Account Manager</p>
                            </div>
                        </div>

                        <div class="card--user__controls">
                            <a href="#" class="link-schedule">Schedule Meeting</a>
                            <a href="#" class="link-envelope">Send Message</a>
                        </div>
                    </div>

                </div>
            </aside>
        </main>
    </div>
</template>

<script>

import qs from "qs";
import VClamp from "vue-clamp";
import Multiselect from 'vue-multiselect';

import trackers from "@/services/trackers.service";
// import dashboard from "@/services/ecosystem/dashboard.service";


const filterCategory = (category, query) => {
    if (typeof query === "string") {
        return query === category.id.toString()
    }

    return (query ?? []).includes(category.id.toString())
}

export default {
    data() {
        return {
            filters: {
                // notViewed: true,
                submitted: true,
                categories: this.$store.state.categories
                    .filter(category => filterCategory(category, this.$route.query['categories[]'])),
                notSubmitted: true
            },
            trackers: [],

            search: "",
            startupsLoaded: false,
            opportunitiesLoaded: false,
            loadingOpportunities: true
        }
    },

    watch: {
        search() {
            this.getList()
        },
        'filters.categories'(newCategories, oldCategories) {
            if (newCategories.length !== oldCategories.length) {
                this.$router.push(`/opportunities?${qs.stringify(
                    {categories: newCategories.map(category => category.id)},
                    {arrayFormat: 'brackets'}
                )}`)
                this.getList()
            }
        }
    },

    mounted() {
        this.getList()

        this.$store.watch(state => {
            if (state.categories.length > 0) {
                this.filters.categories = state.categories
                    .filter(category => filterCategory(category, this.$route.query['categories[]']))
            }
        })
    },

    methods: {
        applyCheckboxes() {
            this.$refs.checkboxesDropdown.hide()
            this.getList()
        },

        getList: async function () {
            const categories = this.filters.categories.map(category => category.id)

            this.loadingOpportunities = true

            const response = await trackers.getOpportunitiesIndex({
                ...this.filters,
                search: this.search,
                categories
            })

            this.trackers = response.data
            this.loadingOpportunities = false
            this.opportunitiesLoaded = true
        },

        applyCategory(category) {
            if (this.filters.categories.find(c => parseInt(c.id) === parseInt(category.id))) {
                this.filters.categories = this.filters.categories.filter(c => parseInt(c.id) !== parseInt(category.id))
            } else {
                this.filters.categories = [...this.filters.categories, {
                    label: category.name,
                    id: category.id,
                }]
            }
        },

        addStartup() {
            this.$store.commit('setAddStartup', true);
            this.$nextTick(() => {
                this.$store.commit('setAddStartup', false);
            })
        },

        addEnterprise() {
            this.$store.commit('setAddEnterprise', true);
            this.$nextTick(() => {
                this.$store.commit('setAddEnterprise', false);
            })
        },

        handleChangeSearch(search) {
            this.search = search
        },

        openCompanyProfile() {
            this.$store.commit('setOpenCompanyProfile', true);

            this.$nextTick(() => {
                this.$store.commit('setOpenCompanyProfile', false);
            })
        },
    },

    components: {
        Multiselect,
        VClamp,
    }
}
</script>
