<template>
    <div>
        <highcharts
            v-if="loaded"
            :options="getOptions(chartOptions)"
            ref="chart"
        />

        <div v-else class="d-flex justify-content-center">
            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
        </div>
    </div>
</template>

<script>
    import {Chart} from 'highcharts-vue'
    import exportingInit from "highcharts/modules/exporting"
    import Highcharts from "highcharts"
    import dashboardChartMixin from '@/mixins/dashboardChartMixin'

    exportingInit(Highcharts)

    export default {
        name: 'StartupsByRegionChart',
        mixins: [dashboardChartMixin],
        components: {
            highcharts: Chart
        },
        data() {
            return {
                loaded: false,
                chartName: "StartupsByRegion",
                chartData: [],
                chartOptions: {
                    chart: {
                        type: "column",
                        style: {
                            fontFamily: '"Overpass", sans-serif;',
                        },
                    },
                    title: {
                        text: "Startups By Region"
                    },
                    xAxis: {
                        categories: [],
                        title: {
                            text: null
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: "Number of startups added",
                            align: "high"
                        },
                    },
                    tooltip: {
                        valueSuffix: " startup(s)"
                    },
                    plotOptions: {
                        column: {
                            dataLabels: {
                                enabled: true,
                            },
                            events: {
                                click: (e) => {
                                    this.openStartupsModal(e.point.category)
                                }
                            }
                        }
                    },
                    series: [
                        {
                            name: "Region",
                            data: [],
                            colorByPoint: true,
                        }
                    ]
                }
            }
        },
        methods: {
            fetchChartData(initialCall = false) {
                if (!initialCall) {
                    this.$refs.chart.chart.showLoading()
                }

                this.getChartData('StartupsByRegion').then(response => {
                    this.chartData = response.data
                    this.formatAndSetChartData()

                    if (!initialCall) {
                        this.$refs.chart.chart.hideLoading()
                    } else {
                        this.loaded = true
                    }
                })
            },
            formatAndSetChartData() {
                let regions = []
                let startupsCount = []

                this.chartData.forEach(item => {
                    regions.push(item.region)
                    startupsCount.push(item.cnt)
                })

                this.chartOptions.xAxis.categories = regions
                this.chartOptions.series[0].data = startupsCount
            },

            openStartupsModal(region) {
                this.$emit('showStartups', [this.chartName, `Startups from ${region}`, region])
            },
        }
    }
</script>
