<script setup>
import {computed, onBeforeUnmount, onMounted, ref, watch} from "vue";
import dashboardService from "@/services/dashboard.service";
import {useStore} from "vue2-helpers/vuex";
import enterpriseService from "@/services/enterprise.service";
import {GridLayout, GridItem} from 'vue-grid-layout';

const store = useStore();
const emit = defineEmits(['closed']);
const showModal = ref(false);
const widgets = ref([]);
const premiumCharts = ref([]);
const hasCustomCharts = ref(false);
const gridLayout = ref(null);

let mouseXY = {"x": null, "y": null}
let dragPos = {"x": null, "y": null, "w": 1, "h": 1, "i": null, "minW": 1, "maxW": 2, "minH": 1, "maxH": 1}

const layout = computed(() => store.getters['dashboard/layout']);
const notUsedWidgets = computed(() => widgets.value.filter(widget => !widget.used));

const handler = (e) => {
    mouseXY.x = e.clientX
    mouseXY.y = e.clientY
}

watch(showModal, (value) => {
    if (!value) {
        emit('closed');
    }
})

onMounted(() => {
    window.addEventListener('dragover', handler, false);
});

onBeforeUnmount(() => {
    window.removeEventListener('dragover', handler, false);
});

onBeforeUnmount(() => {
    window.removeEventListener('dragover', (e) => {
        mouseXY.x = e.clientX
        mouseXY.y = e.clientY
    }, false);
});

async function getAvailableCharts() {
    const {data} = await dashboardService.getAvailableCharts();
    widgets.value = data.available.map(widget => ({
        ...widget,
        used: layout.value.some(item => item.i === widget.id)
    }));

    premiumCharts.value = data.premium;
    hasCustomCharts.value = data.has_custom;
}

function drag() {
    let parentRect = document.getElementById('content').getBoundingClientRect()
    let mouseInGrid = false

    if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right)) && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
        mouseInGrid = true;
    }

    if (mouseInGrid && layout.value.findIndex(item => item.i === 'drop') === -1) {
        console.log(1);
        store.commit('dashboard/addChart', {
            x: 1,
            y: 1,
            w: 1,
            h: 1,
            i: 'drop',
            minW: 1,
            maxW: 2,
            minH: 1,
            maxH: 1
        })
    }

    let index = layout.value.findIndex(item => item.i === 'drop');

    if (index >= 0) {
        let el = gridLayout.value.$children[index];
        el.dragging = {"top": mouseXY.y - parentRect.top, "left": mouseXY.x - parentRect.left}
        let new_pos = el.calcXY(mouseXY.y - parentRect.top, mouseXY.x - parentRect.left);

        if (mouseInGrid === true) {
            gridLayout.value.dragEvent('dragstart', 'drop', new_pos.x, new_pos.y, 1, 1);
            dragPos.i = String(index);
            dragPos.x = layout.value[index].x;
            dragPos.y = layout.value[index].y;
        }

        if (mouseInGrid === false) {
            gridLayout.value.dragEvent('dragend', 'drop', new_pos.x, new_pos.y, 1, 1)
            store.commit('dashboard/changeLayout', layout.value.filter(obj => obj.i !== 'drop'));
        }
    }
}

function dragend(event) {
    let parentRect = document.getElementById('content').getBoundingClientRect();
    let mouseInGrid = false

    if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right)) && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
        mouseInGrid = true
    }

    if (mouseInGrid === true) {
        gridLayout.value.dragEvent('dragend', 'drop', dragPos.x, dragPos.y, 1, 1)
        store.commit('dashboard/changeLayout', layout.value.filter(obj => obj.i !== 'drop'));

        let widgetId = event.target.getAttribute("data-widget")
        markWidget(widgetId);
        let widgetData = getWidgetData(widgetId);

        store.commit('dashboard/addChart', {
            x: dragPos.x,
            y: dragPos.y,
            w: 1,
            h: 1,
            i: widgetData.id,
            minW: 1,
            maxW: 2,
            minH: 1,
            maxH: 1,
            name: widgetData.name,
            img: widgetData.img,
            src: widgetData.src,
        });

        gridLayout.value.dragEvent('dragend', dragPos.i, dragPos.x, dragPos.y, 1, 1);
        saveSettings()
    }
}

function markWidget(id, mark = true) {
    const index = widgets.value.map(widget => widget.id).indexOf(parseInt(id));

    if (index >= 0) {
        widgets.value[index].used = mark
    }
}

function getWidgetData(id) {
    const index = widgets.value.map(widget => widget.id).indexOf(parseInt(id))

    if (index >= 0) {
        return widgets.value[index]
    }
}

function removeItem(val) {
    const index = layout.value.map(item => item.i).indexOf(val);
    layout.value.splice(index, 1)
    markWidget(val, false);
    saveSettings();
}

async function saveSettings() {
    await enterpriseService.saveDashboardSettings(layout.value);
}

function open() {
    showModal.value = true;
    getAvailableCharts();
}

defineExpose({
    open
})
</script>

<template>
    <b-modal v-model="showModal" id="customize-dashboard-modal" modal-class="modal-w-910" hide-header hide-footer>
        <div class="customize-dashboard-modal">
            <div class="customize-dashboard-modal__side">
                <header class="customize-dashboard-modal__header">
                    <h5 class="heading5">Add Widgets</h5>
                    <a href="#" @click.prevent="showModal = false" class="close"></a>
                </header>

                <p>Drag widgets into your desired location to add to homescreen</p>

                <ul class="list-widgets">
                    <li
                        v-for="widget in notUsedWidgets"
                        :key="widget.id"
                        :data-widget="widget.id"
                        @drag="drag"
                        @dragend="dragend"
                        draggable="true"
                    >
                        {{ widget.name }}
                    </li>
                </ul>

                <template v-if="premiumCharts.length">

                    <div class="widget-heading">
                        <span class="widget-heading__title">Premium Charts</span>
                        <a class="widget-heading__unlock" @click="showUnlockChartsModal('premium')">Unlock</a>
                    </div>

                    <ul class="list-widgets">
                        <li v-for="(chart, index) in premiumCharts" :key="index">{{chart.name}}</li>
                    </ul>

                </template>

                <template v-if="!hasCustomCharts">
                    <div class="widget-heading widget-heading__custom">
                        <span class="widget-heading__title">Custom Charts</span>
                        <a class="widget-heading__unlock" @click="showUnlockChartsModal('custom')">Unlock</a>

                    </div>

                    <ul class="list-charts">
                        <li>Unlock all premium charts</li>
                        <li>Customize chart types for any chart</li>
                        <li>Create up to 5 custom charts</li>
                    </ul>
                </template>
            </div>

            <div class="customize-dashboard-modal__main">
                <h5 class="modal-title">Customize Dashboard</h5>

                <ul class="list-action-hint">
                    <li class="list-action-hint__reorder">Drag to reorder</li>
                    <li class="list-action-hint__remove">Remove</li>
                    <li class="list-action-hint__resize">Drag corner to resize</li>
                </ul>

                <div id="content">
                    <div class="vue-grid-layout-wrapper">
                        <GridLayout
                            ref="gridLayout"
                            :layout.sync="layout"
                            :col-num="2"
                            :is-draggable="true"
                            :is-resizable="true"
                            :vertical-compact="true"
                            :use-css-transforms="true"
                        >
                            <GridItem
                                v-for="item in layout"
                                :key="item.i"
                                class="card"
                                :x="item.x"
                                :y="item.y"
                                :w="item.w"
                                :h="item.h"
                                :i="item.i"
                                :minW="item.minW"
                                :maxW="item.maxW"
                                :minH="item.minH"
                                :maxH="item.maxH"
                                @moved="saveSettings()"
                                @resized="saveSettings()"
                            >
                                <a href="#" class="card__remove" @click.prevent="removeItem(item.i)"></a>

                                <header class="card__header">
                                    {{ item.name }}
                                </header>

                                <div class="card__inner">
                                    <img :src="item.img" style="height: 95px;" alt="img"/>
                                </div>
                            </GridItem>
                        </GridLayout>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<style scoped>

</style>
