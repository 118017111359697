<template>
    <b-modal v-model="showModal" hide-footer title="Submitted answers" modal-class="modal-w-md">
        <div v-for="(answer, index) in answers" :key="index" class="mt-2 mb-4">
            <h5 class="heading5 heading5--secondary">{{answer.field.name}}</h5>

            <CustomFieldValue
                :field="answer.field"
                :init-value="answer.value"
                :editable="false"
            />
        </div>
    </b-modal>
</template>

<script>
import CustomFieldValue from "@/components/customFields/CustomFieldValue.vue";
import trackersCompanies from "@/services/trackers-companies.service";

export default {
    components: {
        CustomFieldValue
    },

    data() {
        return {
            showModal: false,
            trackerId: 0,
            startupId: 0,
            answers: [],
        }
    },

    methods: {
        init() {
            this.answers = []

            trackersCompanies.getApplicationData(this.trackerId, this.startupId).then(result => {
                this.answers = result.data
            })
        },

        open(trackerId, startupId) {
            this.trackerId = trackerId
            this.startupId = startupId
            this.init()
            this.showModal = true;
        },
    }
}
</script>

<style scoped>

</style>
