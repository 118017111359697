<script setup>
import Multiselect from "vue-multiselect";
import {inject, onMounted, ref} from "vue";
import trackersFoldersService from "@/services/trackers-folders.service";

const tracker = inject('tracker');
const canEditInfo = inject('canEditInfo');
const initFolderId = inject('folderId');

const foldersList = ref([]);

onMounted(() => {
    getFoldersList();
});

async function getFoldersList(folderId = 0) {
    let folderType = ['ecosystems','POC'].includes(tracker.type) ? 'private' : tracker.type;

    const {data} = await trackersFoldersService.getList(folderId, folderType);
    foldersList.value = data

    if (foldersList.value.length) {
        foldersList.value.unshift({id: 0, name: 'None'})

        if (initFolderId) {
            tracker.folder = foldersList.value.find(item => item.id === initFolderId);
        }
    }
}
</script>

<template>
    <div v-if="foldersList.length">
        <h5 class="heading5 heading5--secondary">Folder <span class="heading5__light">(Optional)</span></h5>

        <Multiselect
            class="multiselect-mid"
            v-model="tracker.folder"
            :options="foldersList"
            select-label=""
            deselect-label=""
            label="name"
            track-by="name"
            :disabled="!canEditInfo"
        />
    </div>
</template>

<style scoped>

</style>
