<template>
    <div>
        <highcharts
            v-if="loaded"
            :options="getOptions(chartOptions)"
            ref="chart"
        />

        <div v-else class="d-flex justify-content-center">
            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
        </div>
    </div>
</template>

<script>
    import {Chart} from 'highcharts-vue'
    import Highcharts from 'highcharts'
    import exportingInit from "highcharts/modules/exporting"
    import mapInit from 'highcharts/modules/map'
    import tileMap from "highcharts/modules/tilemap"
    import dashboardChartMixin from '@/mixins/dashboardChartMixin'

    mapInit(Highcharts)
    exportingInit(Highcharts)
    tileMap(Highcharts)

    export default {
        name: 'UniqueStartupsTrackedCounterChart',
        mixins: [dashboardChartMixin],
        components: {
            highcharts: Chart
        },
        data() {
            return {
                loaded: false,
                chartName: 'UniqueTrackedStartupsCount',
                chartData: [],
                chartOptions: {
                    chart: {
                        type: 'tilemap',
                    },
                    credits: {
                        enabled: false
                    },
                    title: {
                        text: 'Unique Startups Tracked',
                        align: 'left'
                    },
                    tooltip: {
                        enabled: false
                    },
                    xAxis: {
                        visible: false
                    },
                    yAxis: {
                        visible: false
                    },
                    legend: {
                        align: 'center',
                        verticalAlign: 'bottom',
                        y: -50,
                        itemStyle: {
                            fontSize: '12px',
                        },
                        symbolHeight: .001,
                        symbolWidth: .001,
                        symbolRadius: .001
                    },
                    plotOptions: {
                        series: {
                            dataLabels: {
                                enabled: true,
                                style: {
                                    fontSize: '80px'
                                },
                                formatter: function() {
                                    return Highcharts.numberFormat(this.y, 0, '', ',');
                                }
                            },
                        }
                    },
                    series: [
                        {
                            name: '',
                            data: [
                                    {
                                        y: 0,
                                        color: '#ffffff'
                                    }
                                ],
                            events: {
                                click: () => {
                                    this.openStartupsModal()
                                }
                            }
                        },
                    ]
                }
            }
        },
        methods: {
            fetchChartData(initialCall = false) {
                this.getChartData('UniqueTrackedStartupsCount').then(response => {
                    this.chartData = response.data
                    this.formatAndSetChartData()

                    if (!initialCall) {
                        this.$refs.chart.chart.hideLoading()
                    } else {
                        this.loaded = true
                    }
                })
            },

            formatAndSetChartData() {
                this.chartOptions.series[0].data[0].y = this.chartData.curr

                if (this.filters.period === 'all') {
                    this.chartOptions.series[0].name = "All time count"
                }
                else {
                    let periodName = this.filters.period

                    if (!isNaN(parseInt(periodName))) {
                        periodName += " days"
                    }

                    let curr = parseInt(this.chartData.curr)
                    let prev = parseInt(this.chartData.prev)
                    let showPerc = true

                    if (curr || prev) {
                        let diff

                        if (prev) {
                            if (curr) {
                                diff = parseInt((curr - prev) / prev * 100)
                                if (Math.abs(diff) > 100) {
                                    diff = curr - prev
                                    showPerc = false
                                }
                            }
                            else {
                                showPerc = false
                                diff = -prev
                            }
                        } else {
                            showPerc = false
                            diff = curr
                        }

                        if (diff) {
                            this.chartOptions.series[0].name = '<span style="color: ' + (diff > 0 ? 'green' : 'red') + '">' + (diff > 0 ? '▲' : '▼') + Math.abs(diff).toLocaleString() + (showPerc ? '%' : '') + ' ' + (diff > 0 ? 'higher' : 'lower') + '</span> than previous ' + periodName
                        }
                        else {
                            this.chartOptions.series[0].name = 'Same as previous ' + periodName
                        }
                    }
                    else {
                        this.chartOptions.series[0].name = ''
                    }
                }
            },

            openStartupsModal() {
                this.$emit('showStartups', [this.chartName, `Unique Startups Tracked`, ""])
            },
        }
    }
</script>
