<script setup>
import useGrid from "@/composables/useGrid";
import useCurrentPageData from "@/composables/useCurrentPageData";
import savedSearchesService from "@/services/saved-searches.service";
import {ref} from "vue";
import TablePager from "@/components/TablePager.vue";
import SearchesList from "@/views/enterprise/home/content/searches/SearchesList.vue";

const {gridParams, gridMethods} = useGrid(getSearches, 6);
const getPageItems = useCurrentPageData();
const searches = ref([]);
const searchesLoaded = ref(false);
const showModal = ref(false);

async function getSearches() {
    searches.value = await getPageItems(getSearchesIndexList, gridParams);
}

function getSearchesIndexList(pageParams) {
    return savedSearchesService.getIndexList(pageParams);
}

function open() {
    if (!searchesLoaded.value) {
        gridParams.currentPage = 1;
        getSearches();
    }

    showModal.value = true;
}

defineExpose({open});
</script>

<template>
    <b-modal v-model="showModal" title="Saved Searches" modal-class="modal-searches" hide-footer>
        <SearchesList :searches="searches"/>
        
        <TablePager
            v-if="searches.length"
            :pages-count="gridParams.pagesCount"
            :init-page="gridParams.currentPage"
            @changePage="gridMethods.changePage"
        />
    </b-modal>
</template>

<style scoped>

</style>
