import tasks from "@/services/tasks.service"

export default {
    state: {
        availableTasksIds: [],
        tasksLoaded: false,
    },

    actions: {
        async fetchTasks({state}){
            if (!state.tasksLoaded) {
                const {data} = await tasks.getAvailableIds();

                state.availableTasksIds = data;
                state.tasksLoaded = true;
            }
        }
    },

    getters: {
        availableTasksIds: state => state.availableTasksIds,
        tasksLoaded: state => state.tasksLoaded
    }
}
