<script setup>
import {ref} from "vue";
import TasksWidget from "@/components/tasks/TasksWidget.vue";

defineProps(['tasksCount']);
const emit = defineEmits(['tasksChanged']);
const tasksWidget = ref(null);
const showModal = ref(false);

async function open() {
    showModal.value = true;
    tasksWidget.value.getTasks();
}

defineExpose({open});
</script>

<template>
    <div>
        <b-modal v-model="showModal" hide-header hide-footer modal-class="modal-manage modal-w-xxl" static>
            <TasksWidget
                ref="tasksWidget"
                :from-modal="true"
                @closeButtonClicked="showModal = false"
                @innerModalOpened="showModal = false"
                @innerModalClosed="showModal = true"
                @tasksChanged="emit('tasksChanged')"
            />
        </b-modal>
    </div>
</template>

<style scoped>

</style>
