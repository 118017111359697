<template>
    <b-modal v-model="showModal" :title="title" modal-class="modal-w-md" :data-track="title">
        <div class="form__item">
            <h6 class="heading6 mb-1">Task Title*</h6>
            <b-form-input class="form-control--mid mb-3" v-model="task.title"></b-form-input>
        </div>

        <template v-if="!startupId && !trackerId && !ecosystemId">
            <div class="form__item d-flex mb-3">
                <div class="input-radio mr-3">
                    <input id="pr1" type="radio" v-model="type" value="startup">
                    <label for="pr1">Startup</label>
                </div>

                <div class="input-radio mr-3">
                    <input id="pr2" type="radio" v-model="type" value="tracker">
                    <label for="pr2">Tracker</label>
                </div>

                <div class="input-radio">
                    <input id="pr3" type="radio" v-model="type" value="ecosystem">
                    <label for="pr3">Ecosystem</label>
                </div>
            </div>

            <div class="form__item" v-if="type === 'startup'">
                <h6 class="heading6 mb-1">Startup*</h6>
                <multiselect
                    class="multiselect--complex multiselect-mid mb-3"
                    v-model="startup"
                    :options="startupsList"
                    select-label=""
                    deselect-label=""
                    label="name"
                    track-by="name"
                    placeholder="Start typing..."
                    :loading="loadingStartupsList"
                    @search-change="getStartups"
                >
                    <template slot="option" slot-scope="props"><img :src="LogoHelper.getLogo(props.option.logo)" style="width:40px" alt=""> {{ props.option.name }}</template>
                </multiselect>
            </div>

            <div class="form__item" v-if="type === 'tracker'">
                <h6 class="heading6 mb-1">Tracker*</h6>
                <multiselect
                    v-model="tracker"
                    :options="trackersList"
                    select-label=""
                    deselect-label=""
                    placeholder="Type to search your trackers"
                    label="name"
                    track-by="name"
                    class="multiselect-mid selectbox selectbox-mid mb-3"
                >
                </multiselect>
            </div>

            <div class="form__item" v-if="type === 'ecosystem'">
                <h6 class="heading6 mb-1">Ecosystem*</h6>
                <multiselect
                    v-model="ecosystem"
                    :options="ecosystemsList"
                    select-label=""
                    deselect-label=""
                    placeholder="Type to search ecosystem"
                    label="name"
                    track-by="name"
                    class="multiselect--complex multiselect-mid mb-3"
                    @search-change="getEcosystems"
                >
                    <template slot="option" slot-scope="props"><img :src="LogoHelper.getLogo(props.option.logo)" style="width:40px" alt=""> {{ props.option.name }}</template>
                </multiselect>
            </div>

        </template>

        <div class="form__item">
            <h6 class="heading6 mb-1">Assignee*</h6>
            <multiselect class="multiselect-mid mb-3"
                         v-model="user"
                         :options="usersList"
                         select-label=""
                         deselect-label=""
                         label="name"
                         track-by="name"
                         placeholder="Select a user"
            >
                <template slot="option" slot-scope="props">
                    <div class="d-flex align-items-center">
                        <div class="mr-2">
                            <Avatar
                                :username="props.option.name"
                                :src="props.option.avatar"
                                :size="30">
                            </Avatar>
                        </div>
                        {{ props.option.name }}
                    </div>
                </template>
            </multiselect>
        </div>

        <div class="form__item">
            <h6 class="heading6 mb-1">Due Date (optional)</h6>
            <date-picker v-model="date" placeholder="Select a date" format = "MM/DD/YYYY" class="mb-2 w-100"></date-picker>
        </div>

        <div class="form__item">
            <div class="input-checkbox mt-2">
                <input id="private" type="checkbox" v-model="task.private">
                <label for="private">Make private (Only you, the assignee and admins can view)</label>
            </div>
        </div>

        <template #modal-footer>
            <PrimaryButton
                :loading="loading"
                loading-caption="Saving..."
                :caption="id ? 'Update' : 'Add'"
                :disabled="!canSave"
                :data-track="id ? 'edit-task' : 'add-task'"
                @click="save"
            />
        </template>
    </b-modal>
</template>

<script>
import tasks from "@/services/tasks.service"
import multiselect from "vue-multiselect";
import startups from "@/services/startups.service";
import trackers from "@/services/trackers.service";
import ecosystems from "@/services/ecosystems.service";
import Avatar from "vue-avatar";
import DatePicker from "vue2-datepicker";
import debounce from "@/mixins/debounce";
import PrimaryButton from "@/components/ui/PrimaryButton.vue";

export default {
    props: {
        prefix: {
            type: String,
            default: "",
        },

        startupId: {
            type: Number,
            default: 0,
        },

        trackerId: {
            type: Number,
            default: 0,
        },

        ecosystemId: {
            type: Number,
            default: 0,
        },

        taskTitle: {
            type: String,
            default: "",
        }
    },

    mixins: [debounce],

    components: {
        PrimaryButton,
        multiselect,
        Avatar,
        DatePicker
    },

    data() {
        return {
            id: 0,
            type: 'startup',
            startup: null,
            tracker: null,
            ecosystem: null,
            user: null,
            date: null,
            startupsList: [],
            trackersList: [],
            ecosystemsList: [],
            usersList: [],
            lastAddedStartups: [],
            initialEcosystemsList: [],
            loadingStartupsList: false,
            showModal: false,
            loading: false,

            task: {
                title: "",
                startup_id: null,
                tracker_id: null,
                ecosystem_id: null,
                user_id: null,
                date: null,
                private: 0,
            },

            errors: {
                title: []
            },
        }
    },

    mounted() {
        this.getTrackers()

        if (this.$store.getters.isEnterprise) {
            this.getEcosystems(false);
        }

        startups.getLastAddedStartups().then(response => {
            this.lastAddedStartups = response.data
            this.startupsList = this.lastAddedStartups
        })
    },

    watch: {
        type() {
            if (this.type == 'startup') {
                this.tracker = null
            }
            else {
                this.startup = null
            }
        },

        startup() {
            if (this.startup) {
                this.task.startup_id = this.startup.id
            }
            else {
                this.task.startup_id = null
            }
        },

        tracker() {
            if (this.tracker) {
                this.task.tracker_id = this.tracker.id
            }
            else {
                this.task.tracker_id = null
            }

            this.getUsers()
        },

        ecosystem() {
            if (this.ecosystem) {
                this.task.ecosystem_id = this.ecosystem.id
            }
            else {
                this.task.ecosystem_id = null
            }
        },

        user() {
            if (this.user) {
                this.task.user_id = this.user.id
            }
            else {
                this.task.user_id = null
            }
        },

        date() {
            if (this.date) {
                this.task.date = this.dateHelper.encodeDateForServer(this.date)
            }
            else {
                this.task.date = null
            }
        },

        showModal(value) {
            if (!value) {
                this.$emit('closed');
            }
        }
    },

    computed: {
        title() {
            if (this.id) {
                return "Edit Task"
            } else {
                return "Add New Task"
            }
        },

        canSave() {
            return this.task.title.length
                && ((this.type === 'startup' && this.task.startup_id) || (this.type === 'tracker' && this.task.tracker_id) || (this.type == 'ecosystem' && this.task.ecosystem_id))
                && this.task.user_id
        }
    },

    methods: {
        getStartups(search) {
            this.debounce(() => {
                if (search.length) {
                    this.loadingStartupsList = true

                    startups.getList(search).then(response => {
                        this.startupsList = response.data
                    }).finally(() => {
                        this.loadingStartupsList = false
                    })
                } else {
                    this.startupsList = this.lastAddedStartups
                }
            })
        },

        getTrackers() {
            trackers.getList().then(response => {
                this.trackersList = response.data
                if (this.task.tracker_id) {
                    let index = this.arrayHelper.findIndexByColumnValue(this.trackersList, 'id', this.task.tracker_id)
                    if (index >= 0) {
                        this.tracker = this.trackersList[index]
                    }
                }
            })
        },

        getEcosystems(search) {
            ecosystems.getList(search).then(response => {
                this.ecosystemsList = response.data
            })
        },

        getUsers() {

            let trackerId = this.task.tracker_id ? this.task.tracker_id : 0

            tasks.getUsersList(trackerId).then(response => {
                this.usersList = []

                let data = response.data
                let index = this.arrayHelper.findIndexByColumnValue(data, 'id', this.$store.state.user.id)
                this.usersList.push(response.data[index])
                let hasCurrent = false

                data.forEach(item => {
                    if (item.id != this.$store.state.user.id) {
                        this.usersList.push(item)
                    }

                    if (typeof item['$isDisabled'] == "undefined" && item.id == this.task.user_id) {
                        this.user = item
                        hasCurrent = true
                    }
                })

                if (!hasCurrent && this.task.user_id) {
                    this.task.user_id = null;
                    this.user = null;
                }

            })
        },

        init() {
            this.task.title = this.taskTitle

            this.user = null
            this.date = null
            this.startup = null
            this.tracker = null
            this.ecosystem = null
            this.task.private = 0
            this.errors.title = ""

            if (this.startupId) {
                this.task.startup_id = this.startupId
            }

            if (this.trackerId) {
                this.type = 'tracker'
                this.task.tracker_id = this.trackerId
            }
            else if (this.ecosystemId) {
                this.type = 'ecosystem'
                this.task.ecosystem_id = this.ecosystemId
            }
            else {
                this.type = 'startup'
            }

            if (this.id) {
                tasks.getEdit(this.id).then(response => {
                    this.task = response.data

                    if (this.task.startup_id) {
                        startups.getStartupDataForSelect(this.task.startup_id).then(startup => {
                            if (startup.data) {
                                this.startup = startup.data
                            }
                        })

                        this.type = 'startup'
                    }

                    if (this.task.tracker_id) {
                        this.type = 'tracker'
                        if (this.trackersList.length) {
                            let index = this.arrayHelper.findIndexByColumnValue(this.trackersList, 'id', this.task.tracker_id)
                            if (index >= 0) {
                                this.tracker = this.trackersList[index]
                            }
                        }
                    }

                    if (this.task.ecosystem_id) {
                        this.type = 'ecosystem'
                        if (this.ecosystemsList.length) {
                            let index = this.arrayHelper.findIndexByColumnValue(this.ecosystemsList, 'id', this.task.ecosystem_id)
                            if (index >= 0) {
                                this.ecosystem = this.ecosystemsList[index]
                            }
                        }
                    }

                    if (this.usersList.length) {
                        let index = this.arrayHelper.findIndexByColumnValue(this.usersList, 'value', this.task.user_id)
                        if (index >= 0) {
                            this.user = this.usersList[index]
                        }
                    }

                    if (this.task.date) {
                        this.date = this.dateHelper.decodeDateFromServer(this.task.date)
                    }

                    this.getUsers()
                })
            }
            else {
                this.getUsers()
            }
        },

        open(id = 0) {

            if (this.$store.state.accountType != 'premium') {
                this.showNeedUpgrade('tasks')
            }
            else {
                this.id = id
                this.init()
                this.showModal = true;
            }
        },

        save() {
            this.loading = true;

            tasks.save(this.id, this.task).then((response) => {
                this.loading = false;

                if (Object.keys(response.data.errors).length) {
                    this.errors = response.data.errors
                }
                else {
                    this.showModal = false;
                    this.$emit('task-saved')

                    this.$store.commit('setNeedUpdateTasks', true);
                    this.$emit('saved');

                    this.$nextTick(() => {
                        this.$store.commit('setNeedUpdateTasks', false);
                    })
                }
            })
        },
    }
}
</script>

<style scoped>

</style>
