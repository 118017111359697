<script setup>
import Aside from "@/views/enterprise/home/aside/Aside.vue";
import Content from "@/views/enterprise/home/content/Content.vue";
import TopBar from "@/views/enterprise/home/topBar/TopBar.vue";
import {ref, computed, provide, reactive, watch} from "vue";
import constants from "@/constants/constants";
import EditTaskModal from "@/components/tasks/EditTask.vue";
import {useRoute} from "vue2-helpers/vue-router";
import {useStore} from "vue2-helpers/vuex";
import Helper from "@/components/Helper.vue";

const route = useRoute();
const store = useStore();

const filters = reactive({
    period: constants.commonFilterPeriods.find(item => item.value === 'all'),
    userType: constants.usersTypes.find(item => item.value === 'all'),
    trackers: [],
    tags: [],
    customFields: [],
});

const editTaskModal = ref(null);

const filtersForServer = computed(() => {
    let filter = {
        period: filters.period.value,
        userType: filters.userType.value,
    };

    if (filters.trackers.length) {
        filter.trackers = filters.trackers.map(item => item.id);
    }

    if (filters.tags.length) {
        filter.tags = filters.tags.map(item => item.id);
    }

    if (filters.customFields.length) {
        filter.fields = filters.customFields;
    }

    return filter;
});

watch([editTaskModal, () => store.getters.isLoggedIn], ([modalMounted, isLoggedIn]) => {
    if (modalMounted && isLoggedIn) {
        const openTaskId = route.query.open_task;

        if (openTaskId) {
            editTaskModal.value.open(openTaskId);
        }
    }
}, {immediate: true});

provide('filters', filters);
provide('filtersForServer', filtersForServer);
</script>

<template>
    <div class="dashboard">
        <TopBar/>

        <main class="main main--dark main--dashboard">
            <Content/>
            <Aside/>
        </main>

        <EditTaskModal
            ref="editTaskModal"
        />

        <Helper page="homepage"/>
    </div>
</template>

<style scoped>
</style>
