<template>
    <div>
        <b-modal :id="'modal-edit-user' + prefix" :title="title" modal-class="modal-user" size="md" @hide="beforeClose">

            <template #modal-title>
                <div class="d-flex align-items-center">
                    <h4 class="heading4 mb-0 mr-4">{{title}}</h4>
                    <b-form-checkbox v-if="!profile && ($store.state.user.permissions.indexOf('team-manage') >= 0 || !$store.getters.isEnterprise) && !user.is_main_admin" class="input-checkbox" v-model="user.isAdmin" value="1">Admin</b-form-checkbox>

                    <template v-if="!user.isAdmin && !profile && $store.state.companyType === 'enterprise'">
                        <b-form-checkbox class="input-checkbox ml-3" v-model="user.external" value="1">External</b-form-checkbox>
                        <div class="hint-secondary" v-b-popover.hover.top="{ customClass: 'hint-secondary-popover', content: 'This user will only see content shared explicitly with them or a group they have been added to. By default, they will not see content shared with all users in your company.' }"></div>
                    </template>
                </div>
            </template>

            <template v-if="!$store.getters.isCollinsEnterprise">
                <div class="d-flex align-items-center mb-1">
                    <div class="image-block image-block--rounded--lg mb-4">
                        <img v-if="user.avatar" :src="this.user.avatar" alt="img">
                    </div>
                    <div>
                        <a @click="showAvatarUpload = true" class="btn btn-primary">Upload Photo</a>
                        <p class="modal-text m-0">Image size: 130 X 130 pixels, max file size 5 mb</p>
                    </div>
                </div>

                <ImageUpload
                    v-model="showAvatarUpload"
                    :width="130"
                    :height="130"
                    :noSquare="true"
                    langType="en"
                    @crop-success="photoCropped"
                />
            </template>

            <h5 class="heading5 heading5--secondary">
                First Name*
            </h5>
            <div class="form form-modal mb-4">
                <b-form-input class="form-control form-control--mid" v-model="user.firstName" placeholder="Enter First Name"></b-form-input>
            </div>

            <h5 class="heading5 heading5--secondary">
                Last Name<span v-if="$store.getters.isStartup">*</span>
            </h5>
            <div class="form form-modal mb-4">
                <b-form-input class="form-control form-control--mid" v-model="user.lastName" placeholder="Enter Last Name"></b-form-input>
            </div>

            <h5 class="heading5 heading5--secondary">
                Title
            </h5>
            <div class="form form-modal mb-4">
                <b-form-input class="form-control form-control--mid" v-model="user.jobTitle" placeholder="Enter Title"></b-form-input>
            </div>
            <h5 class="heading5 heading5--secondary">
                Linkedin Profile URL
            </h5>
            <div class="form">
                <template v-if="typeof errors.linkedin_url !== 'undefined'">
                    <span class="form form__error" v-for="(error, index) in errors.linkedin_url" :key="index">{{error}}</span>
                </template>
            </div>
            <div class="form form-modal mb-4">
                <b-form-input class="form-control form-control--mid" v-model="user.linkedinUrl" placeholder="Enter Linkedin URL"></b-form-input>
            </div>

            <h5 class="heading5 heading5--secondary">
                Email*
            </h5>
            <div class="form">
                <template v-if="typeof errors.email !== 'undefined'">
                    <span class="form form__error" v-for="(error, index) in errors.email" :key="index">{{error}}</span>
                </template>
            </div>

            <div class="form form-modal mb-4">
                <b-form-input class="form-control form-control--mid" v-model="user.email" placeholder="Enter Email"></b-form-input>
            </div>

            <div class="form form-modal mb-4" v-if="profile && this.$store.state.user.company_id !== this.constants.companyPfizer && this.$store.state.user.company_id !== this.constants.companyColgate">
                <a @click="handleOpenChangePassword" class="link">Change Password</a>
            </div>

            <template v-if="!profile && $store.state.user.company_type === 'enterprise'">
                <h5 class="heading5 heading5--secondary">
                    Add To Group
                </h5>
                <div class="form form-modal mb-4">
                    <multiselect class="multiselect-mid pl-2"
                                 v-model="newGroup"
                                 :options="actualGroupsList"
                                 placeholder="Select Group"
                                 select-label=""
                                 deselect-label=""
                                 label="name"
                                 track-by="name"
                    >
                    </multiselect>
                </div>

                <div class="dropdown-simple-custom-wrapper">
                    <div v-for="(group, index) in selectedGroups" :key="index">
                        <div class="dropdown-simple-custom-wrapper__group">
                            <div class="dropdown-simple-custom-wrapper__title">
                                {{group.group.name}}<br/>
                                {{constants.groupRolesNames[group.role]}}
                            </div>

                            <b-dropdown class="drag-n-drop icons-list dropdown-simple" v-if="$store.state.user.permissions.indexOf('team-manage') >= 0 || ($store.state.user.admin_groups.includes(group.group.id) >= 0 && group.role !== 'admin')">
                                <template v-if="$store.state.user.permissions.indexOf('team-manage') >= 0">
                                    <b-dropdown-item v-if="group.role !== 'admin'" class="dropdown-simple-custom-wrapper__admin" @click="selectedGroups[index].role = 'admin'">
                                        Make group admin
                                    </b-dropdown-item>

                                    <b-dropdown-item v-else class="dropdown-simple-custom-wrapper__remove-admin" @click="selectedGroups[index].role = 'member'">
                                        Remove As Admin
                                    </b-dropdown-item>
                                </template>

                                <b-dropdown-item class="dropdown-simple-custom-wrapper__remove" @click="selectedGroups.splice(index, 1)">
                                    Remove from group
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </div>
                </div>
            </template>

            <template #modal-footer>
                <div class="buttons-wrapper">
                    <button class="btn btn-bordered btn-lg" v-if="id === 0 && !profile" @click="editTemplate">Customize Invite</button>

                    <primary-button
                        :disabled="!canSave"
                        caption="Save"
                        loading-caption="Saving..."
                        :loading="loading"
                        @click="save"
                    />
                </div>
            </template>

        </b-modal>

        <EditEmailTemplate
            ref="editEmailTemplate"
            :prefix = "prefix"
            v-on:saved="setTemplate"
            v-on:close="$bvModal.show('modal-edit-user' + prefix)"
        >
        </EditEmailTemplate>

        <change-password ref="changePassword"></change-password>
        <add-more-users v-if="$store.state.user.company_type === 'enterprise'"></add-more-users>
    </div>
</template>

<script>

import team from "@/services/team.service";
import user from "@/services/user.service";
import multiselect from "vue-multiselect";
import ImageUpload from 'vue-image-crop-upload';
import Vue from "vue";
import EditEmailTemplate from "@/components/settings/EditEmailTemplate";
import companyService from "@/services/company.service";
import ChangePassword from "@/components/team/ChangePassword";
import PrimaryButton from "@/components/ui/PrimaryButton.vue";

export default {
    props: {
        profile: {
            type: Boolean,
            default: false,
        },

        groupId: {
            type: Number,
            default: 0,
        },

        prefix: {
            type: String,
            default: "",
        }
    },

    components: {
        PrimaryButton,
        multiselect,
        ImageUpload,
        ChangePassword,
        EditEmailTemplate,
        AddMoreUsers: () => import("@/components/enterprise/AddMoreUsers"),
    },

    data() {
        return {
            user: {
                firstName: "",
                lastName: "",
                jobTitle: "",
                linkedinUrl: "",
                email: "",
                isAdmin: "",
                is_main_admin: "",
                avatar: "",
                external: "",
                groups: [],
                template: {
                    subject: "",
                    body: "",
                },
            },

            selectedGroups: [],
            newGroup: null,

            groupsList: [],

            id: 0,
            isAdmin: false,
            showAvatarUpload: false,

            avatarChanged: false,
            loading: false,

            errors: {},
        }
    },

    watch: {
        newGroup() {
            if (this.newGroup) {
                this.selectedGroups.push({
                    'group' : this.newGroup,
                    'role' : 'member',
                })

                this.newGroup = null
            }
        }
    },

    computed: {
        title() {
            if (this.profile) {
                return "Edit Profile"
            }
            else if (this.id) {
                return "Edit User"
            }
            else {
                if (this.isAdmin) {
                    return "Add Admin"
                }
                else {
                    return "Add User"
                }
            }
        },

        canSave() {
            return this.user.firstName && this.user.firstName.length && this.user.email.length && this.stringHelper.validEmail(this.user.email)
                && (!this.$store.getters.isStartup || (this.user.lastName && this.user.lastName.length))
        },

        actualGroupsList() {
            let array = []
            let existIds = []

            this.selectedGroups.forEach(item => {
                existIds.push(item.group.id)
            })

            this.groupsList.forEach(item => {
                if (existIds.indexOf(item.id) < 0 && (this.$store.state.user.permissions.indexOf('team-manage') >= 0 || this.$store.state.user.admin_groups.indexOf(item.id) >= 0)) {
                    array.push(item)
                }
            })

            return array
        }
    },

    mounted() {
        if (this.$store.state.user.company_type === 'enterprise') {
            team.getGroupsList().then(response => {
                this.groupsList = response.data
            })
        }
    },

    methods: {

        init() {
            this.user.firstName = ""
            this.user.lastName = ""
            this.user.jobTitle = ""
            this.user.linkedinUrl = ""
            this.user.email = ""
            this.user.avatar = ""
            this.avatarChanged = false
            this.errors = {}

            this.user.template.subject = ""
            this.user.template.body = ""
            this.user.external = ""

            if (this.groupId) {
                let index = this.arrayHelper.findIndexByColumnValue(this.groupsList, 'id', this.groupId)

                if (index >= 0) {
                    this.selectedGroups = [{
                        'group': this.groupsList[index],
                        'role': 'member',
                    }]
                }
            }
            else {
                this.selectedGroups = []
            }

            if (this.isAdmin) {
                this.user.isAdmin = 1
            }
            else {
                this.user.isAdmin = 0
            }

            if (this.profile) {
                user.getProfileEdit().then(response => {
                    this.user.firstName = response.data.first_name
                    this.user.lastName = response.data.last_name
                    this.user.email = response.data.email
                    this.user.jobTitle = response.data.job_title
                    this.user.linkedinUrl = response.data.linkedin_url
                    this.user.avatar = response.data.avatar

                    user.getCurrentUserData()
                        .then(response => {
                            this.$store.commit('setUser', response.data)
                        })
                })

            } else if (this.id) {
                team.getUserEdit(this.id).then(response => {
                    this.user.firstName = response.data.first_name
                    this.user.lastName = response.data.last_name
                    this.user.email = response.data.email
                    this.user.jobTitle = response.data.job_title
                    this.user.isAdmin = response.data.is_admin
                    this.user.avatar = response.data.avatar
                    this.user.is_main_admin = response.data.is_main_admin
                    this.user.external = response.data.external
                    this.selectedGroups = []

                    response.data.groups.forEach(group => {
                        let index = this.arrayHelper.findIndexByColumnValue(this.groupsList, 'id', group.id)

                        if (index >= 0) {
                            this.selectedGroups.push({
                                'group': this.groupsList[index],
                                'role': group.role,
                            })
                        }
                    })
                })
            }
        },

        beforeClose() {
            this.$emit('close')
        },

        open(id = 0, isAdmin = false) {
            this.id = id
            this.isAdmin = isAdmin

            if (this.profile || id) {
                this.showModal();
            } else {
                if (this.$store.state.companyType === 'ecosystem') {
                    team.canAddUser().then(response => {
                        if (response.data) {
                            this.showModal()
                        } else {
                            this.showNeedUpgrade('users')
                        }
                    })
                } else if (this.$store.state.companyType === 'enterprise' && (
                    this.$store.state.accountType === 'free' || this.$store.state.accountType === 'event_limited' || this.$store.state.maxUsers > 0)
                ) {
                    team.canAddUser().then(response => {
                        if (response.data) {
                            this.showModal()
                        } else {
                            this.showNeedUpgrade('users')
                        }
                    })
                } else {
                    this.showModal()
                }
            }
        },

        showModal() {
            this.init()
            this.$bvModal.show('modal-edit-user' + this.prefix)
        },

        save() {
            this.loading = true;
            if (this.avatarChanged) {
                this.user.newAvatar = this.user.avatar
            }

            if (this.profile) {
                user.saveProfile(this.user).then((response) => {
                    this.errors = response.data.errors

                    if (!Object.keys(this.errors).length) {
                        this.$emit('user-saved')
                        this.$bvModal.hide('modal-edit-user' + this.prefix)
                    }
                }).finally(() => {
                    this.loading = false;
                })
            }
            else {
                this.user.groups = [];

                this.selectedGroups.forEach(group => {
                    this.user.groups.push({
                        'id': group.group.id,
                        'role': group.role,
                    })
                })

                team.saveUser(this.id, this.user).then((response) => {
                    this.errors = response.data.errors

                    if (! Object.keys(this.errors).length) {
                        this.$bvModal.hide('modal-edit-user' + this.prefix)
                        this.$store.commit('setNeedUpdateUsers', true);

                        this.$nextTick(() => {
                            this.$store.commit('setNeedUpdateUsers', false);
                        })

                        if (response.data.message) {
                            Vue.notify({
                                group: 'app',
                                type: 'success',
                                duration: 5000,
                                text: response.data.message
                            })
                        }
                    }
                }).finally(() => {
                    this.loading = false;
                })
            }
        },

        photoCropped(imageDataUrl) {
            this.user.avatar = imageDataUrl
            this.showAvatarUpload = false
            this.avatarChanged = true
        },

        editTemplate() {
            this.$bvModal.hide('modal-edit-user' + this.prefix)

            if (!this.user.template.subject.length) {

                companyService.getEmailTemplateEdit('user-invite').then(response => {
                    this.user.template.subject = response.data.subject
                    this.user.template.body = response.data.body

                    this.$refs.editEmailTemplate.open(this.user.template.subject, this.user.template.body)
                })
            }
            else {
                this.$refs.editEmailTemplate.open(this.user.template.subject, this.user.template.body)
            }
        },

        setTemplate(template) {
            this.user.template = template
            this.$bvModal.show('modal-edit-user' + this.prefix)
        },

		handleOpenChangePassword() {
			this.$bvModal.hide(`modal-edit-user${this.prefix}`);
			this.$refs.changePassword.open(`modal-edit-user${this.prefix}`);
		}
    }
}
</script>
