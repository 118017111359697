<script setup>

import {ref} from "vue";
import awardsService from "@/services/awards.service";

const showModal = ref(false);
const awardId = ref(null);
const awardDetails = ref({});

async function getAwardDetails() {
    const {data} = await awardsService.getDetails(awardId.value);
    awardDetails.value = data;
}

function open(id) {
    awardDetails.value = {};
    showModal.value = true;
    awardId.value = id;
    getAwardDetails();
}

defineExpose({open});
</script>

<template>
    <b-modal title="Contract Details" v-model="showModal" hide-footer modal-class="modal-w-xl">
        <table class="mb-5">
            <tr v-for="(value, name) in awardDetails" :key="name">
                <th>{{name}}</th>
                <td v-html="value"/>
            </tr>
        </table>
    </b-modal>
</template>

<style scoped>
    td, th {
        padding: 5px;
        border: 1px solid #ccc;
    }

    th {
        min-width: 200px;
    }
</style>
