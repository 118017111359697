<script setup>
import {ref, inject, onMounted} from "vue";
import dashboardService from "@/services/dashboard.service";
import SearchesListModal from "@/views/enterprise/home/content/searches/SearchesListModal.vue";
import SearchesList from "@/views/enterprise/home/content/searches/SearchesList.vue";

const filters = inject('filters');
const allSearches = ref([]);
const searches = ref(null);
const searchesListModal = ref(null);

onMounted(() => {
    getSearches();
});

async function getSearches() {
    const {data} = await dashboardService.getWidgetData('SavedSearches', filters.value);

    allSearches.value = data;
    searches.value = allSearches.value.slice(0, 3);
}
</script>

<template>
    <section class="dashboard--searches">
        <div class="card card--bordered">
            <header class="dashboard__header">
                <h3 class="heading3">Saved Searches</h3>

                <a v-if="allSearches.length > 3" @click="searchesListModal.open" class="link link--view">Show more</a>
            </header>

            <template v-if="searches !== null">
                <SearchesList v-if="searches.length" :searches="searches"/>

                <div v-else>
                    <p>You have no saved searches.</p>
                    <p><a :href="'/startups'">Search for startups</a> and save your search to easily jump back in from here</p>
                </div>
            </template>

            <SearchesListModal ref="searchesListModal"/>
        </div>
    </section>
</template>

<style scoped>

</style>
