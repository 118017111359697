<script setup>
import Multiselect from "vue-multiselect";
import {computed, inject} from "vue";
import {useStore} from "vue2-helpers/vuex";
import constants from "@/constants/constants";
import {customCategories} from "@/constants/custom/coPaceTrackerCategories";

const store = useStore();
const tracker = inject('tracker');
const canEditInfo = inject('canEditInfo');

const categoriesList = computed(() => store.state.user.company_id === constants.companyCoPace ? store.state.categories.filter(category => customCategories.some(categoryId => category.id === categoryId)) : store.state.categories);
</script>

<template>
    <div>
        <h5 class="heading5 heading5--secondary">Categories</h5>
        <div class="mb-4">
            <Multiselect
                v-model="tracker.categories"
                class="multiselect-mid multiselect-modern"
                :options="categoriesList"
                :multiple="true"
                select-label=""
                deselect-label=""
                :disabled="!canEditInfo"
                label="name"
                track-by="name"
            />
        </div>
    </div>
</template>

<style scoped>

</style>
