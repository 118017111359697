<script setup>
import startupsService from "@/services/startups.service";
import {inject, onMounted, ref} from "vue";
import ContractModal from "@/views/profile/ContractModal.vue";

const companyId = inject('companyId');
const awards = ref([]);
const contractModal = ref(null);

onMounted(() => {
    getAwards();
});

async function getAwards() {
    const {data} = await startupsService.getAwards(companyId);
    awards.value = data;
}
</script>

<template>
    <div>
        <div v-for="award in awards" :key="award.id" class="main-startup__tabs__item main-startup__tabs__item--contracts">
            <div class="main-startup__tabs__item__inner">
                <a @click="contractModal.open(award.id)">
                    <h5 class="heading5">{{ award.title }}</h5>
                </a>

                <p class="paragraph-light">Awarded {{ award.date }} by {{ award.agency }}</p>

                <p v-html="award.description"></p>
            </div>
        </div>

        <ContractModal ref="contractModal"/>
    </div>
</template>

<style scoped>

</style>
