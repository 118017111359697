import { render, staticRenderFns } from "./Home_old.vue?vue&type=template&id=3ef80ad7&scoped=true&"
import script from "./Home_old.vue?vue&type=script&lang=js&"
export * from "./Home_old.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ef80ad7",
  null
  
)

export default component.exports