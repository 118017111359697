<script setup>
import {ref, computed, inject, onMounted, watch, nextTick} from "vue";
import constants from "@/constants/constants";
import {useStore} from "vue2-helpers/vuex";
import CustomizeHomepage from "@/views/enterprise/home/topBar/customize/CustomizeHomepage.vue";
import Filters from "@/views/enterprise/home/topBar/Filters.vue";
import EditPresetModal from "@/views/enterprise/home/topBar/EditPresetModal.vue";
import savedFiltersService from "@/services/saved-filters.service";
import ManagePresetsModal from "@/views/enterprise/home/topBar/ManagePresetsModal.vue";

const store = useStore();
const filters = inject('filters');

const savedFilter = ref(null);
const customizeHomepageModal = ref(null);
const savePresetModal = ref(null);
const filtersModal = ref(null);
const managePresetsModal = ref(null);
const showSaveFilter = ref(false);
const watchFilterChange = ref(true);
const showPresets = ref(true);
const updateDefaultFilter = ref(true);
const savedFilters = ref([]);

const periodLabel = computed(() => constants.commonFilterPeriods.find(item => item.value === filters.period.value).label);
const userTypeLabel = computed(() => constants.usersTypes.find(item => item.value === filters.userType.value).label);
const loadPresetLabel = computed(() => savedFilter.value ? savedFilter.value.name : 'Load Preset');
const trackersList = computed(() => store.getters.foldersTrackersList.reduce((trackers, group) => [...trackers, ...group[1]], []));

watch(() => filters, () => {
    if (watchFilterChange.value) {
        showSaveFilter.value = true;
        showPresets.value = false;
    }
}, {deep: true});

watch(savedFilter, (value) => {
    if (value) {
        applySavedFilter(value);
    }
}, {deep: true});

watch([() => store.getters.foldersTrackersLoaded, () => store.getters.tagsLoaded], ([trackersLoaded, tagsLoaded]) => {
    if (trackersLoaded && tagsLoaded) {
        getPresets(0 ,true);
    }
}, {immediate: true});

onMounted(() => {
    store.dispatch('fetchFoldersTrackers');
    store.dispatch('fetchTags');
});

function editFilters() {
    filtersModal.value.open();
}

function openSavePreset() {
    savePresetModal.value.open();
}

function openManagePresets() {
    managePresetsModal.value.open();
}

function presetCreated(id) {
    getPresets(id);
}

function resetFilters() {
    watchFilterChange.value = false;

    filters.period = constants.commonFilterPeriods.find(item => item.value === 'all');
    filters.userType = constants.usersTypes.find(item => item.value === 'all');
    filters.trackers = [];
    filters.tags = [];
    filters.customFields = [];

    savedFilter.value = null;
    showSaveFilter.value = false;

    savedFiltersService.updateDefault(null);

    nextTick(() => {
        watchFilterChange.value = true;
        showPresets.value = true;
    });
}

function applySavedFilter() {
    showSaveFilter.value = false;
    watchFilterChange.value = false;

    let filter = JSON.parse(savedFilter.value.filter);

    filters.period = constants.commonFilterPeriods.find(item => item.value === filter.period);
    filters.userType = constants.usersTypes.find(item => item.value === filter.userType);

    if ('trackers' in filter) {
        filters.trackers = trackersList.value.filter(item => filter.trackers.includes(item.id));
    } else {
        filters.trackers = [];
    }

    if ('tags' in filter) {
        filters.tags = store.getters.tagsList.filter(item => filter.tags.includes(item.id))
    } else {
        filters.tags = [];
    }

    if ('fields' in filter) {
        filters.customFields = filter.fields;
    } else {
        filters.customFields = [];
    }

    if (updateDefaultFilter.value) {
        savedFiltersService.updateDefault(savedFilter.value.id);
    } else {
        updateDefaultFilter.value = true;
    }

    nextTick(() => {
        watchFilterChange.value = true;
    })
}

async function getPresets(currentPresetId = 0, loadDefault = false) {
    const {data} = await savedFiltersService.getList();

    savedFilters.value = data.map(group => group.items).flat();

    if (savedFilters.value.length) {
        if (currentPresetId) {
            savedFilter.value = savedFilters.value.find(item => item.id === currentPresetId);
        } else if (loadDefault) {
            let defaultFilter = savedFilters.value.find(item => item.is_default);

            if (defaultFilter) {
                savedFilter.value = defaultFilter;
                updateDefaultFilter.value = false;
            }
        } else if (savedFilter.value) {
            let saved = savedFilters.value.find(item => item.id === savedFilter.value.id);

            if (saved) {
                savedFilter.value = saved
            } else {
                resetFilters()
            }
        }

        showPresets.value = true;
    }
}
</script>

<template>
    <div class="topbar topbar__dashboard">
        <div class="topbar__dashboard__inner">
            <div class="row align-items-baseline">
                <div class="col-12 d-flex flex-wrap">
                    <div class="bullet-section">
                        <a @click="editFilters">
                            <span class="bullet bullet--period">
                                {{periodLabel}}
                            </span>
                        </a>

                        <a @click="editFilters">
                            <span class="bullet bullet--users">
                                {{userTypeLabel}}
                            </span>
                        </a>

                        <a @click="editFilters" v-for="tracker in filters.trackers" :key="tracker.id">
                            <span class="bullet bullet--tracker">
                                {{tracker.name}}
                            </span>
                        </a>

                        <a @click="editFilters" v-for="tag in filters.tags" :key="tag.id">
                            <span class="bullet bullet--tag">
                                {{tag.name}}
                            </span>
                        </a>

                        <a @click="editFilters">
                            <span class="bullet bullet--fields" v-if="filters.customFields.length">
                                {{filters.customFields.length}} selected
                            </span>
                        </a>
                    </div>

                    <ul class="topbar__side actions-list topbar__side--home">
                        <li class="topbar__side__actions--filter"><a @click="editFilters">Edit Filters</a></li>
                        <li v-if="showSaveFilter"><a class="topbar__side__save" @click="openSavePreset">Save As Preset</a></li>

                        <template v-if="showPresets">
                            <li>
                                <b-dropdown v-if="savedFilters.length" :text="loadPresetLabel" class="topbar__side__actions topbar__presets">
                                    <b-dropdown-item v-if="savedFilter" @click="resetFilters" class="default-preset">
                                        Reset To Default
                                    </b-dropdown-item>

                                    <b-dropdown-item v-for="(item, index) in savedFilters" @click="savedFilter = item" :key="index">
                                        {{item.name}}
                                    </b-dropdown-item>

                                    <b-dropdown-item class="manage-presets" @click="openManagePresets">
                                        Manage Saved Presets
                                    </b-dropdown-item>
                                </b-dropdown>
                            </li>

                            <li v-if="store.state.user.is_admin">
                                <a @click="customizeHomepageModal.open()" class="link link--dashboard">Customize Homepage</a>
                            </li>
                        </template>

                        <template v-else>
                            <li>
                                <a @click="resetFilters" class="topbar__side__clear">Clear Selected</a>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
        </div>

        <EditPresetModal
            ref="savePresetModal"
            :filter="filters"
            @created="presetCreated"
        />

        <ManagePresetsModal
            ref="managePresetsModal"
            @presetsChanged="getPresets"
        />

        <Filters ref="filtersModal"/>
        <CustomizeHomepage ref="customizeHomepageModal"/>
    </div>
</template>

<style scoped>

</style>
