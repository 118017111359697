<template>
    <div class="mystartups-page">
        <header class="topbar mystartups-page__header">
            <startups-header></startups-header>
            <input
                type="text"
                class="form-search-primary"
                placeholder="Search Startups"
                v-debounce:500ms.lock="applySearch"
                :disabled="loading"
            >
            <div class="topbar__side actions-list">
                <b-dropdown id="dropdown-show" :text="inviteStatuses[inviteFilter]" class="topbar__side__actions topbar__side__actions--filter">
                    <b-dropdown-item v-for="(label, name) in inviteStatuses" :key="name" @click="() => {inviteFilter = name}">
                        {{label}}
                    </b-dropdown-item>
                </b-dropdown>

                <a href="javascript:void(0)" class="link-wrapper ml-4">
                    <span class="link-wrapper__add" @click="addStartup">Add Startups</span>
                </a>
            </div>
        </header>
        
        <main class="main main--dark">
            <div class="main__inner">

                <div class="showing-select">
                    Showing {{totalRowsCount}} startups
                </div>
                
                <ul class="list-cards" v-infinite-scroll="loadMore">
                    <li v-for="startup in startups" :key="startup.id">
                        <div class="image-block">
                            <img :src="LogoHelper.getLogo(startup.name.logoUrl)" :alt="startup.name.name">
                        </div>
                        <div class="list-cards__content">
                            <router-link class="heading5 heading5--tertiary" :to="`/startups/${startup.name.id}`">
                                {{ startup.name.name }}
                            </router-link>
                            
                            <p>{{ startup.name.shortDescription }}</p>

                            <ul class="list-stat">
                                <li class="list-stat__funding" v-if="startup.total_raised">{{ startup.total_raised }}</li>
                                <li class="list-stat__location" v-if="startup.location.country">{{ startup.location.country }}<span v-if="startup.location.region">, {{ startup.location.region }}</span></li>
                                <li class="list-stat__location" v-if="!startup.location.country && startup.location.region">{{ startup.location.region }}</li>
                                <li class="list-stat__startups" v-if="startup.stage">{{ startup.stage }}</li>
                            </ul>
                        </div>
                        <a class="invite-info" href="javascript:void(0)" v-if="startup.status == 'invite'" @click="$refs.invite.open(startup.id)">
                            <span class="invite-info__invited">{{ startup.status }}</span>
                        </a>
                        <p class="invite-info" v-if="startup.status != 'invite'">{{ startup.status }}</p>
                        <b-dropdown right text="Actions" class="list-cards__actions icons-list dropdown-simple dropdown-small" :disabled="removing">
                            <b-dropdown-item @click="removeStartup(startup.id)" class="icons-list__delete">
                                Remove startup
                            </b-dropdown-item>
                            <b-dropdown-item @click="addToPortfolio(startup.id)" class="icons-list__add">Add to a portfolio</b-dropdown-item>
                        </b-dropdown>
                    </li>
                </ul>

                <div class="loading__spinner">
                    <span v-if="loadingMore"><b-spinner class="mr-1" small></b-spinner>Loading...</span>
                </div>
            </div>
        </main>

        <add-startups-to-portfolio
            ref="addStartupsToPortfolio"
            :startups="selectedStartups">
        </add-startups-to-portfolio>

        <Invite
            ref="invite"
            v-on:invited="getList"
        >
        </Invite>
    </div>
</template>

<script>
import grid from "@/mixins/grid";
import startups from "@/services/ecosystem/startups.service";
import addStartupsToPortfolio from "@/components/startups/AddToPortfolios";
import StartupsHeader from "@/components/ecosystem/StartupsHeader.vue";
import Invite from "@/components/startups/InviteStartup";
import ecosystemStatups from "@/services/ecosystem/startups.service";
import {inviteStatuses} from "@/constants/constants";

export default {
    mixins: [grid],

    components: {
        addStartupsToPortfolio,
        StartupsHeader,
        Invite,
    },

    data() {
        return {
            search: '',
            loading: false,
            startups: [],
            currentPage: 1,
            loadingMore: false,
            inviteFilter: 'all',

            columns: [
                {
                    label: 'Name',
                    name: 'name',
                    component: 'SearchCompanyNameColumn',
                },
                {
                    label: 'Stage',
                    name: 'stage',
                },
                {
                    label: 'Location',
                    name: 'location',
                    component: 'SearchCompanyLocationColumn',
                },
                {
                    label: 'Total Raised',
                    name: 'total_raised',
                },
                {
                    label: 'Status',
                    name: 'status',
                    component: 'InviteStatusColumn',
                },
                {
                    name: 'actions',
                    label: 'Actions',
                    component: 'Actions',
                }
            ],
            selectedStartups: [],
            removing: false
        }
    },

    watch: {
        search() {
            this.getList();
        },

        inviteFilter() {
            this.getList();
        },
    },

    computed: {
        inviteStatuses() {
            return inviteStatuses
        },
    },

    mounted() {
        this.getList()

        this.$store.watch(state => {
            if (state.needUpdateStartups) {
                this.currentPage = 1
                this.getList()
            }
        })
    },

    methods: {
        async fetchData() {
            const columns = this.arrayHelper.arrayColumn(this.columns, 'name')
            const currentPage = this.currentPage;
            const pageLength = this.pageLength;
            const sort = this.sort;
            const filters = {
                invite_status: this.inviteFilter,
                query: this.search
            };

            const response = await startups.getStartups({ columns, sort, currentPage, pageLength, filters });
            return response.data;
        },
        async getList() {
            try {
                this.loading = true;

                const { data, total_count, current_page } = await this.fetchData();

                this.loading = false;
                this.startups = data;
                this.currentPage = current_page;
                this.totalRowsCount = +total_count;
            } catch (e) {
                this.loading = false;
                console.error(e);
            }
        },
        async loadMore(startLoading) {
            try {
                if (startLoading && !this.loadingMore && this.totalRowsCount > (this.currentPage * this.pageLength)) {
                    this.loadingMore = true;

                    this.currentPage = this.currentPage + 1;
                    const { data, total_count } = await this.fetchData();

                    this.startups = [...this.startups, ...data];
                    this.loadingMore = false;
                    this.totalRowsCount = +total_count;
                }
            } catch (e) {
                this.loadingMore = false;
                console.error(e);
            }
        },
        async removeStartup(startupId) {
            if (confirm("Remove startup?")) {
                try {
                    this.removing = true
                    await startups.deleteStartup(startupId)
                    let index = this.arrayHelper.findIndexByColumnValue(this.startups, 'id', startupId)
                    if (index >= 0) {
                        this.startups.splice(index, 1)
                        this.totalRowsCount--
                    }
                    this.removing = false
                } catch (e) {
                    console.error(e);
                    this.removing = false
                }
            }
        },
        addToPortfolio(startupId) {
            this.selectedStartups = [startupId];
            this.$refs.addStartupsToPortfolio.open(startupId);
        },

        gridAction(params) {

            if (params.action == 'invite') {

                if (this.$store.state.user.account_type == 'premium') {
                    this.$refs.invite.open(params.rowId)
                }
                else if (this.$store.state.user.account_type == 'invited') {
                    this.showNeedUpgrade('startups-invitations')
                }
                else {
                    ecosystemStatups.getCounts().then(response => {
                        if (response.data.added < this.constants.ecosystemLimits['startups-invite'][this.$store.state.user.account_type]) {
                            this.$refs.invite.open(params.rowId)
                        }
                        else {
                            this.showNeedUpgrade('startups-invitations')
                        }
                    })
                }
            }
        },

        addStartup() {
            this.$store.commit('setAddStartup', true);
            this.$nextTick(() => {
                this.$store.commit('setAddStartup', false);
            })
        },

        applySearch(search) {
            this.search = search;
        }
    }
}
</script>

<style scoped>

</style>
