import axios from '@/axios';

const RESOURCE_NAME = '/trackers-reports';

export default {
    getInsightsReport(id) {
        return axios.get(`${RESOURCE_NAME}/get-insights-report?id=${id}`,{
            responseType: 'blob',
        })
    },

    getDeepDiveReport(id) {
        return axios.get(`${RESOURCE_NAME}/get-deep-dive-report?id=${id}`,{
            responseType: 'blob',
        })
    },

    getCompareReport(id) {
        return axios.get(`${RESOURCE_NAME}/get-compare-report?id=${id}`,{
            responseType: 'blob',
        })
    },

    requestComparisonReport(id, startupsIds, features) {
        return axios.post(`${RESOURCE_NAME}/request-comparison-report?id=${id}`, {
            startups_ids: startupsIds,
            features: features
        });
    },

    saveComparisonReportEmail(reportId, email) {
        return axios.post(`${RESOURCE_NAME}/save-comparison-report-email`, {reportId, email});
    },

    getComparisonReportData(logId) {
        return axios.get(`${RESOURCE_NAME}/get-comparison-report-data?log_id=${logId}`);
    },

    getRecommendedFeatures(startupsIds) {
        return axios.post(`${RESOURCE_NAME}/get-recommended-features`, {
            startups_ids: startupsIds,
        });
    },

    getCustomFields(id) {
        return axios.get(`${RESOURCE_NAME}/get-custom-fields?id=${id}`);
    },

    downloadReport(logId) {
        return axios.get(`${RESOURCE_NAME}/download-report?log_id=${logId}`,{
            responseType: 'blob',
        })
    }
}
