import enterprisesService from "@/services/ecosystem/enterprises.service";

export default {
    state: {
        eventEnterprises: [],
        eventEnterprisesLoaded: false,
    },

    actions: {
        async fetchEventEnterprises({state}) {
            if (!state.eventEnterprisesLoaded) {
                const response = await enterprisesService.getEventEnterprises();
                state.eventEnterprises = response.data
                state.eventEnterprisesLoaded = true;
            }
        }
    },

    getters: {
        eventEnterprises: state => state.eventEnterprises
    }
}
