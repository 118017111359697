<script setup>
import {provide, reactive, ref, watch} from "vue";
import UsersActivities from "@/views/enterprise/home/aside/updates/users/UsersActivities.vue";
import UserUpdatesFilter from "@/views/enterprise/home/aside/updates/users/UserActivityFilter.vue";
import {hasParentWithClass} from "@/helpers/functions";
import StartupsUpdates from "@/views/enterprise/home/aside/updates/startups/StartupsUpdates.vue";
import FilterByTrackers from "@/components/widgets/FilterByTrackers.vue";

const currentTab = ref('users');
const showActivitiesFilters = ref(false);
const showActivitiesFiltersLink = ref();
const hasStartupUpdates = ref(true);

const activitiesFilters = reactive({
    activityTypes: {
        notes: false,
        contacts: false,
        files: false,
        interactions: false,
    },

    users: [],
    groups: [],
    trackers: [],
    period: [null, null],
    tags: [],
});

const startupsFilters = reactive({
    trackers: [],
});

watch(hasStartupUpdates, (value) => {
    if (!value) {
        currentTab.value = 'users';
    }
});

function clickedOutsideMenu(e) {
    if (e.target !== showActivitiesFiltersLink.value && !hasParentWithClass(e.target, 'mx-datepicker-popup')) {
        showActivitiesFilters.value = false;
    }
}

function trackersFilterChanged(trackers) {
    startupsFilters.trackers = trackers;
}

provide('activitiesFilters', activitiesFilters);
provide('startupsFilters', startupsFilters);
</script>

<template>
    <div class="updates">
        <div class="updates-header">
            <h3 class="heading3">Updates</h3>

            <div>
                <a
                    v-if="currentTab === 'users'"
                    ref="showActivitiesFiltersLink"
                    @click="showActivitiesFilters = !showActivitiesFilters"
                >
                    Select Activity Types
                </a>

                <FilterByTrackers
                    v-if="currentTab === 'startups'"
                    @changed="trackersFilterChanged"
                />
            </div>
        </div>

        <div class="updates-body">
            <div class="updates-backdrop" :class="{visible: showActivitiesFilters}"/>

            <div class="position-relative">
                <div
                    v-show="showActivitiesFilters"
                    class="updates-filters"
                    v-click-outside="clickedOutsideMenu"
                >
                    <div class="title">
                        <div>
                            View Activity
                        </div>

                        <a @click="showActivitiesFilters = false">
                            <span class="close"></span>
                        </a>
                    </div>

                    <UserUpdatesFilter/>
                </div>

                <div v-if="hasStartupUpdates" class="updates-tabs">
                    <div class="updates-tabs-inner">
                        <div @click="currentTab = 'users'">
                            <div class="updates-tab-content" :class="{'active': currentTab === 'users'}">
                                User Activity
                            </div>
                        </div>

                        <div @click="currentTab = 'startups'">
                            <div class="updates-tab-content" :class="{'active': currentTab === 'startups'}">
                                Startup Updates
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="updates-content">
                <UsersActivities v-show="currentTab === 'users'"/>

                <StartupsUpdates
                    v-show="currentTab === 'startups'"
                    @hideStartupUpdates="hasStartupUpdates = false"
                />
            </div>
        </div>
    </div>
</template>

<style scoped>
</style>
