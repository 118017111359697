<script setup>
import {computed, inject, onMounted, ref, watch} from "vue";
import useGrid from "@/composables/useGrid";
import useCurrentPageData from "@/composables/useCurrentPageData";
import TablePager from "@/components/TablePager.vue";
import Avatar from "vue-avatar";
import constants from "@/constants/constants";
import tasksService from "@/services/tasks.service";
import {useStore} from "vue2-helpers/vuex";
import EditTaskModal from "@/components/tasks/EditTask.vue";
import fileHelper from "@/helpers/file";
import AccountTypes from "@/constants/accountTypes";
import {getLogo} from "@/helpers/logo";

const {fromModal = false} = defineProps(['fromModal']);
const emit = defineEmits(['closeButtonClicked','innerModalOpened','innerModalClosed','tasksChanged']);

const store = useStore();
const {gridParams, gridMethods} = useGrid(getTasks, 5);
const getPageItems = useCurrentPageData();
const companyType = inject('companyType', null);
const companyId = inject('companyId', 0);
const trackerId = inject('trackerId', 0);
const getTasksIndexList = inject('getTasksIndexList');
const initListType = inject('tasksListType');
const tasksCount = inject('tasksCount');

const tasks = ref([]);
const listLoaded = ref(false);
const listType = ref(initListType);
const search = ref("");
const openTypeSelect = ref(false);
const showCompleted = ref(false);

const editTaskModal = ref(null);

const taskListTypes = computed(() => {
    let list = {
        my: 'Me',
    }

    if (store.state.user.is_admin) {
        store.getters.groupsList.forEach(item => {
            list['group-' + item.id] = item.name
        })

        list['all'] = 'Everyone'
    } else {
        list['team'] = 'Teams'
    }

    return list

})

watch([listType, showCompleted], getTasks);

watch(() => store.state.needUpdateTasks, (value) => {
    if (value) {
        getTasks();
    }
});

onMounted(() => {
    store.dispatch('fetchGroups');
});

function getFilteredTasksList(pageParams) {
    return getTasksIndexList(listType.value, {
        forExport: false,
        search: search.value,
        showCompleted: showCompleted.value
    }, pageParams);
}

async function getTasks() {
    tasks.value = await getPageItems(getFilteredTasksList, gridParams);
    listLoaded.value = true;
}

async function exportTasks() {
    const {data} = await getTasksIndexList(listType.value, {
        forExport: true,
        search: search.value,
        showCompleted: showCompleted.value
    });

    fileHelper.download(data, 'tasks.csv');
}

function taskChanged() {
    getTasks();
    emit('tasksChanged');
}

async function changeStatus(taskId, status) {
    await tasksService.update(taskId, {status: status});
    await getTasks();
}

function addTask() {
    emit('innerModalOpened');
    editTaskModal.value.open();
}

function editTask(id) {
    emit('innerModalOpened');
    editTaskModal.value.open(id);
}

function editTaskClosed() {
    emit('innerModalClosed');
}

async function removeTask(id) {
    if (confirm('Remove task?')) {
        await tasksService.delete(id);
        emit('tasksChanged');
        await getTasks();
    }
}

defineExpose({open, getTasks});

</script>

<template>
    <section class="dashboard--tasks">
        <div class="card card--bordered card--dashboard">
            <header v-if="tasksCount" class="dashboard__header">
                <h3 class="heading3">Tasks Assigned to
                    <div
                        v-click-outside="() => openTypeSelect = false"
                        class="tasks__select"
                    >
                        <div
                            @click="openTypeSelect = true"
                            class="tasks__select__chosen"
                        >
                            <span :title="taskListTypes[listType]">
                                {{taskListTypes[listType]}}
                            </span>
                        </div>

                        <ul v-show="openTypeSelect" class="multiselect__content">
                            <li
                                v-for="(name, value) in taskListTypes"
                                :key="value"
                                @click="listType = value; openTypeSelect = false"
                                class="multiselect__element"
                            >
                                {{name}}
                            </li>
                        </ul>
                    </div>
                </h3>

                <input
                    v-model="search"
                    type="text"
                    class="form-search-primary"
                    placeholder="Search"
                    v-debounce:500ms.lock="getTasks"
                />

                <button v-if="fromModal" @click="emit('closeButtonClicked')" type="button" aria-label="Close" class="close">×</button>
            </header>

            <template v-if="tasksCount">
                <div class="dashboard--tasks__controls">
                    <a @click="addTask" class="dashboard--tasks__add">Add Task</a>
                    <a  v-if="tasks.length" @click="exportTasks" class="dashboard--tasks__export">Export</a>
                    <a v-if="!showCompleted" @click="showCompleted = true" class="dashboard--tasks__show">Show completed</a>
                    <a v-if="showCompleted" @click="showCompleted = false" class="dashboard--tasks__hide">Hide completed</a>
                </div>

                <template v-if="tasks.length">
                    <ul class="list-bordered list-bordered--tasks">
                        <li v-for="task in tasks" :key="task.id">
                            <div class="list-bordered__inner">
                                <div class="image-block image-block--34 image-block--34--rounded">
                                    <Avatar
                                        :src="task.assignee.avatar"
                                        :username="task.assignee.name"
                                        :size="34"
                                    />
                                </div>

                                <p>{{task.title}}</p>

                                <select
                                    v-if="task.status.can_edit"
                                    class="select-status button-light"
                                    :value="task.status.status"
                                    :class="{
                                        'select-status-open': task.status.status === 'open',
                                        'select-status-blocked': task.status.status === 'blocked',
                                        'select-status-done': task.status.status === 'done',
                                        'select-status-progress': task.status.status === 'in_progress'
                                    }"
                                    @change="(e) => changeStatus(task.id, e.target.value)"
                                >
                                    <option
                                        v-for="(name, statusId) in constants.tasksStatus"
                                        :key="statusId"
                                        :value="statusId"
                                    >
                                        {{name}}
                                    </option>
                                </select>

                                <div v-if="task.due" class="button-light button-light--time" :class="{'button-light--urgent': task.urgent}">{{task.due}}</div>

                                <a v-if="'type' in task" :href="task.object_url" target="_blank">
                                    <div v-if="task.type === 'tracker'" class="icon-type icon-type--tracker" :title="task.object_name"></div>

                                    <div v-else class="image-block image-block--34 no-border">
                                        <img :src="getLogo(task.object_logo)" :alt="task.object_name">
                                    </div>
                                </a>

                                <div v-else class="image-block image-block--34 no-border"/>
                            </div>

                            <div class="list-bordered__dropdown">
                                <b-dropdown v-if="task.can_edit" class="dropdown dropdown-original dropdown-original--middle icons-list dropdown-small" boundary="viewport">
                                    <b-dropdown-item @click="editTask(task.id)" class="icons-list__edit">
                                        Edit Task
                                    </b-dropdown-item>

                                    <b-dropdown-item @click="removeTask(task.id)" class="icons-list__delete">
                                        Remove Task
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </li>
                    </ul>

                    <!--div>
                        <div v-for="task in tasks" :key="task.id" class="modal-manage__task-wrapper">
                            <div class="modal-manage__task">
                                <Avatar
                                    :src="task.assignee.avatar"
                                    :username="task.assignee.name"
                                    :size="41"
                                />
                                <div class="modal-manage__task__text">{{task.title}}</div>
                                <div class="button-light" :class="`button-light--${task.type}`" v-html="task.object"></div>
                                <div v-if="task.due" class="button-light button-light--time" :class="{'button-light--urgent': task.urgent}">{{task.due}}</div>
                                <div>
                                    <select
                                        v-if="task.status.can_edit"
                                        class="select-status button-light"
                                        :value="task.status.status"
                                        :class="{
                                            'select-status-open': task.status.status === 'open',
                                            'select-status-blocked': task.status.status === 'blocked',
                                            'select-status-done': task.status.status === 'done',
                                            'select-status-progress': task.status.status === 'in_progress'
                                        }"
                                        @change="(e) => changeStatus(task.id, e.target.value)"
                                    >
                                        <option
                                            v-for="(name, statusId) in constants.tasksStatus"
                                            :key="statusId"
                                            :value="statusId"
                                        >
                                            {{name}}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="modal-manage__task__dropdown">
                                <b-dropdown v-if="task.can_edit" class="dropdown-original icons-list dropdown-simple dropdown-small" boundary="viewport">
                                    <b-dropdown-item class="icons-list__edit" @click="editTask(task.id)">
                                        Edit Task
                                    </b-dropdown-item>

                                    <b-dropdown-item class="icons-list__delete" @click="removeTask(task.id)">
                                        Remove Task
                                    </b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </div>
                    </div-->

                    <TablePager
                        v-if="tasks.length"
                        :pages-count="gridParams.pagesCount"
                        :init-page="gridParams.currentPage"
                        @changePage="gridMethods.changePage"
                    />
                </template>
            </template>

            <div v-if="!tasks.length && listLoaded" class="dashboard__content dashboard__content--empty py-4 text-center">
                <h4 class="heading4 mb-4">Create A Task</h4>
                <p>Assign tasks to teammates (or yourself) to keep track of action items.</p>
                <b-button @click="addTask" class="btn-primary btn-lg">+ Add A Task</b-button>
            </div>

            <EditTaskModal
                ref="editTaskModal"
                :startup-id="companyType === AccountTypes.STARTUP ? companyId : 0"
                :ecosystem-id="companyType === AccountTypes.ECOSYSTEM ? companyId : 0"
                :tracker-id="trackerId"
                @saved="taskChanged"
                @closed="editTaskClosed"
            />
        </div>
    </section>
</template>

<style scoped>

</style>
