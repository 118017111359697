<template>
    <div>
        <highcharts
            v-if="loaded"
            :options="getOptions(chartOptions)"
            ref="chart"
        />

        <div v-else class="d-flex justify-content-center">
            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
        </div>
    </div>
</template>

<script>
    import {Chart} from 'highcharts-vue'
    import exportingInit from "highcharts/modules/exporting"
    import Highcharts from "highcharts"
    import dashboardChartMixin from '@/mixins/dashboardChartMixin'

    exportingInit(Highcharts)

    export default {
        name: 'StartupsCategoryChart',
        mixins: [dashboardChartMixin],
        components: {
            highcharts: Chart
        },
        data() {
            return {
                loaded: false,
                chartName: "StartupsBySource",
                chartData: [],
                chartOptions: {
                    chart: {
                        type: "column",
                        style: {
                            fontFamily: '"Overpass", sans-serif;',
                        },
                    },
                    title: {
                        text: "Startups By Source"
                    },
                    xAxis: {
                        categories: [],
                        title: {
                            text: null
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: "Number of startups",
                            align: "high"
                        },
                        labels: {
                            overflow: "justify"
                        }
                    },
                    tooltip: {
                        formatter: function () {
                            return this.point.label
                        }
                    },
                    plotOptions: {
                        column: {
                            dataLabels: {
                                enabled: true
                            },
                            events: {
                                click: (e) => {
                                    this.openStartupsModal(e.point.category)
                                }
                            }
                        }
                    },
                    series: [
                        {
                            name: "Source",
                            data: [],
                            color: '#0083b2'
                        }
                    ]
                }
            }
        },
        methods: {
            fetchChartData(initialCall = false) {
                this.getChartData('StartupsBySource').then(response => {
                    this.chartData = response.data
                    this.formatAndSetChartData()

                    if (!initialCall) {
                        this.$refs.chart.chart.hideLoading()
                    } else {
                        this.loaded = true
                    }
                })
            },

            formatAndSetChartData() {
                this.chartOptions.series[0].data = this.chartData
                this.chartOptions.xAxis.categories = this.chartData.map(item => item.source)
            },

            openStartupsModal(category) {
                this.$emit('showStartups', [this.chartName, `Startups in group "${category}"`, category])
            },
        }
    }
</script>
