import trackers from "@/services/trackers.service"
import trackersFoldersService from "@/services/trackers-folders.service";

export default {
    actions: {
        async fetchAllTrackers({ state }) {
            if (state.allTrackers.length) {
                return state.allTrackers
            }

            if (!state.allTrackersLoading) {
                state.allTrackersLoading = true;

                const {data} = await trackers.getList();
                state.allTrackers = data;
                state.allTrackersLoading = false;
                state.allTrackersLoaded = true;
            }
        },

        async fetchTrackers({ commit, state }, reload = false) {
            // get from state to avoid extra queries when no need to reload data from server
            if (state.trackers.length && !reload) {
                return state.trackers
            }

            if (state.fetchTrackersPromise) {
                return state.fetchTrackersPromise
            }

            let fetchTrackersPromise = trackers.getListForCompanyAdd().then(response => {
                commit('setTrackers', response.data)
                commit('setFetchTrackersPromise', null)
            })

            commit('setFetchTrackersPromise', fetchTrackersPromise)
        },

        async fetchFoldersTrackers({ commit, state }, reload = false) {
            // get from state to avoid extra queries when no need to reload data from server
            if (state.foldersTrackers.length && !reload) {
                return state.foldersTrackers
            }

            if (state.fetchFoldersTrackersPromise) {
                return state.fetchFoldersTrackersPromise
            }

            let fetchFoldersTrackersPromise = trackers.getFoldersAndTrackersList().then(response => {
                commit('setFoldersTrackers', Object.entries(response.data))
                commit('setFoldersTrackersLoaded')
                commit('setFetchFoldersTrackersPromise', null)
            })

            commit('setFetchFoldersTrackersPromise', fetchFoldersTrackersPromise)
        },

        async fetchFolders({state}){
            if (!state.foldersLoaded) {
                const {data} = await trackersFoldersService.getAvailableIds();

                state.availableFoldersIds = data;
                state.foldersLoaded = true;
            }
        }
    },
    mutations: {
        setTrackers: (state, trackers) => {state.trackers = trackers.startup; state.ecosystemTrackers = trackers.ecosystem},
        setFetchTrackersPromise: (state, fetchTrackersPromise) => (state.fetchTrackersPromise = fetchTrackersPromise),
        setFoldersTrackers: (state, foldersTrackers) => (state.foldersTrackers = foldersTrackers),
        setFoldersTrackersLoaded: (state) => (state.foldersTrackersLoaded = true),
        setFetchFoldersTrackersPromise: (state, fetchFoldersTrackersPromise) => (state.fetchFoldersTrackersPromise = fetchFoldersTrackersPromise),

        clearTrackers: (state) => {
            state.trackers = [];
            state.ecosystemTrackers = [];
            state.foldersTrackers = [];
        }
    },
    state: {
        allTrackers: [],
        allTrackersLoading: false,
        allTrackersLoaded: false,
        trackers: [],
        ecosystemTrackers: [],
        fetchTrackersPromise: null,
        foldersTrackers: [],
        foldersTrackersLoaded: false,
        fetchFoldersTrackersPromise: null,
        availableFoldersIds: [],
        foldersLoaded: false,
    },
    getters: {
        allTrackersList: state => state.allTrackers,
        availableTrackersIds: state => state.allTrackers.map(tracker => tracker.id),
        trackersList: state => state.trackers,
        ecosystemTrackersList: state => state.ecosystemTrackers,
        foldersTrackersList: state => state.foldersTrackers,
        foldersTrackersLoaded: state => state.foldersTrackersLoaded,
        availableFoldersIds: state => state.availableFoldersIds,
    },
}
