<template>
    <b-modal id="modal-edit-tracker" hide-footer modal-class="modal-edit-tracker">
        <b-button v-if="step > 1" variant="primary" size="lg" @click="prevStep" class="back-link"></b-button>

        <div class="modal-sub">
            <div class="modal-sub__inner">
        <!--        First template page-->

                <template v-if="step === 1">
                    <h1 class="modal__title">Add Template Details</h1>
                    <p>You can edit these later</p>
                    
                    <b-row class="mb-4">
                        <b-col cols="7">
                            <div class="form form-modal">
                                <h5 class="heading5 heading5--secondary">Template name</h5>
                                <b-form-input class="form-control--mid" v-model="template.name" placeholder="Enter Template Name"></b-form-input>
                            </div>
                        </b-col>
                        <b-col cols="5">
                            <div class="form form-modal">
                                <h5 class="heading5 heading5--secondary">Tracker name</h5>
                                <b-form-input class="form-control--mid" v-model="template.trackerName" placeholder=""></b-form-input>
                            </div>
                        </b-col>
                    </b-row>

                    <h5 class="heading5 heading5--secondary">Permissions (Who can use template)</h5>

                    <ul class="rows-list mb-3">
                        <li class="rows-list__form">

                            <multiselect class="multiselect-mid mr-3"
                                 v-model="newPermission.holder"
                                 :options="actualPermissionHolders"
                                 select-label=""
                                 deselect-label=""
                                 label="name"
                                 track-by="name"
                            >
                                <template slot="option" slot-scope="props">
                                    <template v-if="props.option.type === 'group'">
                                        <div class="d-flex align-items-center">
                                            <div class="mr-2">
                                                <font-awesome-icon :icon="['fa', 'users']" size="lg"  />
                                            </div>
                                            {{ props.option.name }}
                                        </div>

                                    </template>
                                    <template v-else>
                                        <div class="d-flex align-items-center">
                                            <div class="mr-2">
                                                <Avatar
                                                    :username="props.option.name"
                                                    :src="props.option.avatar"
                                                    :size="30">
                                                </Avatar>
                                            </div>
                                            {{ props.option.name }}
                                        </div>
                                    </template>
                                </template>
                            </multiselect>

                            <b-button @click="addPermission" variant="primary" size="md" :disabled="!newPermission.holder">Add</b-button>
                        </li>

                        <li v-for="(permission, index) in template.permissions" :key="index">
                            <div class="image-block image-block--rounded">
                                <Avatar
                                    :username="permission.name"
                                    :src="permission.avatar"
                                    :size="30">
                                </Avatar>
                            </div>

                            <h5 class="heading5 heading5--secondary">{{ permission.name }}</h5>

                            <a class="remove-action" @click="template.permissions.splice(index, 1)">Delete</a>
                        </li>
                    </ul>

                    <h5 class="heading5 heading5--secondary">Description</h5>
                    <div class="mb-4">
                        <b-form-textarea v-model="template.description" class="form-control--mid">
            
                        </b-form-textarea>
                    </div>
        
                    <h5 class="heading5 heading5--secondary">Default view</h5>
                    
                    <div class="radio-block mb-4">
                        <div class="radio-block__item radio-block__item--list">
                            <input type="radio" value="list" v-model="template.defaultView" id="dw1">
                            <label for="dw1">List</label>
                        </div>
                        <div class="radio-block__item radio-block__item--pipeline">
                            <input type="radio" value="pipeline" v-model="template.defaultView" id="dw2">
                            <label for="dw2">Funnel</label>
                        </div>
                    </div>
        
                   <h5 class="heading5 heading5--secondary">Timeline</h5>
                    <div class="mb-4">
                        <date-picker v-model="timeline"
                                     format = "MM/DD/YYYY"
                                     placeholder="Select Start / End Date"
                                     range>
                        </date-picker>
            
                        {{template.timeline}}
                    </div>
                    
                    <h5 class="heading5 heading5--secondary">Categories</h5>
                    <div class="mb-4">
                        <multiselect class="multiselect-mid"
                            v-model="template.categories"
                            :options="$store.state.categories"
                            select-label=""
                            deselect-label=""
                            :multiple="true"
                            label="name"
                            track-by="name"
                        >
                        </multiselect>
                    </div>
                    
                    <h5 class="heading5 heading5--secondary">Reviews</h5>
                    
                    <div class="d-flex mb-3">
                        <div class="input-radio mr-3">
                            <input type="radio" value="1" v-model="template.reviewsEnabled" id="tr1" name="tr">
                            <label for="tr1">Enabled (recommended)</label>
                        </div>
                        <div class="input-radio">
                            <input type="radio" value="0" v-model="template.reviewsEnabled" id="tr2" name="tr">
                            <label for="tr2">Disabled</label>
                        </div>
                    </div>

                    <template v-if="$store.getters.isEnterprise">
                        <div class="input-checkbox">
                            <input id="is-default-for-private" type="checkbox" v-model="template.isDefaultForPrivate">
                            <label for="is-default-for-private">Is Default for Private Tracker</label>
                        </div>

                        <div v-if="$store.state.user.company_id === constants.companyCoPace" class="input-checkbox mt-3">
                            <input id="is-default-for-poc" type="checkbox" v-model="template.isDefaultForPoc">
                            <label for="is-default-for-poc">Is Default for POC Tracker</label>
                        </div>
                    </template>
                </template>
        
                <template v-if="step === 2">
                    <h1 class="modal__title">Template Stages</h1>
                    
                    <p>Set up all the steps you want to track. You can edit these later.</p>
        
                    <multiselect class="multiselect-mid"
                        v-model="template.pipeline"
                        :options="pipelinesList"
                        select-label=""
                        deselect-label=""
                        label="name"
                        track-by="name"
                    >
                    </multiselect>
        
                    <ul v-if="template.pipeline" class="list-drag-n-drop list-drag-n-drop--full">
                        <li v-for="(step, index) in template.pipeline.steps" :key="index" class="list-drag-n-drop__item">
                            <span class="input-checkbox">
                                {{step.name}}
                            </span>
                        </li>
                    </ul>

                    <a v-if="template.pipeline" @click="$refs.EditPipeline.open(template.pipeline.id)">Manage Stages</a>
                </template>
        
                <template v-if="step === 3">
                    <h1 class="modal__title">Customizations</h1>
                    <h5 class="heading5 heading5--secondary">Tracker Details Fields</h5>
                    <p class="template-hint">Fields your team members will fill out in order to create tracker</p>

                    <Draggable v-model="template.submitterFields" class="list-drag-n-drop--full list-drag-n-drop list-drag-n-drop--complex" handle=".draggable">
                        <div v-for="(field, index) in template.submitterFields" :key="'s'+index" class="w-100 draggable">
                            <div class="mb-2 pr-0 list-drag-n-drop__item">
                                <span></span>
                                <Multiselect class="multiselect-mid mb-2"
                                             v-model="template.submitterFields[index]"
                                             :options="submitterFieldsList"
                                             select-label=""
                                             deselect-label=""
                                             label="name"
                                             track-by="name"
                                />
                            </div>

                            <div class="text-right mb-2">
                                <a @click="template.submitterFields.splice(index, 1)" class="link link-normal">Remove</a>
                            </div>
                        </div>
                    </Draggable>

                    <div class="link-wrapper">
                        <a @click="template.submitterFields.push(null)" class="link">+ Add Field</a>
                    </div>

                    <template v-if="template.reviewsEnabled === 1">
                        <h5 class="heading5 heading5--secondary">Reviewer Fields</h5>
                        <p class="template-hint">Fields your team members will fill out in order to review startups</p>

                        <Draggable v-model="template.reviewerFields" class="list-drag-n-drop--full list-drag-n-drop list-drag-n-drop--complex" handle=".draggable">
                            <div v-for="(field, index) in template.reviewerFields" :key="'r'+index" class="w-100 draggable">
                                <div class="mb-2 pr-0 list-drag-n-drop__item">
                                    <span></span>
                                    <Multiselect class="multiselect-mid mb-2"
                                         v-model="template.reviewerFields[index]"
                                         :options="reviewerFieldsList"
                                         label="name"
                                         track-by="name"
                                         select-label=""
                                         deselect-label=""
                                    />
                                </div>

                                <div class="text-right mb-2">
                                    <a @click="template.reviewerFields.splice(index, 1)" class="link link-normal">Remove</a>
                                </div>
                            </div>
                        </Draggable>
                        
                        <div class="link-wrapper">
                            <a @click="template.reviewerFields.push(null)" class="link">+ Add Field</a>
                        </div>
                    </template>

                    <h5 class="heading5 heading5--secondary">Startup Diligence Fields</h5>

                    <Draggable v-model="template.diligenceFields" class="list-drag-n-drop--full list-drag-n-drop list-drag-n-drop--complex" handle=".draggable">
                        <div v-for="(field, index) in template.diligenceFields" :key="index" class="w-100 draggable">
                            <div class="mb-2 pr-0 list-drag-n-drop__item">
                                <span></span>
                                <Multiselect class="multiselect-mid mb-2"
                                             v-model="template.diligenceFields[index]"
                                             :options="actualDiligenceFields"
                                             select-label=""
                                             deselect-label=""
                                             label="name"
                                             track-by="name"
                                />
                            </div>

                            <div class="text-right mb-2">
                                <a @click="template.diligenceFields.splice(index, 1)" class="link link-normal">Remove</a>
                            </div>
                        </div>
                    </Draggable>

                    <div class="link-wrapper">
                        <a @click="template.diligenceFields.push(null)" class="link">+ Add Field</a>
                    </div>
                </template>

                <footer class="modal__bottom">
                    <div class="modal__bottom__inner">
                        <b-button v-if="step < 3" variant="primary" size="lg" :disabled="!canNext" @click="nextStep">Next</b-button>
                        
                        <b-button v-if="step === 3" variant="primary" size="lg" @click="save"><template v-if="id > 0">Update</template><template v-else>Create</template> Template</b-button>
                    </div>
                </footer>
            </div>

        </div>

        <CustomFieldEdit
            ref="EditPipeline"
            type="tracker_diligence"
            fieldType="pipeline"
            :sectionId="0"
            @saved="updatePipeline"
        />

        <CustomFieldEdit
            ref="EditSubmit"
            type="tracker_submit"
            :sectionId="0"
            @saved="updateSubmit"
        />

        <CustomFieldEdit
            ref="EditReview"
            type="tracker_review"
            :sectionId="0"
            @saved="updateReview"
        />
    </b-modal>
</template>

<script>
import customFields from "@/services/custom-fields.service";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import trackers from "@/services/trackers.service";
import trackersTemplates from "@/services/trackers-templates.service";
import team from "@/services/team.service";
import Avatar from "vue-avatar";
import CustomFieldEdit from "@/components/customFields/edit/CustomFieldEdit.vue";
import Draggable from "vuedraggable";
import constants from "@/constants/constants";

export default {

    components: {
        Draggable,
        CustomFieldEdit,
        Multiselect,
        DatePicker,
        Avatar,
    },

    data() {
        return {
            id: 0,
            step: 1,

            pipelinesList: [],
            timeline: [null, null],
            reviewerFieldsList: [],
            submitterFieldsList: [],
            diligenceFieldsList: [],
            currentReviewIndex: -1,
            currentSubmitIndex: -1,

            permissionHolders: [],
            newPermission: {
                holder: null,
            },

            roles: {
                'manager': 'Manager',
                'user': 'User',
                'contributor': 'Contributor',
            },

            template: {
                name: '',
                trackerName: '',
                categories: [],
                description: '',
                pipeline: null,
                startDate: null,
                endDate: null,
                defaultView: 'pipeline',
                reviewsEnabled: 1,
                submitterFields: [],
                reviewerFields: [],
                diligenceFields: [],
                permissions: [],
                isDefaultForPrivate: false,
                isDefaultForPoc: false,
            }
        }
    },

    watch: {
        timeline() {
            if (this.timeline[0]) {
                let interval = this.dateHelper.encodeIntervalForServer(this.timeline)
                this.template.startDate = interval[0]
                this.template.endDate = interval[1]
            }
            else {
                this.template.startDate = null
                this.template.endDate = null
            }
        },

        'template.pipeline'() {
            if (this.template.pipeline && this.template.pipeline.id == -1) {
                this.$refs.EditPipeline.open(0)
                this.template.pipeline = null
            }
        },

        'template.submitterFields'() {
            this.template.submitterFields.forEach((item, index) => {

                if (item && item.id == -1) {
                    this.currentSubmitIndex = index
                    this.template.submitterFields[index] = null
                    this.$refs.EditSubmit.open(0)
                }
            })
        },

        'template.reviewerFields'() {
            this.template.reviewerFields.forEach((item, index) => {

                if (item && item.id == -1) {
                    this.currentReviewIndex = index
                    this.template.reviewerFields[index] = null
                    this.$refs.EditReview.open(0)
                }
            })
        }
    },

    computed: {
        constants() {
            return constants
        },

        hasReviewStep() {
            return this.template.type === 'public' || this.template.reviewsEnabled
        },
        //title() {
        //    if (this.step == 1 && !this.template.name.length) {
        //        return "Create New Template"
        //    }
        //    else {
        //        return "Choose a Template"
        //    }
        //},

        canNext() {
            if (this.step === 1 && !this.template.name.length) {
                return false
            }
            else {
                return true
            }
        },

        actualPermissionHolders() {
            let array = []
            this.permissionHolders.forEach(item => {
                if (!this.arrayHelper.exists(this.template.permissions, ['type', 'id'], [item.type, item.id])) {
                    array.push(item)
                }
            })

            return array
        },

        actualDiligenceFields() {
            return this.arrayHelper.arrayDiff(this.diligenceFieldsList, this.template.diligenceFields, 'id')
        },
    },

    mounted() {
        team.getPermissionHolders().then(response => {
            this.permissionHolders = response.data
        })
    },

    methods: {
        getFieldsList() {
            trackers.getPipelinesList().then(response => {
                this.pipelinesList = response.data

                if (this.$store.state.user.permissions.includes('funnel-create')) {
                    this.pipelinesList.push({
                        id: -1,
                        name: "Create New Funnel",
                        steps: [],
                    })
                }
            })

            customFields.getFieldsArray('tracker_submit').then(response => {
                this.submitterFieldsList = response.data
                this.submitterFieldsList.push({
                    id: -1,
                    name: "Create New Field",
                })
            })

            customFields.getFieldsArray('tracker_review').then(response => {
                this.reviewerFieldsList = response.data
                this.reviewerFieldsList.push({
                    id: -1,
                    name: "Create New Field",
                })
            })

            customFields.getFieldsArray('tracker_diligence').then(response => {
                this.diligenceFieldsList = response.data
            })
        },

        init() {
            this.getFieldsList()

            this.step = 1

            if (this.id) {
                trackersTemplates.getTemplateEdit(this.id).then(response => {
                    this.template.name = response.data.name
                    this.template.trackerName = response.data.tracker_name
                    this.template.description = response.data.description
                    this.template.defaultView = response.data.default_view
                    this.template.reviewsEnabled = response.data.reviews_enabled
                    this.template.startDate = response.data.start_date
                    this.template.endDate = response.data.end_date
                    this.template.submitterFields = response.data.submitter_fields
                    this.template.reviewerFields = response.data.reviewer_fields
                    this.template.diligenceFields = response.data.diligence_fields
                    this.template.permissions = response.data.permissions
                    this.template.isDefaultForPrivate = response.data.is_default_for_private
                    this.template.isDefaultForPoc = response.data.is_default_for_poc

                    if (response.data.start_date != null) {
                        this.timeline = this.dateHelper.decodeIntervalFromServer([response.data.start_date, response.data.end_date])
                    }

                    this.template.pipeline = response.data.pipeline
                    this.template.categories = response.data.categories
                })
            }
            else {
                this.timeline = [null, null]
                this.template.name = ""
                this.template.trackerName = ""
                this.template.description = ""
                this.template.defaultView = "pipeline"
                this.template.reviewsEnabled = 1

                this.template.pipeline = null
                this.template.categories = []
                this.template.startDate = null
                this.template.endDate = null
                this.template.submitterFields = []
                this.template.reviewerFields = []
                this.template.permissions = []
                this.template.isDefaultForPrivate = false
                this.template.isDefaultForPoc = false
            }
        },

        open(id) {
            this.id = id
            this.init()
            this.$bvModal.show('modal-edit-tracker')
        },

        save() {
            trackersTemplates.saveTemplate(this.id, this.template).then(() => {
                this.$emit('templateSaved')
                this.$bvModal.hide('modal-edit-tracker')
            })
        },

        updatePipeline(id) {
            trackers.getPipelinesList().then(response => {
                this.pipelinesList = response.data
                this.pipelinesList.push({
                    id: -1,
                    name: "Create New Funnel",
                    steps: [],
                })

                let index = this.arrayHelper.findIndexByColumnValue(this.pipelinesList, 'id', id)
                this.template.pipeline = this.pipelinesList[index]
            })
        },

        updateSubmit(id) {
            customFields.getFieldsArray('tracker_submit').then(response => {
                this.submitterFieldsList = response.data
                this.submitterFieldsList.push({
                    id: -1,
                    name: "Create New Field",
                })

                let index = this.arrayHelper.findIndexByColumnValue(this.submitterFieldsList, 'id', id)
                this.template.submitterFields[this.currentSubmitIndex] = this.submitterFieldsList[index]
            })
        },

        updateReview(id) {
            customFields.getFieldsArray('tracker_review').then(response => {
                this.reviewerFieldsList = response.data
                this.reviewerFieldsList.push({
                    id: -1,
                    name: "Create New Field",
                })

                let index = this.arrayHelper.findIndexByColumnValue(this.reviewerFieldsList, 'id', id)
                this.template.reviewerFields[this.currentReviewIndex] = this.reviewerFieldsList[index]
            })
        },

        addPermission() {
            this.template.permissions.push(
                {
                    type: this.newPermission.holder.type,
                    id: this.newPermission.holder.id,
                    name: this.newPermission.holder.name,
                    avatar: this.newPermission.holder.avatar,
                }
            )

            this.newPermission.holder = null
        },

        nextStep() {
            if (this.step === 1 && (this.template.defaultView === 'list' || (this.template.isDefaultForPoc && !this.template.isDefaultForPrivate))) {
                this.step = 3
            } else {
                ++this.step
            }
        },

        prevStep() {
            if (this.step === 3 && this.template.defaultView === 'list') {
                this.step = 1
            } else {
                --this.step
            }
        },
    }
}
</script>

<style scoped>

</style>
