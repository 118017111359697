<script setup>
import {fieldFilled, getNoun} from "@/helpers/string";
import logoHelper from "@/helpers/logo";
import Vue, {reactive, ref, watch, inject, nextTick, computed} from "vue";
import {useStore} from "vue2-helpers/vuex";
import constants from "@/constants/constants";
import analystService from "@/services/analyst.service";
import AddAnalystHours from "@/components/subscriptions/AddAnalystHours.vue";
import GetAnalystsDataModal from "@/components/startups/GetAnalystsDataModal.vue";
import CustomFieldValue from "@/components/customFields/CustomFieldValue.vue";
import userService from "@/services/user.service";
import useConditionalFields from "@/composables/useConditionalFields";

const props = defineProps(['startupId', 'customFields', 'customData']);
const clearCustomValue = inject('clearCustomValue');

const store = useStore();
const emit = defineEmits(['updateCustomData', 'getLatestActivities']);
const addAnalystHoursModal = ref(null);
const sectionsFilledFields = reactive({});
const getAnalystsDataModal = ref(null);
const customData = ref(props.customData);
const actualFields = ref({});
const notWatchValue = ref(false);
const showAnalystHint = ref(null)

const allFields = computed(() => Object.keys(props.customFields).map(sectionId => props.customFields[sectionId].fields).flat());
const {getFieldStatus} = useConditionalFields(allFields, customData);

watch(customData, (value) => {
    if (!notWatchValue.value) {

        // Set available fields (according to dependencies)
        actualFields.value = {};

        for (let sectionId in props.customFields) {
            actualFields.value[sectionId] = {
                ...props.customFields[sectionId],
                fields: [],
            };

            for (let field of props.customFields[sectionId].fields) {
                const {canShow, actualField, needClear} = getFieldStatus(field);

                if (canShow) {
                    actualFields.value[sectionId].fields.push(actualField);

                    if (needClear) {
                        clearValue(field);
                    }
                } else if (fieldFilled(value[field.id])) {
                    clearValue(field);
                }
            }
        }
    }

    // Calculate filled fields percentage
    for (let sectionId in props.customFields) {
        const { sectionFieldsCount, sectionFilledFieldsCount } = actualFields.value[sectionId].fields.reduce(
            (acc, field) => {
                acc.sectionFieldsCount++;

                if (fieldFilled(value[field.id])) {
                    acc.sectionFilledFieldsCount++;
                }

                return acc;
            },
            { sectionFieldsCount: 0, sectionFilledFieldsCount: 0 }
        );

        sectionsFilledFields[sectionId] = sectionFieldsCount ? Math.round((sectionFilledFieldsCount / sectionFieldsCount) * 100) : undefined;
    }
}, {immediate: true, deep: true});

function updateValue(fieldId, value) {
    customData.value[fieldId].text = value.text;
    customData.value[fieldId].options = value.options;
    customData.value[fieldId].links = value.links;
    customData.value[fieldId].files = value.files;
}

function sectionFilled(section) {
    return section.fields.some(field =>
        field.can_edit ||
        field.type === 'static_text' ||
        (customData.value[field.id] && fieldFilled(customData.value[field.id]))
    );
}

function displayGetFieldData(fieldId) {
    return !store.getters.isEventLimited && 
        !customData.value[fieldId]?.data_requested &&
        !fieldFilled(customData.value[fieldId]);
}

function displayGetSectionData(sectionId) {
    if (
        sectionId === '0' ||
        !store.state.user.is_admin ||
        store.getters.isEventLimited ||
        store.state.user.company_id === constants.companyPfizer
    ) {
        return false;
    }

    return actualFields.value[sectionId].fields.some(field => displayGetFieldData(field.id));
}

async function openAnalystsModal(sectionId, field) {
    const {data} = await analystService.getAnalystHoursLeft();

    if (data.hours_plan) {
        getAnalystsDataModal.value.show(sectionId, field);
    } else {
        addAnalystHoursModal.value.open();
    }
}

function dataRequested({field, sectionId}) {
    if (field) {
        Vue.notify({
            group: 'app',
            type: 'success',
            duration: 5000,
            text: `Data requested for ${field.name} field`
        });
    } else {
        const total = actualFields.value[sectionId].fields.filter(field => displayGetFieldData(field.id)).length;

        Vue.notify({
            group: 'app',
            type: 'success',
            duration: 5000,
            text: `Data requested for ${total} ${getNoun('field', total)}`
        });
    }

    emit('updateCustomData');
}

function clearValue(field) {
    notWatchValue.value = true;
    clearCustomValue(field.id);

    customData.value[field.id] = {
        text: '',
        options: [],
        links: [],
        files: [],
    };

    nextTick(() => {
        notWatchValue.value = false;
    });
}

function hideAnalystDataPrompt(sectionId) {
    userService.hideFieldFillPrompt();
    store.state.user.settings.hide_fields_fill_prompt = 1;
    showAnalystHint.value = sectionId;
}

function clickedOutsideTooltip(e) {
    if (!e.target.className.includes('link-dismiss')) {
        showAnalystHint.value = null;
    }
}

</script>

<template>
    <div>                 
        <div class="accordion">
            <template v-for="(section, sectionId) in actualFields">
                <div class="accordion__item" v-if="sectionFilled(section) || section.is_default" :key="sectionId">
                    <header
                        class="details-field__header accordion__header"
                        :id="`header-accordion-${sectionId}`"
                        v-b-toggle.collapse="`accordion-${sectionId}`"
                    >
                        <h4 class="heading4 accordion__title accordion__title-custom-arrow">
                            <div>{{section.name}}</div>

                            <span class="accordion__collapse-custom-arrow" role="button"></span>
                        </h4>
                        <div
                            v-if="sectionsFilledFields[sectionId]"
                            class="filled-cnt ml-2">{{sectionsFilledFields[sectionId]}}% filled
                        </div>
<!--  -->
                        <div
                            :id="'section-dropdown-' + sectionId"
                            v-if="store.getters.isEnterprise && displayGetSectionData(sectionId)"
                            class="dropdown-original ml-4"
                        >
                            <b-dropdown
                                class="dropdown-data position-relative"
                                boundary="viewport"
                                @show="showAnalystHint = null"
                            >
                                <b-dropdown-item @click.stop.prevent="openAnalystsModal(sectionId)">
                                    Get All Data
                                </b-dropdown-item>
                            </b-dropdown>

                            <BTooltip
                                v-if="showAnalystHint === sectionId"
                                :show="true"
                                :target="'section-dropdown-' + sectionId"
                                placement="bottom"
                                triggers=""
                                custom-class="tooltip--origin"
                            >
                                <p>The option to get all data will be available in this menu</p>

                                <div class="text-right">
                                    <a
                                        class="link"
                                        @click.prevent.stop="showAnalystHint = null"
                                        v-click-outside="clickedOutsideTooltip"
                                    >
                                        Close
                                    </a>
                                </div>
                            </BTooltip>
                        </div>
                    </header>

                    <b-collapse :id="'accordion-' + sectionId" accordion="sections-accordion" :visible="Object.keys(actualFields)[0] === sectionId">
                        <template v-if="store.getters.isEnterprise && displayGetSectionData(sectionId) && !store.getters.settings.hide_fields_fill_prompt">
                            <div class="main-startup__tabs__top flex-wrap">
                                <p class="mr-3">Utilize our analysts to populate data for all fields in this section</p>

                                <div class="main-startup__tabs__top__sidebar">
                                    <a
                                        role="button" class="link-data link-data--light"
                                        @click.stop.prevent="openAnalystsModal(sectionId)"
                                    >
                                        Get All Data
                                    </a>

                                    <a class="link-dismiss ml-4" @click="hideAnalystDataPrompt(sectionId)">
                                        Dismiss
                                    </a>
                                </div>
                            </div>
                        </template>

                        <div class="main-startup__tabs__item__inner">
                            <table class="details-table">
                                <tbody>
                                    <template v-for="field in section.fields">
                                        <tr v-if="field.can_edit || field.type === 'static_text' || (customData[field.id] && stringHelper.fieldFilled(customData[field.id]))" :key="field.id" >
                                            <td colspan="2" v-if="field.type === 'static_text'">
                                                <div class="details-table__cell__label d-flex flex-wrap">
                                                    {{field.name}}

                                                    <b-popover
                                                        v-if="field.description" 
                                                        custom-class="hint-secondary__content" 
                                                        :target="`field-description-${field.id}`"
                                                        triggers="hover"
                                                        placement="top"
                                                    >
                                                        <div class="popover__content">
                                                            {{ field.description }}
                                                        </div>
                                                    </b-popover>

                                                    <i v-if="field.description"
                                                       :id="`field-description-${field.id}`"
                                                       class="hint-secondary"
                                                    ></i>
                                                </div>
                                            </td>

                                            <td
                                                v-else
                                                :class="{ 'details-table__cell': ['select', 'text', 'date', 'numeric', 'links_list', 'file', 'multiselect'].includes(field.type)}">
                                                <div class="details-table__cell__label d-flex flex-wrap">
                                                    {{field.name}}

                                                    <div v-if="field.added_by === 'ai'" class="details-table__added-info-ai">
                                                        Added by Gen AI
                                                    </div>

                                                    <b-popover
                                                        v-if="field.description"
                                                        custom-class="hint-secondary__content"
                                                        :target="`field-description-${field.id}`"
                                                        triggers="hover"
                                                        placement="top"
                                                    >
                                                        <div class="popover__content">
                                                           {{ field.description }}
                                                        </div>
                                                    </b-popover>

                                                    <i v-if="field.description"
                                                       :id="`field-description-${field.id}`"
                                                       class="hint-secondary"
                                                    ></i>
                                                </div>

                                                <div class="startup-field-block" v-if="field.allow_startup_population">
                                                    Startup Field
                                                </div>

                                                <div class="details-table__edit-info">
                                                    <template v-if="typeof customData[field.id] !== 'undefined' && customData[field.id]['updated_at'] && stringHelper.fieldFilled(customData[field.id])">
                                                        <span class="details-table__edit-info__date">
                                                            <template v-if="customData[field.id].populated_by_startup">
                                                                <div class="image-block image-block--xxs">
                                                                    <img :src="logoHelper.getLogo(customData[field.id].startup_logo)" alt="img"/>
                                                                </div>
                                                                Edited {{customData[field.id]['updated_at']}}
                                                            </template>

                                                            <template v-else>
                                                                {{customData[field.id]['user']}}
                                                                {{customData[field.id]['updated_at']}}
                                                            </template>
                                                        </span>
                                                    </template>

                                                    <template v-if="store.getters.isEnterprise && store.state.user.is_admin && store.state.user.company_id !== constants.companyPfizer">
                                                        <a
                                                            role="button"
                                                            class="link-data link-data-analyst"
                                                            v-if="displayGetFieldData(field.id)"
                                                            @click="openAnalystsModal(null, field)"
                                                        >
                                                            Get Analyst Data
                                                        </a>

                                                        <span
                                                            v-if="!!customData[field.id]?.data_requested"
                                                            class="link-data link-data--requested"
                                                        >
                                                            Data Requested
                                                        </span>
                                                    </template>
                                                </div>
                                            </td>

                                            <td v-if="field.type !== 'static_text'">
                                                <CustomFieldValue
                                                    :editable="field.can_edit"
                                                    :field="field"
                                                    :init-value="customData[field.id]"
                                                    @valueChanged="updateValue"
                                                />
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </b-collapse>
                </div>
            </template>

            <AddAnalystHours ref="addAnalystHoursModal"/>

            <GetAnalystsDataModal
                ref="getAnalystsDataModal"
                :startup-id="startupId"
                @requested="dataRequested"
            />
        </div>
    </div>
</template>

<style scoped>

</style>
