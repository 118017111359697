<script setup>
import Vue, {computed, ref, watch} from "vue";
import trackersReportsService from "@/services/trackers-reports.service";
import PrimaryButton from "@/components/ui/PrimaryButton.vue";
import AddList from "@/components/trackers/comparisonReport/AddList.vue";
import {useStore} from "vue2-helpers/vuex";
import {validEmail} from "@/helpers/string";

const {trackerId} = defineProps(["trackerId"]);
const emit = defineEmits(['openReport']);
const store = useStore();

const standardFeatures = [
    'Short Description',
    'Employee Count',
    'Total Raised',
    'Clients',
];

const showModal = ref(false);
const startups = ref([]);
const selectedFeatures = ref([]);
const recommendedFeatures = ref([]);
const recommendedFeaturesLoading = ref(true);
const profileFields = ref([]);
const diligenceFields = ref([]);
const filter = ref("");

const loading = ref(false);
const reportId = ref(null);
const interval = ref(null);
const step = ref(1);
const email = ref('');

const modalTitle = computed(() => {
    return step.value === 1 ? "Select Features" : "Report Generating";
});

const suggestedLists = computed(() => [...recommendedFeatures.value, ...standardFeatures, ...profileFields.value, ...diligenceFields.value]);

const actualRecommendedFeatures = computed(() => {
    return filteredList(recommendedFeatures.value);
});

const actualStandardFeatures = computed(() => {
    return filteredList(standardFeatures);
});

const actualProfileFields = computed(() => {
    return filteredList(profileFields.value);
});

const actualDiligenceFields = computed(() => {
    return filteredList(diligenceFields.value);
});

const filterHasExactMatch = computed(() => suggestedLists.value.some(feature => feature.toLowerCase() === filter.value.toLowerCase()));

watch(showModal, (value) => {
    if (!value) {
        clearInterval(interval.value);
    }
});

function filteredList(list) {
    return list
        .filter(feature => !selectedFeatures.value.some(selectedFeature => feature.toLowerCase() === selectedFeature.toLowerCase()))
        .filter(feature => feature.toLowerCase().includes(filter.value.toLowerCase()));
}

async function sendRequest() {
    loading.value = true;
    const {data} = await trackersReportsService.requestComparisonReport(trackerId, startups.value, selectedFeatures.value);

    loading.value = false;

    if (data) {
        let [ready, report_id] = data;

        if (ready) {
            showModal.value = false;

            emit('openReport', {
                id: report_id,
                text: 'Feature Comparison Report',
            });
        } else {
            reportId.value = report_id;
            step.value = 2;
        }
    } else {
        Vue.notify({
            group: 'app',
            type: 'error',
            text: 'Error occurred'
        });
    }
}

async function sendEmail() {
    loading.value = true;
    const {data} = await trackersReportsService.saveComparisonReportEmail(reportId.value, email.value);

    loading.value = false;
    showModal.value = false;

    if (data) {
        Vue.notify({
            group: 'app',
            type: 'success',
            text: 'Request sent'
        });
    } else {
        Vue.notify({
            group: 'app',
            type: 'error',
            text: 'Error occurred'
        });
    }
}

async function getRecommendedFeatures() {
    const {data} = await trackersReportsService.getRecommendedFeatures(startups.value);
    recommendedFeatures.value = data;
    recommendedFeaturesLoading.value = false;
}

async function getCustomFields() {
    const {data} = await trackersReportsService.getCustomFields(trackerId);
    profileFields.value = data[0];
    diligenceFields.value = data[1];
}

function addCustomFeature() {
    if (filter.value.length) {
        selectedFeatures.value.push(filter.value);
        filter.value = "";
    }
}

function addFeature(feature) {
    selectedFeatures.value.push(feature);
    filter.value = "";
}

function open(startupsIds) {
    startups.value = startupsIds;
    selectedFeatures.value = [...standardFeatures];
    recommendedFeatures.value = [];
    recommendedFeaturesLoading.value = true;
    filter.value = '';
    reportId.value = null;
    email.value = store.state.user.email;
    step.value = 1;
    showModal.value = true;

    getRecommendedFeatures();
    getCustomFields();
}

defineExpose({
    open
});

</script>

<template>
    <b-modal v-model="showModal" :title="modalTitle" modal-class="modal-w-xl">
        <template v-if="step === 1">
            <div>Add features you want to compare</div>

            <div class="content">
                <!--            Left side-->
                <div>
                    <div style="display: flex; justify-content: space-between; align-items: center;">
                        <div style="flex: 1">
                            <input
                                v-model="filter"
                                type="text"
                                placeholder="Enter feature name"
                                class="form-control form-control--mid"
                                @keyup.enter="addCustomFeature"
                            >
                        </div>
                    </div>

                    <AddList
                        v-if="filter.length && !filterHasExactMatch"
                        title="Add Column Feature"
                        :list="[filter]"
                        @add="addFeature"
                    />

                    <AddList
                        v-show="actualRecommendedFeatures.length || recommendedFeaturesLoading"
                        title="Suggestions"
                        :list="actualRecommendedFeatures"
                        :ai-generated="true"
                        :loading="recommendedFeaturesLoading"
                        @add="addFeature"
                    />

                    <AddList
                        v-show="actualStandardFeatures.length"
                        title="Standard Fields"
                        :list="actualStandardFeatures"
                        @add="addFeature"
                    />

                    <AddList
                        v-show="actualProfileFields.length"
                        title="Your Profile Fields"
                        :list="actualProfileFields"
                        @add="addFeature"
                    />

                    <AddList
                        v-show="actualDiligenceFields.length"
                        title="You Startup Diligence Fields"
                        :list="actualDiligenceFields"
                        @add="addFeature"
                    />
                </div>

                <div>
                    <div class="selected-block">
                        <div class="block-header">
                            Selected
                        </div>

                        <div class="block-content">
                            <ul v-if="selectedFeatures.length">
                                <li
                                    v-for="(feature, index) in selectedFeatures"
                                    :key="index"
                                    class="feature-item"
                                >
                                    <div style="display: flex; justify-content: space-between; align-items: center;">
                                        <div>
                                            {{feature}}
                                        </div>

                                        <div>
                                            <a @click="selectedFeatures.splice(index, 1)" class="remove-button"></a>
                                        </div>
                                    </div>
                                </li>
                            </ul>

                            <div v-else class="text-center">
                                No features selected.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template v-else-if="step === 2">
            <div style="margin-right: 200px; text-align: left; font-size: 18px">
                Creating this report will take about 10 minutes. Add your email and we’ll send it to you when it’s ready.
            </div>

            <input
                v-model="email"
                type="text"
                placeholder="Enter email"
                class="form-control form-control--mid"
                style="width: 269px; margin-top: 50px; margin-bottom: 10px"
                @keyup.enter="addCustomFeature"
            >
        </template>

        <template #modal-footer>
            <div v-if="step === 2" style="width: 100%; margin-left: 12px; text-align: left">
                <PrimaryButton
                    :loading="loading"
                    :disabled="!(email.length && validEmail(email))"
                    caption="Email Me"
                    loading-caption="Saving email..."
                    @click="sendEmail"
                />
            </div>

            <PrimaryButton
                v-else
                :loading="loading"
                :disabled="!selectedFeatures.length"
                caption="Generate Report"
                loading-caption="Sending request..."
                @click="sendRequest"
            />
        </template>
    </b-modal>
</template>

<style scoped>
    .content {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        font-size: 14px;

        &>div {
            width: 285px;
        }

        .selected-block {

            .block-header {
                padding: 10px 24px;
                color: white;
                background-color: #043648;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }

            .block-content {
                padding: 10px 24px;
                background-color: #f2f2f2;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;

                li {
                    padding: 11px 0;

                    &:not(:nth-last-child) {
                        border-bottom: 1px solid #ddd;
                    }
                }
            }
        }

        .block-header {
            font-weight: bold;
        }

        .feature-item {
            padding: 10px 0;

            &:not(:last-child) {
                border-bottom: 1px solid #ddd;
            }
        }

        .remove-button {
            display: block;
            flex: 0 0 16px;
            width: 16px;
            height: 16px;
            background: url(../../../images/icon-custom-remove-dark.svg) no-repeat center;
            background-size: contain;
        }
    }
</style>
