<template>
    <div>
        <highcharts
            v-if="loaded"
            :options="getOptions(chartOptions)"
            ref="chart"
        />

        <div v-else class="d-flex justify-content-center">
            <b-spinner :style="{'color':constants.spinnerColor}"></b-spinner>
        </div>
    </div>
</template>

<script>
    import {Chart} from 'highcharts-vue'
    import Highcharts from 'highcharts'
    import exportingInit from "highcharts/modules/exporting"
    import dashboardChartMixin from '@/mixins/dashboardChartMixin'
    import string from "@/helpers/string";

    exportingInit(Highcharts)

    export default {
        name: 'RequestsByStageAndStatus',
        mixins: [dashboardChartMixin],
        components: {
            highcharts: Chart
        },
        data() {
            return {
                loaded: false,
                chartName: "RequestsByStageAndStatus",
                chartOptions: {
                    chart: {
                        type: 'column'
                    },

                    title: {
                        text: 'PoC Stages / Statuses',
                        align: 'left'
                    },

                    xAxis: {
                        labels: {
                            style: {
                                fontWeight: 'bold',
                                fontSize: '13px'
                            }
                        },
                        tickWidth: 0,
                        lineWidth: 0,
                        crosshair: true
                    },

                    yAxis: {
                        allowDecimals: false,
                        min: 0,
                        title: {
                            text: 'Number of Projects'
                        }
                    },

                    tooltip: {
                        formatter: function() {
                            return `<b>${this.series.name}</b><br>` +
                                `${this.x}: ${this.y} ${string.getNoun('project', this.y)}`;
                        }
                    },

                    plotOptions: {
                        column: {
                            stacking: 'normal',
                        },
                        series: {
                            dataLabels: {
                                enabled: true,
                                formatter: function() {
                                    if (this.point.stackTotal && this.point.y && this.point.stackY === this.point.stackTotal) {
                                        return this.point.stackTotal;
                                    }

                                    return '';
                                },
                                style: {
                                    fontWeight: 'bold',
                                    color: 'black'
                                },
                                y: -40
                            }
                        }
                    },

                    series: [],
                    legend: {
                        align: 'center',
                        verticalAlign: 'bottom',
                        layout: 'horizontal',
                        itemMarginBottom: 5
                    }
                },
            }
        },
        methods: {
            fetchChartData(initialCall = false) {
                this.getChartData('RequestsByStageAndStatus').then(response => {
                    let data = response.data

                    this.chartOptions.series = data.series.map(item => ({
                        ...item,
                        events: {
                            click: (e) => {
                                this.openStartupsModal(e.point.category, item.name)
                            }
                        }
                    }));

                    this.chartOptions.xAxis.categories = data.categories

                    if (!initialCall) {
                        this.$refs.chart.chart.hideLoading()
                    } else {
                        this.loaded = true
                    }
                })
            },

            openStartupsModal(category, name) {
                this.$emit('showStartups', [this.chartName, `Startups - ${category} - ${name}`, JSON.stringify([category, name])])
            }
        }
    }
</script>
