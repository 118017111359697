import { render, staticRenderFns } from "./AddList.vue?vue&type=template&id=ddbb80c0&scoped=true&"
import script from "./AddList.vue?vue&type=script&setup=true&lang=js&"
export * from "./AddList.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./AddList.vue?vue&type=style&index=0&id=ddbb80c0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ddbb80c0",
  null
  
)

export default component.exports