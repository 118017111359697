import { render, staticRenderFns } from "./Trending.vue?vue&type=template&id=704eb13c&scoped=true&"
import script from "./Trending.vue?vue&type=script&setup=true&lang=js&"
export * from "./Trending.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./Trending.vue?vue&type=style&index=0&id=704eb13c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "704eb13c",
  null
  
)

export default component.exports