<script setup>
import Loader from "@/components/ui/Loader.vue";
import {inject, ref, watch} from "vue";
import constants from "@/constants/constants";
import dashboardService from "@/services/dashboard.service";
import StartupUpdateLog from "@/views/enterprise/home/aside/updates/startups/StartupUpdateLog.vue";
import ScrolledBottom from "@/components/ScrolledBottom.vue";

const emit = defineEmits(['hideStartupUpdates']);
const filters = inject('startupsFilters');
const updates = ref(null);
const loading = ref(false);
const maxDate = ref(null);

watch(filters, () => {
    updates.value = null;
    maxDate.value = null;
    getUpdates();
}, {deep: true, immediate: true});

async function getUpdates() {
    loading.value = true;

    if (updates.value === null) {
        updates.value = [];
    }

    const {data} = await dashboardService.getStartupsActionLogs(maxDate.value, filters);
    updates.value = [...updates.value, ...data.logs];
    maxDate.value = data.max_date;
    loading.value = false;

    if (!updates.value.length) {
        emit('hideStartupUpdates');
    }
}

function scrolledBottom() {
    if (loading.value) {
        return;
    }

    getUpdates();
}
</script>

<template>
    <div>
        <template v-if="updates !== null">
            <StartupUpdateLog
                v-for="(log, index) in updates"
                :key="index"
                :log="log"
                :index="index"
            />

            <ScrolledBottom @scrolledBottom = scrolledBottom />
        </template>

        <Loader
            v-if="loading"
            :loading="true"
            :color="constants.spinnerColor"
        />
    </div>
</template>

<style scoped>

</style>
