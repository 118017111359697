<script setup>
import {getLogo} from "@/helpers/logo";
import ActivityContent from "@/components/activity/ActivityContent.vue";
import {ref} from "vue";

const {log} = defineProps(['log', 'index']);
const showAll = ref(false);
</script>

<template>
    <div class="activity-item">
        <div class="activity-item-header">
            <img class="startup-logo activity-item__image" :src="getLogo(log.startup_logo)" alt="img">

            <div class="activity-item__content">
                <a :href="`/startups/${log.startup_id}`" target="_blank">{{log.startup_name}}</a>
            </div>

            <time>{{log.date}}</time>
        </div>

        <div class="startup-update-items">
            <div class="startup-update-item">
                <ActivityContent
                    :activity="log.items[0]"
                    :index="`${index}-0`"
                />
            </div>

            <template v-if="log.items.length > 1">
                <template v-if="showAll">
                    <div
                        v-for="(item, innerIndex) in log.items.slice(1)"
                        :key="innerIndex"
                        class="startup-update-item"
                    >
                        <ActivityContent
                            :activity="item"
                            :index="`${index}-${innerIndex+1}`"
                        />
                    </div>
                </template>

                <div v-else class="startup-update__more mt-2">
                    <a @click="showAll = true">View {{log.items.length - 1}} more updates from {{log.startup_name}}</a>
                </div>
            </template>
        </div>
    </div>
</template>

<style scoped>
    
</style>
